import debounce from "lodash.debounce";
import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import {
  updateFilterAndPagination,
  handlePaginations,
  handlePageSizes,
} from "../../Common Functions/Function";
import { Routers } from "../../Routes/Routes";
import { categoryResponse } from "../Categories/utils";
import { useAlert } from "../ContextAPI/ContextApi";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { columns_name } from "./data";
import Toast from "../Toast/Toast";
import Table from "../../Blocks/Table/Table";
import { searchCommunication } from "../../Api/Search/Communication/Communication";

type SearchData = {
  fields: string;
  value: string;
};

const Category = () => {
  const { alert, message, errorType, setAlert } = useAlert();
  const [parentItems, setParentItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const { storeId } = useStoreId();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });

  // eslint-disable-next-line
  const getCommunicationlist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchCommunication("notification", filtervalue, Pagination, "")
        .then(async (res) => {
          const result = categoryResponse(res);
          setParentItems(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching categories:", err);
          setIsLoading(false);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    getCommunicationlist(filtervalue, Pagination);
  }, [getCommunicationlist, filtervalue, Pagination]);

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  return (
    <>
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <div className="row mb-4 mt-4 justify-content-between">
        <div className="col-auto">
          <h3 className="theme_color fw-bolder m-0">Communications</h3>
        </div>
        <div className="col-auto ms-auto" onClick={() => setAlert(false)}>
          <Link
            className="text-decoration-none"
            to={Routers?.newcommunications}
          >
            <button className="btn btn-outline-success" type="button">
              New Communications
            </button>
          </Link>
        </div>
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={columns_name}
          data={parentItems || []}
          paginationShow={true}
          isLoading={isLoading}
          filterdata={filterdata}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
        />
      </div>
    </>
  );
};

export default Category;
