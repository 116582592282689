import { encode } from "base-64";

export const getAuthToken = async () => {
  const token = encode(
    `${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`,
  );
  if (token) {
    return `Basic ${token}`;
  }
};
