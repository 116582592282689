const OptionResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.productCode,
      value: val._source.productCode,
      label: val._source.name,
      ...val._source,
      ...val._source.extraFieldsProduct,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { OptionResponse };
