const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  // {
  //   field: "id",
  //   label: "Recipes Code",
  //   type: "text",
  //   sub_header: {
  //     inputtype: "input",
  //     placeholder: "Recipes Code",
  //   },
  // },
  {
    field: "imageUrl",
    label: "Media File",
    type: "multipleImg",
  },
  {
    field: "recipeName",
    label: "Recipes Title",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Recipes Title",
    },
  },

  // {
  //   field: "status",
  //   label: "Status",
  //   type: "icon",
  //   sub_header: {
  //     inputtype: "select",
  //   },
  // },
  {
    field: "createdDate",
    label: "Created At",
    type: "date",
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const data = [
  {
    id: "1",
    code: "Recipes 1",
    title: "Recipes Title 100",
    status: "true",
  },
  {
    id: "2",
    code: "Recipes 1",
    title: "Recipes Title 100",
    status: "true",
  },
  {
    id: "3",
    code: "Recipes 1",
    title: "Recipes Title 100",
    status: "true",
  },
  {
    id: "4",
    code: "Recipes 1",
    title: "Recipes Title 100",
    status: "true",
  },
  {
    id: "5",
    code: "Recipes 1",
    title: "Recipes Title 100",
    status: "true",
  },
];

export { columns_name, data };
