import { ReactComponent as Search } from "../../../../Asset/search.svg";
import "./Items.css";
import arrows from "./../../../../Asset/Arrows.svg";
import upload from "./../../../../Asset/upload.svg";
import { ENUMitems } from "./data";

interface MenuCategoriesProps {
  handler: (category: string) => void;
}

const Items: React.FC<MenuCategoriesProps> = ({ handler }) => {
  return (
    <div className="container card">
      <div className="menu_item_wrapper">
        <div className="row justify-content-between mb-4">
          <div className="col-lg-auto col-md-12">
            <h3 className="theme_color fw-bolder">Menus</h3>
          </div>
          <div className="col-lg-auto col-md-12 mt-3 mt-lg-0">
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={() => handler(ENUMitems?.ITEMS_NEW)}
            >
              New Menu
            </button>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row gap-2">
          <div className="col-lg-auto w-25 col-md-12 filter_wrap">
            <Search />
            <input
              type="search"
              className="form-control theme_color"
              aria-label="Search input"
              placeholder="Search"
            />
          </div>
          <div className="col-lg-auto w-25 col-md-12 mt-3 mt-lg-0">
            <select className="form-select" aria-label="Select menu">
              <option selected>All menus</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
      </div>
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Photo</th>
              <th scope="col">
                <img alt={arrows} src={arrows} /> Name
              </th>
              <th scope="col">Price</th>
              <th scope="col">Menus</th>
              <th scope="col">Categories</th>
              <th scope="col">Used in </th>
              <th scope="col">Contains</th>
              <th scope="col">Last update</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img alt={upload} src={upload} />
              </td>
              <td>70% Rye Bread</td>
              <td>R55,00</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>08/11 </td>
            </tr>
            <tr>
              <td>
                <img alt={upload} src={upload} />
              </td>
              <td>70% Rye Bread</td>
              <td>R55,00</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>08/11 </td>
            </tr>
            <tr>
              <td>
                <img alt={upload} src={upload} />
              </td>
              <td>70% Rye Bread</td>
              <td>R55,00</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>08/11 </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Items;
