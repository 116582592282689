export const CREATE_USER = `
  mutation MyMutation($input: UserOperationInput!) {
    userCreateUpdateDisabled(input: $input,operation: "CREATE")
  }
`;
export const DISABLE_USER = `
  mutation DisableUser($input: UserOperationInput!, $PK: String!, $SK: String!, $username: String!, $operation: String!) {
    userCreateUpdateDisabled(input: $input, PK: $PK, SK: $SK, username: $username, operation: $operation)
  }
`;

export const UPDATE_USER = `
  mutation MyMutation($PK: String!, $SK: String!, $input: CreatePreSignUpUserInput!) {
    updateUser(PK: $PK, SK: $SK, input: $input) 
  }`;

export const CHANGE_PASSWORD = `
  mutation MyMutation($input: UserActionInput!) {
  changePasswordUser(input: $input)
}`;

export const onUpdateDriverLocationAppSynch = `
    subscription MySubscription {
      trackingDriverCreated
    }
`;

export const onUpdateDriverLocationAPI = `
 mutation MyMutation($PK: String!, $SK: String!) {
    createTrackingDriver(input: {
      PK: $PK,
      SK: $SK,
      action: "getTrackingDriver"
})}`;
