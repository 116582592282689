// Select.tsx
import React from "react";
import AsyncSelect from "react-select/async";

export type SelectOption = {
  id: string;
  value: string;
  label: string;
  data: any;
};

type SelectProps = {
  data: SelectOption[]; // Data passed as props
  defaultOptions?: SelectOption[];
  cacheOptions?: boolean;
  onChange?: (selectedOption: SelectOption | null) => void;
  [x: string]: any;
  isLoading?: boolean;
  isMulti?: boolean;
  customStyleApply?: boolean;
};

const Select: React.FC<SelectProps> = ({
  data,
  defaultOptions,
  cacheOptions = true,
  onChange,
  isLoading,
  isMulti,
  customStyleApply,
  ...props
}) => {
  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOption[]) => void,
  ) => {
    setTimeout(() => {
      const filteredOptions = data.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      );
      callback(filteredOptions);
    }, 1000);
  };

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 1000,
    }),
    control: (provided: any) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  return (
    <AsyncSelect
      cacheOptions={cacheOptions}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions} //@ts-expect-error
      onChange={onChange}
      isLoading={isLoading}
      className="form-select-cstm"
      {...props}
      isClearable
      isMulti={isMulti}
      styles={customStyleApply ? customStyles : undefined} // Apply custom styles conditionally
    />
  );
};

export default Select;
