import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { TagsInput } from "react-tag-input-component";
import { useAlert } from "../../ContextAPI/ContextApi";
import { Category } from "../data";
import { useStoreId } from "../../ContextAPI/StoreIdContext";
import validationSchema from "./Validation";
import { updateCategory } from "../../../Api/Category/mutations";
import { handleFileUpload } from "../../../Common Functions/Function";
import ImageLoader from "../../../Effects/ImageLoader";
import { Routers } from "../../../Routes/Routes";
import FormEffect from "../../../Effects/FormEffect";
import { categoryResponse } from "../utils";
import { searchWithPKSK } from "../../../Api/Search/mutation";
import Select from "../../../Atoms/Select";
import { List } from "../SubCategory/utils";
import Toast from "../../Toast/Toast";
import LoadingOverlay from "../../../Effects/LoadingOverlay";
import { useDropzone } from "react-dropzone";

const UpdateCategory = () => {
  const { PK, SK } = useParams();
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const { alert, message, errorType } = useAlert();
  const [isLoading, setisLoading] = useState(false);
  const [categoryData, setCategoryData] = useState<Category>();
  const [saveLoader, setsaveLoader] = useState(false);
  const [dataLoader, setdataLoader] = useState(false);
  const { storeId, storeType } = useStoreId();
  let index = process.env.REACT_APP_STACK
    ? `category${process.env.REACT_APP_STACK}`
    : "category";

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      imageURL: "",
      status: true,
      tags: [],
      storeId: storeId,
      storeType: storeType,
      entityName: index,
      itemType: "",
      PK: "",
      SK: "",
      id: "",
      isDeleted: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setsaveLoader(true);
      await updateCategory(values)
        .then((res) => {
          const result = JSON.parse(res?.data?.updateCategory);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.categories);
            setAlert(true);
            setErrorType("success");
            setMessage("Category Update Successfully");
            setsaveLoader(false);
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setsaveLoader(false);
          }
          setsaveLoader(false);
        })
        .catch((err: any) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const getCategoryById = useCallback(
    async (PK: string | undefined, SK: string | undefined) => {
      setdataLoader(true);
      await searchWithPKSK("category", PK, SK)
        .then(async (res) => {
          const result = await categoryResponse(res);
          setCategoryData(result?.modifiedData[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [],
  );

  useEffect(() => {
    getCategoryById(PK, SK);
  }, [getCategoryById, PK, SK]);

  useEffect(() => {
    if (categoryData) {
      setdataLoader(false);
      formik.setValues({
        ...formik.values,
        name: categoryData.name || "",
        description: categoryData.description || "",
        imageURL: categoryData.imageURL || "",
        status: categoryData?.status ? JSON.parse(categoryData?.status) : "",
        tags: categoryData.tags || [],
        storeId: categoryData.storeId || storeId,
        storeType: categoryData.storeType || storeType,
        itemType: categoryData?.itemType || "",
        PK: categoryData?.PK,
        SK: categoryData?.SK,
        id: categoryData?.id,
        isDeleted: categoryData?.isDeleted,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData]);

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const e: any = {
      target: {
        files: event,
        name: "imageURL",
      },
    };
    await handleFileUpload(
      e,
      setisLoading,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const handleCategoryType = (data: any) => {
    let val = data;
    formik.setFieldValue("itemType", val.id);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //@ts-expect-error
    onDrop: handleFileInputChangeAndUpload, //@ts-expect-error
    accept: "image/*",
    onDragEnter: () => console.log("Drag Enter"),
    onDragLeave: () => console.log("Drag Leave"),
    onDropAccepted: (acceptedFiles) =>
      console.log("Files accepted:", acceptedFiles),
    onDropRejected: (fileRejections) =>
      console.log("Files rejected:", fileRejections),
  });

  return (
    <div>
      {isLoading && <ImageLoader />}
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />
      <form onSubmit={formik.handleSubmit}>
        <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
          <div className="heading-title mt-3 mt-md-0">
            <h4 className="theme_color fw-bolder">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.categories}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              {"Update A Category"}
            </h4>
          </div>
          <div className=" d-flex justify-content-end gap-2">
            <Link className="text-decoration-none" to={Routers?.categories}>
              <button className="btn btn-outline-danger" type="button">
                {"Cancel"}
              </button>
            </Link>
            <button
              className="btn btn-outline-success"
              disabled={isLoading || saveLoader}
              type="button"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="container">
          {dataLoader ? (
            <FormEffect />
          ) : (
            <div className="row">
              <div className="col-md-8">
                <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                  <h4 className="theme_color fw-bold align-self-center mb-3">
                    {"General"}
                  </h4>
                  <div className="row">
                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label `}
                      >
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        maxLength={100}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                      ) : null}
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <div className="mb-3">
                        <label htmlFor="description" className={`form-label `}>
                          Category Type<span className="text-danger">*</span>
                        </label>
                        <Select
                          cacheOptions={true}
                          onChange={handleCategoryType}
                          onBlur={formik.handleBlur}
                          defaultOptions={List}
                          data={List}
                          value={List.find(
                            (item: { id: string }) =>
                              item?.id === formik?.values.itemType,
                          )}
                        />
                        {formik.touched.itemType && formik.errors.itemType ? (
                          <div className="text-danger">
                            {formik.errors.itemType}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Tags
                      </label>

                      <div className="row">
                        <div className="form-group col-12">
                          <TagsInput
                            name="tags"
                            onChange={(tags) =>
                              formik.handleChange({
                                target: { name: "tags", value: tags },
                              })
                            }
                            value={formik.values.tags}
                          />
                          <small className="form-text text-muted">
                            <i className="bi bi-info-circle"></i> Press Enter
                            after typing your tag to add it.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label ${formik.touched.description && formik.errors.description && "text-danger"}`}
                      >
                        Description
                      </label>
                      <ReactQuill
                        theme="snow"
                        onChange={(content, delta, source, editor) => {
                          formik.setFieldValue("description", editor.getHTML());
                        }}
                        value={formik.values.description}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6] }],
                            ["bold", "italic", "link"],
                            [{ list: "ordered" }],
                            ["blockquote"],
                          ],
                        }}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="text-danger">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    {"Category Banner"}
                  </h4>
                  <div
                    {...getRootProps({
                      className: `new_category_banner_inner text-center ${
                        isDragActive ? "active-drop-zone" : ""
                      }`,
                      style: {
                        border: isDragActive
                          ? "2px dashed #4caf50"
                          : "2px dashed #ccc",
                        padding: "20px",
                        cursor: "pointer",
                      },
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="new_category_banner_content">
                      <div>
                        <i className="bi bi-arrow-up-circle upload_icon"></i>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-outline-success"
                        >
                          Add Image
                        </button>
                      </div>
                      <div>
                        <span className="upload_img_text">
                          {isDragActive
                            ? "Drop the images here..."
                            : "Click to upload images"}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  {formik.values.imageURL && (
                    <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                      <h4 className="theme_color fw-bold mb-3 align-self-center">
                        {"Preview Image"}
                      </h4>
                      <div className="new_category_banner_inner d-block">
                        <div className="new_category_banner_content h-100 p-2">
                          <div className="image-container-preview">
                            <img
                              src={formik.values.imageURL}
                              alt="img"
                              className="image-preview"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="border border-grey p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    STATUS
                  </h4>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="flexRadioDefault1"
                      value="disabled"
                      checked={!formik.values.status}
                      onChange={(event) => {
                        formik.setFieldValue("status", !formik.values.status);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Disabled
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="flexRadioDefault2"
                      value="enabled"
                      checked={formik.values.status}
                      onChange={(event) => {
                        formik.setFieldValue("status", !formik.values.status);
                      }}
                    />
                    <label
                      className="form-check-label theme_color"
                      htmlFor="flexRadioDefault2"
                    >
                      Enabled
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default UpdateCategory;
