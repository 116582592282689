import logo from "../../Asset/logo.png";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchAuthSession, signIn, signOut } from "aws-amplify/auth";
import Spinner from "../../Effects/Spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateUser } from "../../Api/User/mutations";

const validationSchema = Yup.object({
  username: Yup.string()
    .matches(/^\S*$/, "Spaces are not allowed in the email address.")
    .email("Please enter a valid email.")
    .required("Email is required."),
  password: Yup.string()
    .matches(/^\S*$/, "Spaces are not allowed in the Password.")
    .min(6, "Password must be at least 6 characters long.")
    .required("Password is required."),
});

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let index = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  useEffect(() => {
    const user_info = localStorage.getItem("user-info");
    if (user_info) {
      const user = JSON.parse(user_info);
      if (user?.sub) {
        navigate("/");
      }
    } else {
      setLoading(false);
      console.log("Logged out");
    }
  }, [navigate, location.pathname]);

  const currentSession = async () => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const userInfo = idToken?.payload;

      if (
        userInfo &&
        userInfo["cognito:groups"] && //@ts-expect-error
        userInfo["cognito:groups"].includes("admin")
      ) {
        localStorage.setItem("user-info", JSON.stringify(idToken?.payload));
        localStorage.setItem("storeId", "");
        localStorage.setItem("storeName", "");
        localStorage.setItem("storeType", "");
        localStorage.setItem("latitude", "");
        localStorage.setItem("longitude", "");
        const loginTime = new Date().getTime();
        localStorage.setItem("login-time", loginTime.toString());
        const { name, email, phone_number: phone, sub: userId } = userInfo;
        const CreatePreSignUpUserInput = {
          group: "admin",
          email,
          fullName: name,
          username: email,
          phone,
          entityName: index, // Ensure `index` is defined or passed properly
          isOnline: true,
          id: userId,
          deviceToken: localStorage.getItem("FCMToken"),
        };
        const res: any = await updateUser(
          "Admin",
          `Active#${userId}`,
          CreatePreSignUpUserInput,
        );
        const result = JSON.parse(res?.data?.updateUser);
        const errorMessage = JSON.parse(result.body).error;
        if (result?.statusCode === 200) {
          console.log("User successfully updated.");
        } else {
          console.error(
            "Error updating user:",
            errorMessage || "Unknown error",
          );
        }
        window.location.href = "/";
        console.log("Logged In");
      } else {
        toast.error("User not authenticated or does not have permission");
        setProgress(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const LoginHandler = async (values: {
    username: string;
    password: string;
  }) => {
    setProgress(true);

    await signOut();
    try {
      const { isSignedIn } = await signIn(values);
      if (isSignedIn) {
        currentSession();
        setLoading(false);
      }
    } catch (error) {
      //@ts-expect-error
      const { message } = error;
      toast.error(message);
      setProgress(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="w-100">
      <ToastContainer />
      <div className="login_page_bg w-100">
        <div className="container">
          <div className="login_page_card m-auto">
            <div className="d-flex justify-content-center login_page_logo">
              <img src={logo} alt="logo" />
            </div>
            <Formik
              initialValues={{ username: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => LoginHandler(values)}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username/Email
                    </label>
                    <Field
                      type="text"
                      name="username"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="form-text text-danger"
                    />
                  </div>
                  <div className="mb-3 position-relative">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                    />
                    <span
                      className="position-absolute end-0 top-50 mt-3 translate-middle-y me-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="form-text text-danger"
                    />
                  </div>
                  <div className="forgotPassword">
                    <Link to={"/forgotpassword"} className="link">
                      <p>Forgot Password</p>
                    </Link>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn login_btn"
                      disabled={isSubmitting || progress}
                    >
                      {progress ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
