import { generateClient } from "aws-amplify/api";
import { NEWOPTION } from "./query";

const client = generateClient();

export const addOption = async (
  MenuManagerInput: any,
  setAlert: Function,
  setErrorType: any,
  setMessage: Function,
) => {
  try {
    const response: any = await client.graphql({
      query: NEWOPTION,
      variables: { input: MenuManagerInput },
    });
    const result = JSON.parse(response?.data?.menuManager);
    const body = JSON.parse(result.body);
    if (result?.statusCode === 200) {
      setAlert(true);
      setErrorType("success");
      setMessage("Option Added Successfully");
      return response;
    }
    setAlert(true);
    setErrorType("error");
    setMessage(body?.error);
    return;
  } catch (error) {
    console.log(error);
    return error;
  }
};
