import { Link, useNavigate, useParams } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { useFormik } from "formik";
import { isValidJSON } from "../../Common Functions/Function";
import { useCallback, useEffect, useState } from "react";
import { CouponResponse, FormValues } from "./utils";
import { addCoupons } from "../../Api/Coupons/mutation";
import { useAlert } from "../ContextAPI/ContextApi";
import {} from "react-toastify";
import { validationSchema } from "./validation";
import { searchWithPKSK } from "../../Api/Search/mutation";
import FormEffect from "../../Effects/FormEffect";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import DatePicker from "react-datepicker";

interface FormData {
  [key: string]: any;
}

const UpdateCoupon = () => {
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType, alert, errorType, message } =
    useAlert();
  const [saveLoader, setsaveLoader] = useState(false);
  const { PK, SK } = useParams();
  const [dataLoader, setdataLoader] = useState(true);
  const [couponData, setCouponData] = useState<any>([]);
  let index = process.env.REACT_APP_STACK
    ? `coupon${process.env.REACT_APP_STACK}`
    : "coupon";
  const formik = useFormik<FormValues>({
    initialValues: {
      couponCode: "",
      description: "",
      startDate: "",
      endDate: "",
      discountType: {
        freeFirstDelivery: true,
        fixDiscount: false,
        fixAmount: "",
      },
      categories: [],
      products: [],
      itemStatus: true,
      createdAt: "",
      updatedAt: "",
      extraField1: "",
      extraField2: "",
      extraField3: "",
      itemAction: "update",
      entityName: index,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setsaveLoader(true);

      let discount = {
        freeFirstDelivery: values?.discountType?.freeFirstDelivery,
        fixDiscount: values?.discountType?.fixDiscount,
        fixAmount: values?.discountType?.fixDiscount
          ? values?.discountType?.fixAmount
          : "", // Corrected this line
      };

      const newData: FormData = {
        PK: PK,
        SK: SK,
        id: couponData?.id,
        discountType: JSON.stringify(discount),

        categories: JSON.stringify(values?.categories),
        products: JSON.stringify(values?.products),
        couponCode: values?.couponCode,
        description: values?.description,
        startDate: new Date(values?.startDate).toISOString(),
        endDate: new Date(values?.endDate).toISOString(),
        itemStatus: values?.itemStatus,
        extraField1: values?.extraField1,
        extraField2: values?.extraField2,
        extraField3: values?.extraField3,
        entityName: index,
        itemAction: "update",
      };
      await addCoupons(newData)
        .then((response: any) => {
          const result = JSON.parse(response?.data?.createCoupon);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.coupons);
            setAlert(true);
            setErrorType("success");
            setMessage("Coupon Update Successfully");
            setsaveLoader(false);
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setsaveLoader(false);
          }
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const handleDiscountTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    formik.setFieldValue("discountType", {
      freeFirstDelivery: value === "freeFirstDelivery",
      fixDiscount: value === "fixDiscount",
      fixAmount: formik?.values?.discountType?.fixAmount,
    });
  };

  const getCoupon = useCallback(async () => {
    setdataLoader(true);
    await searchWithPKSK("coupon", PK, SK)
      .then((res) => {
        setdataLoader(false);
        const result = CouponResponse(res);
        setCouponData(result?.modifiedData[0]);
      })
      .catch((error) => {
        setdataLoader(false);
        console.log(error);
      });
  }, [PK, SK]);

  useEffect(() => {
    getCoupon();
  }, [getCoupon]);

  useEffect(() => {
    if (couponData) {
      formik.setValues({
        ...formik.values,
        couponCode: couponData?.couponCode || "",
        description: couponData.description || "",
        startDate: couponData.startDate || "",
        endDate: couponData.endDate || "",
        discountType: {
          ...formik.values.discountType,
          ...couponData.discountType,
        },

        categories: couponData.categories || [],
        products: couponData.products || [],
        itemStatus: isValidJSON(couponData?.itemStatus)
          ? JSON.parse(couponData.itemStatus)
          : couponData?.itemStatus,
        extraField1: couponData.extraField1 || "",
        extraField2: couponData.extraField2 || "",
        extraField3: couponData.extraField3 || "",
        itemAction: couponData.itemAction || "create",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponData]);

  //................ getPKSk end.............................//

  if (dataLoader) {
    return <FormEffect />;
  }
  const handleSubmit = () => {
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  return (
    <div>
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />

      <form onSubmit={formik.handleSubmit}>
        <div className=" d-flex justify-content-end gap-2">
          <Link className="text-decoration-none" to={Routers?.coupons}>
            <button className="btn btn-outline-danger" type="button">
              {"Cancel"}
            </button>
          </Link>
          <button
            className="btn btn-outline-success "
            type="button"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
        <div className="heading-title mt-2 mt-md-0">
          <h4 className="theme_color fw-bold">
            <i className="bi bi-chevron-left me-3"></i>
            {"Update A Coupon"}
          </h4>
        </div>

        <div className="container">
          <div className="border border-grey p-3 rounded mt-3 mt-md-4 white_bg">
            <h4 className="theme_color align-self-center fw-bold">
              {"General"}
            </h4>
            <div className="row">
              <div className="row">
                <div className={`form-group col-12 mb-3 mt-4`}>
                  <label htmlFor="couponCode" className="form-label h5">
                    Coupon Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="couponCode"
                    name="couponCode"
                    onChange={formik.handleChange}
                    value={formik.values.couponCode}
                  />
                  {formik.touched.couponCode && formik.errors.couponCode ? (
                    <div className="text-danger">
                      {formik.errors.couponCode}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={`form-group col-12 mb-3`}>
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label h5"
                >
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="text-danger">{formik.errors.description}</div>
                ) : null}
              </div>

              <div className="form-group col-md-5 mb-3">
                <label htmlFor="startDate" className="form-label h5">
                  Start Date
                </label>
                <div className="mt-2">
                  {/* Add margin-top for spacing */}
                  <DatePicker
                    selected={formik.values.startDate}
                    onChange={(date: Date | null) =>
                      formik.setFieldValue("startDate", date)
                    }
                    dateFormat="yyyy-MM-dd"
                    minDate={
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60 * 1000,
                      )
                    } // Adjust for local timezone
                    className={`form-control ${formik.touched.startDate && formik.errors.startDate ? "is-invalid" : ""}`}
                    aria-label="Start date"
                    id="startDate"
                    placeholderText="YYYY-MM-DD"
                    isClearable
                  />
                  {formik.touched.startDate &&
                  typeof formik.errors.startDate === "string" ? (
                    <div className="text-danger">{formik.errors.startDate}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group col-md-5 mb-3">
                <label htmlFor="endDate" className="form-label h5">
                  End Date
                </label>
                <div className="mt-2">
                  {" "}
                  {/* Add margin-top for spacing */}
                  <DatePicker
                    selected={formik.values.endDate}
                    onChange={(date: Date | null) =>
                      formik.setFieldValue("endDate", date)
                    }
                    dateFormat="yyyy-MM-dd"
                    minDate={
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60 * 1000,
                      )
                    }
                    className={`form-control ${formik.touched.endDate && formik.errors.endDate ? "is-invalid" : ""}`}
                    aria-label="End date"
                    id="endDate"
                    placeholderText="YYYY-MM-DD"
                    isClearable
                  />
                  {formik.touched.endDate &&
                  typeof formik.errors.endDate === "string" ? (
                    <div className="text-danger">{formik.errors.endDate}</div>
                  ) : null}
                </div>
              </div>

              <div className={`form-group col-md-2 mb-3 align-self-center`}>
                <label
                  className="form-check-label h5 fw-bold"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Status
                </label>
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input form-control"
                    type="checkbox"
                    role="switch"
                    id="itemStatus"
                    name="itemStatus"
                    onChange={formik.handleChange}
                    checked={formik.values.itemStatus}
                  />
                </div>
              </div>
            </div>

            <div className="row p-3">
              <h4 className="theme_color align-self-center fw-bold">
                {"Discount Type"}
              </h4>
              <div className="row m-1">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="freeFirstDelivery"
                    name="discountType" // Same name for both radio buttons to ensure they belong to the same group
                    value="freeFirstDelivery" // This value will be used to update the Formik field
                    onChange={handleDiscountTypeChange} // This will handle the value change
                    checked={formik.values.discountType.freeFirstDelivery} // Checks the radio if true
                    disabled
                  />
                  <label
                    className="form-check-label h5 fw-bold"
                    htmlFor="freeFirstDelivery"
                  >
                    Free First Delivery
                  </label>
                  {formik.touched.discountType?.freeFirstDelivery &&
                  formik.errors.discountType?.freeFirstDelivery ? (
                    <div className="text-danger">
                      {formik.errors.discountType?.freeFirstDelivery}
                    </div>
                  ) : null}
                </div>

                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="fixDiscount"
                    name="discountType" // Same name for both radio buttons to ensure they belong to the same group
                    value="fixDiscount" // This value will be used to update the Formik field
                    onChange={handleDiscountTypeChange} // This will handle the value change
                    checked={formik.values.discountType.fixDiscount} // Checks the radio if true
                    disabled
                  />
                  <label
                    className="form-check-label h5 fw-bold"
                    htmlFor="fixDiscount"
                  >
                    Fix X Discount
                  </label>
                  {formik.touched.discountType?.fixDiscount &&
                  formik.errors.discountType?.fixDiscount ? (
                    <div className="text-danger">
                      {formik.errors.discountType?.fixDiscount}
                    </div>
                  ) : null}
                </div>
              </div>
              {formik.values.discountType?.fixDiscount && (
                <div className="form-group col-12 mb-3 mt-4">
                  <label htmlFor="fixAmount" className="form-label h5">
                    Fix Coupon Amount
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fixAmount"
                    name="discountType.fixAmount"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "discountType.fixAmount",
                        e.target.value,
                      )
                    }
                    value={formik.values.discountType?.fixAmount || ""}
                  />
                  {formik.touched.discountType?.fixAmount &&
                  formik.errors.discountType?.fixAmount ? (
                    <div className="text-danger">
                      {formik.errors.discountType?.fixAmount}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateCoupon;
