import React from "react";
import "./FormEffect.css";

const FormEffect = () => {
  return (
    <div className="form-shimmer-container">
      {[1, 2, 3, 4].map((item) => (
        <div key={item} className="form-shimmer-cell">
          <div className="form-shimmer-content">
            <div className="form-shimmer-title"></div>
            <div className="form-shimmer-description"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormEffect;
