const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "id",
    label: "Order ID",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order ID",
    },
  },
  {
    field: "customerName",
    label: "Customer Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Customer Name",
    },
  },
  {
    field: "commonOrderId",
    label: "Parent Id",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order ID",
    },
  },
  {
    field: "orderType",
    label: "Order Type",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order Type",
    },
  },

  {
    field: "status",
    label: "Order Staus",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order Staus",
    },
  },

  {
    field: "driverId",
    label: "Assigned To",
    type: "select",
  },
  // {
  //   field: "createdDate",
  //   label: "Create Date",
  //   type: "date",
  //   sub_header: {
  //     inputtype: "input",
  //     placeholder: "Created At",
  //   },
  // },

  {
    field: "Details",
    label: "Details",
    type: "Details",
  },
];

export { columns_name };
