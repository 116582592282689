import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  itemName: Yup.string()
    .matches(/^[^\s]/, "No spaces allowed at the start")
    .matches(/^(?!.* {2,})/, "More than one space is not allowed")
    .matches(/^(?!.* $)/, "Spaces are not allowed at the end")
    .required("Name is required"),
  sku: Yup.string().required("SKU is required"),
  // categoryId: Yup.string().required("Category is required"),
  // subcategoryId: Yup.string().required("Sub-Category is required"),
  mealCategories: Yup.array()
    .min(1, "At least one category is required")
    .required("Category is required"),
  mealSubCategories: Yup.array()
    .min(1, "At least one sub-category is required")
    .required("Sub-Category is required"),
  itemStatus: Yup.boolean().required("Status is required"),
  Takeaway: Yup.array().of(
    Yup.object({
      title: Yup.string()
        .matches(/^[^\s]/, "No spaces allowed at the start")
        .matches(/^(?!.* {2,})/, "More than one space is not allowed")
        .matches(/^(?!.* $)/, "Spaces are not allowed at the end")
        .test("title-required", "Title is required", function (value) {
          const { substituteDetails } = this.parent;
          // Check if there is at least one productCode present
          const hasProductCode = substituteDetails?.some(
            (detail: { products: any[] }) =>
              detail.products.some(
                (product: { productCode: any }) => product.productCode,
              ),
          );
          return hasProductCode ? !!value : true; // Required only if hasProductCode
        }),
      substituteDetails: Yup.array().of(
        Yup.object({
          subtitle: Yup.string(),
          products: Yup.array().of(
            Yup.object({
              productCode: Yup.string()
                .matches(/^[^\s]/, "No spaces allowed at the start")
                .matches(/^(?!.* {2,})/, "More than one space is not allowed")
                .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),
              type: Yup.string().test(
                "type-required",
                "Type is required if product is present",
                function (value) {
                  const { productCode } = this.parent;
                  // Check if productCode is present, then type is required
                  return productCode ? !!value : true;
                },
              ),
            }),
          ),
        }),
      ),
    }),
  ),
});

export default validationSchema;
