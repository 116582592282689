import axios from "axios";
import { getAuthToken } from "../utils";

export const getAllOption = async (
  query: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  let val = { term: { storeId: storeId } };

  const queryBody = {
    size: 10000,
    sort: [
      {
        createdAt: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        filter: [{ match: { isDeleted: "false" } }, val],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};
