import { useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { columns_name } from "./data";
import { Routers } from "../../Routes/Routes";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { processOrderResponse } from "./utils";
import { searchOrders } from "../../Api/Search/Orders/Orders";
import debounce from "lodash.debounce";
import {
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";

const Orders = () => {
  const { alert, message, setAlert, setMessage } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [orderslist, setOrders] = useState<any>([]);
  const [total, setTotal] = useState();
  const { storeId } = useStoreId();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });

  type SearchData = {
    fields: string;
    value: string;
  };
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);

  // eslint-disable-next-line
  const GetOrderslist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchOrders("order", filtervalue, Pagination, storeId)
        .then((res) => {
          const result = processOrderResponse(res);
          setOrders(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching ordes:", error);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    GetOrderslist(filtervalue, Pagination);
  }, [GetOrderslist, filtervalue, Pagination]);

  const navigate = useNavigate();

  const handleInfo = (data: { IdUser: string; id: string }) => {
    navigate(
      `${Routers.ordersDetails
        .replace(":IdUser", encodeURIComponent(data.IdUser))
        .replace(":orderId", encodeURIComponent(data.id))}`,
    );
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  return (
    <div>
      {alert && message && <Toast type="success" message={message} />}
      <div className="row my-4 d-flex">
        <div className="col-10">
          <h3 className="theme_color mb-0 fw-bolder">{"Orders"}</h3>
        </div>
        <div className="col-2 justify-content-end">
          <div className="col-12 col-md-auto d-flex flex-column flex-md-row gap-2">
            <button
              className="btn btn-outline-success w-100"
              onClick={() => {
                if (storeId) {
                  setIsLoading(true);
                  GetOrderslist(filtervalue, Pagination);
                } else {
                  setAlert(true);
                  setMessage("First select a store");
                }
              }}
              disabled={isLoading}
            >
              Refresh Order List
            </button>
          </div>
        </div>
      </div>

      <div className="border p-4  border-grey  rounded  white_bg">
        <Table
          columns={columns_name}
          data={orderslist || []}
          paginationShow={true}
          isLoading={isLoading}
          handlePagination={handlePagination}
          handlePageSize={handlePageSize}
          filterdata={filterdata}
          total={total}
          Pagination={Pagination}
          handleInfo={handleInfo}
        ></Table>
      </div>
    </div>
  );
};

export default Orders;
