import React, { useState } from "react";
import { cloudInvalidation } from "../../Api/Cloud-Invalidation/mutation";
import { useAlert } from "../ContextAPI/ContextApi";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import Toast from "../Toast/Toast";

function Setting() {
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert, setMessage, setErrorType } = useAlert();
  const { alert, message, errorType } = useAlert();

  const ClearCache = () => {
    console.log(process.env.REACT_APP_DOMAIN);
    setIsLoading(true);
    cloudInvalidation(process.env.REACT_APP_DOMAIN)
      .then((response: any) => {
        const createInvalidation = JSON.parse(response.data.createInvalidation);
        const statusCode = createInvalidation.statusCode;
        const result = JSON.parse(createInvalidation.body);

        const errorMessage =
          result?.error ||
          result?.errorMessage ||
          result?.message ||
          "Unknown error occurred";

        if (statusCode === 200) {
          setIsLoading(false);
          setAlert(true);
          setErrorType("success");
          setMessage("Clear Cache Successfully");
          window.location.reload();
        } else {
          setAlert(true);
          setErrorType("error");
          setMessage(errorMessage);
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setAlert(true);
        setErrorType("error");
        setIsLoading(false);
        const message =
          error?.Error?.message || // Check if error object has an Error property with a message
          error?.errors?.[0]?.message || // Check if errors array exists and has a message in the first item
          "Unknown error occurred"; // Fallback message
        setMessage(message);
      });
  };
  return (
    <>
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={isLoading} message="Please wait..." />
      <div className="card" style={{ width: "18rem" }}>
        <div className="card-body">
          <h5 className="card-title">Clear Cache</h5>
          <p className="card-text">
            Free up space and improve performance by clearing cached data.
          </p>
          <button
            className="btn btn-outline-success"
            onClick={() => ClearCache()}
          >
            Clear Cache
          </button>
        </div>
      </div>
    </>
  );
}

export default Setting;
