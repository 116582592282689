import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { steps } from "./utils";
import { useAlert } from "../ContextAPI/ContextApi";
import { addRecipes } from "../../Api/Recipie/mutations";
import {
  formattedDate,
  generateUUID,
  handleMultipleFileUpload,
  isValidJSON,
} from "../../Common Functions/Function";
import { FormikErrors, useFormik } from "formik";
import validationSchema from "./validation";
import parse from "html-react-parser";
import { TagsInput } from "react-tag-input-component";
import ImageLoader from "../../Effects/ImageLoader";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import {
  getAllProductsByProductType,
  getProductByCode,
} from "../../Api/Search/Product/Product";
import { ProductResponse } from "../Products/utils";
import nom_image from "../../Asset/no_image.png";
import Select from "../../Atoms/Select";
import {
  getAllCategory,
  getAllCategoryById,
} from "../../Api/Search/Category/Category";
import { categoryResponse } from "../Categories/utils";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";

const NewRecipes = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loader, setLoader] = useState(false);
  const [saveLoader, setsaveLoader] = useState(false);
  const { storeId } = useStoreId();
  const [parentItems, setParentItems] = useState<any>([]);
  const [subItems, setSubItems] = useState<any>([]);
  const { setAlert, setMessage, setErrorType, alert, errorType, message } =
    useAlert();

  let index = process.env.REACT_APP_STACK
    ? `recipe${process.env.REACT_APP_STACK}`
    : "recipe";

  const formik = useFormik({
    initialValues: {
      categoryId: "",
      subcategoryId: "",
      cooking_instruction: [{ instruction: "" }],
      ingredientsDetail: [
        {
          productCode: "",
          qty: "",
          sku: "",
          substituteDetails: [
            {
              productCode: "",
              qty: "",
              sku: "",
            },
          ],
        },
      ],
      description: "",
      recpies_code: "",
      difficulty_level: "",
      recipe_title: "",
      author_chef: "",
      total_time: "",
      prep_time: "",
      cooking_time: "",
      recipeName: "",
      imageUrl: [],
      tags: [],
      storeId: storeId,
      entityName: index,
      isDeleted: false,
      createdDate: formattedDate(),
      productType: "STORE",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      setsaveLoader(true);
      setSubmitting(true);
      const UUID = generateUUID();
      formik.setFieldValue("recpies_code", UUID);
      const cleanedIngredients = values?.ingredientsDetail?.map(
        (detail: {
          [x: string]: any;
          productdata: any;
          substituteDetails: any;
        }) => {
          const { productdata, substituteDetails, ...rest } = detail;
          const cleanedSubstituteDetails = substituteDetails.map(
            (subDetail: { [x: string]: any; productdata: any }) => {
              const { productdata, ...rest } = subDetail;
              return rest;
            },
          );
          return {
            ...rest,
            substituteDetails: cleanedSubstituteDetails,
          };
        },
      );
      const newData: FormData = {
        recipeDetail: {
          steps: values?.cooking_instruction,
        },
        PK: `Recipies`,
        SK: `Active#${UUID}`,
        id: UUID,
        ingredients: JSON.stringify({ ingredientsDetail: cleanedIngredients }),
      };

      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          if (steps.includes(key)) {
            const value = values[key];
            newData.recipeDetail[key] = value;
          } else {
            newData[key] = values[key];
          }
        }
      }

      newData.recipeDetail = JSON.stringify(newData.recipeDetail);
      delete newData.imageUrl;
      newData.imageUrl = JSON.stringify(values?.imageUrl);
      delete newData.cooking_instruction;
      delete newData.ingredientsDetail;

      await addRecipes(newData)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.createRecipe);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            setAlert(true);
            setErrorType("success");
            setMessage("Recipies Added Successfully");
            navigate(Routers?.recipes);
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
          }
          setsaveLoader(false);
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
          setsaveLoader(false);
        });
    },
  });

  const [productlist, setproductlist] = useState<any>([]);
  const [, setIsLoading] = useState(false);

  const getProductlist = useCallback(async () => {
    setIsLoading(true);
    await getAllProductsByProductType("", "product", storeId, "STORE")
      .then((res) => {
        const result = ProductResponse(res);
        setproductlist(result?.modifiedData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getProductlist();
  }, [getProductlist]);

  useEffect(() => {
    getProductlist();
  }, [getProductlist]);

  const handleIngredients = (key: number, name: string, value: string) => {
    const updatedIngredients = [...formik.values.ingredientsDetail];
    updatedIngredients[key] = {
      ...updatedIngredients[key],
      [name]: value,
    };
    formik.setFieldValue("ingredientsDetail", updatedIngredients);

    if (name === "productCode") {
      getProduct(value)
        .then((productdata) => {
          if (productdata) {
            const updatedIngredient = {
              ...updatedIngredients[key],
              productdata: productdata[0],
            };
            updatedIngredients[key] = updatedIngredient;
            formik.setFieldValue("ingredientsDetail", updatedIngredients);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };
  let getProduct = (productCode: any) => {
    return getProductByCode(productCode, "product", storeId);
  };

  const removeFormFields = (indexToRemove: number) => {
    formik.setFieldValue(
      "cooking_instruction",
      formik.values.cooking_instruction.filter(
        (_: any, index: number) => index !== indexToRemove,
      ),
    );
  };

  const handleSubstituteIngredients = (
    ingredientIndex: number,
    substituteIndex: number,
    field: string,
    value: string,
  ) => {
    const updatedIngredients = [...formik.values.ingredientsDetail];
    updatedIngredients[ingredientIndex].substituteDetails[substituteIndex][
      field
    ] = value;
    if (field === "productCode") {
      getProduct(value)
        .then((productdata) => {
          if (productdata && productdata.length > 0) {
            const updatedSubstitute = {
              ...updatedIngredients[ingredientIndex].substituteDetails[
                substituteIndex
              ],
              productdata: productdata[0],
            };
            updatedIngredients[ingredientIndex].substituteDetails[
              substituteIndex
            ] = updatedSubstitute;
            formik.setFieldValue("ingredientsDetail", updatedIngredients);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      formik.setFieldValue("ingredientsDetail", updatedIngredients);
    }
  };

  const removeIngredients = (indexToRemove: number) => {
    formik.setFieldValue(
      "ingredientsDetail",
      formik.values.ingredientsDetail.filter(
        (_: any, index: number) => index !== indexToRemove,
      ),
    );
  };

  const addSubstituteIngredient = (ingredientIndex: number) => {
    formik.setFieldValue(
      `ingredientsDetail[${ingredientIndex}].substituteDetails`,
      [
        ...formik.values.ingredientsDetail[ingredientIndex].substituteDetails,
        {
          productCode: "",
          qty: "",
          sku: "",
        },
      ],
    );
  };

  const handleRemoveSubstitute = (
    ingredientIndex: number,
    substituteIndex: number,
  ) => {
    const updatedIngredients = [...formik.values.ingredientsDetail];
    updatedIngredients[ingredientIndex].substituteDetails.splice(
      substituteIndex,
      1,
    );
    formik.setFieldValue("ingredientsDetail", updatedIngredients);
  };

  interface FormData {
    [key: string]: any;
  }

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    await handleMultipleFileUpload(
      event,
      setLoader,
      formik.setFieldValue,
      formik.values,
      setAlert,
      setErrorType,
      setMessage,
    );
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const navigate = useNavigate();

  const handleRemoveImage = (index: number) => {
    const updatedImageURL = [...formik.values.imageUrl];
    updatedImageURL.splice(index, 1);
    formik.setFieldValue("imageUrl", updatedImageURL);
  };

  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const formatError = (error: string | any[] | FormikErrors<any>) => {
    if (typeof error === "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(", ");
    } else {
      return JSON.stringify(error); // or any custom logic
    }
  };

  const getCategorylist = useCallback(async () => {
    await getAllCategory("", "category", "Recipe", storeId)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setParentItems(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    const fetchData = async () => {
      if (formik?.values?.categoryId) {
        await getAllCategoryById(
          "",
          "subcategory",
          "Recipe",
          formik?.values?.categoryId,
          storeId,
        )
          .then((res) => {
            const result = categoryResponse(res);
            setSubItems(result?.modifiedData);
          })
          .catch((error) => {
            console.error("Error fetching categories:", error);
          });
      }
    };

    fetchData();
  }, [formik?.values?.categoryId, storeId]);

  useEffect(() => {
    getCategorylist();
  }, [getCategorylist]);

  const handleCategory = (data: any) => {
    let val = isValidJSON(data) ? JSON.parse(data) : data;
    formik.setFieldValue("categoryId", val.id);
    formik.setFieldValue("subcategoryId", "");
  };

  const handleSubCategory = (data: any) => {
    let val = isValidJSON(data) ? JSON.parse(data) : data;
    formik.setFieldValue("subcategoryId", val.id);
  };

  return (
    <div>
      {loader && <ImageLoader />}
      <ToastContainer />
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}

      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />

      <form onSubmit={formik.handleSubmit}>
        <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
          <div className="heading-title">
            <h4 className="theme_color fw-bolder">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.recipes}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              {"Create A New  Recipe"}
            </h4>
          </div>
          <div className=" d-flex justify-content-end gap-2">
            <Link className="text-decoration-none" to={Routers?.recipes}>
              <button className="btn btn-outline-danger" type="button">
                {"Cancel"}
              </button>
            </Link>
            <button
              className="btn btn-outline-success "
              onClick={handleSubmit}
              type="button"
              disabled={loader}
            >
              Save
            </button>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border  border-grey rounded  p-4  mt-4 white_bg">
                <h6 className="theme_color align-self-center h4 fw-bold mb-3">
                  {"General"}
                </h6>
                <div className={`form-group row mb-3`}>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description1" className={`form-label`}>
                      Category<span className="text-danger">*</span>
                    </label>

                    <Select
                      cacheOptions={true}
                      onChange={handleCategory}
                      defaultOptions={parentItems}
                      data={parentItems}
                      value={parentItems.find(
                        (item: { SK: string }) =>
                          item?.SK === formik?.values.categoryId,
                      )}
                    />
                    {formik.touched.categoryId && formik.errors.categoryId && (
                      <div className="text-danger">
                        {formatError(formik.errors.categoryId)}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description2" className={`form-label`}>
                      Sub-Category<span className="text-danger">*</span>
                    </label>

                    <Select
                      onChange={handleSubCategory}
                      defaultOptions={subItems}
                      data={subItems}
                      value={
                        formik?.values?.subcategorySK === ""
                          ? null
                          : subItems.find(
                              (item: { SK: string }) =>
                                item?.SK === formik?.values?.subcategoryId,
                            )
                      }
                    />
                    {formik.touched.subcategoryId &&
                      formik.errors.subcategoryId && (
                        <div className="text-danger">
                          {formatError(formik.errors.subcategoryId)}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className={`form-group col-md-12 mb-3`}>
                    <label className={`form-label`}>
                      Recipe Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="recipeName"
                      onChange={formik.handleChange}
                      value={formik.values.recipeName}
                      required
                      className="form-control"
                      maxLength={100}
                    />
                    {formik.touched.recipeName && formik.errors.recipeName ? (
                      <div className="text-danger">
                        {formatError(formik.errors.recipeName)}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label className="form-label">
                      Difficulty Level<span className="text-danger">*</span>
                    </label>
                    <select
                      name="difficulty_level"
                      className="form-select"
                      aria-label="Default select example"
                      onChange={formik.handleChange}
                      value={formik?.values?.difficulty_level}
                    >
                      <option value="" label="Select difficulty level" />
                      <option value="Easy" label="Easy" />
                      <option value="Medium" label="Medium" />
                      <option value="Hard" label="Hard" />
                    </select>

                    {formik.touched.difficulty_level &&
                    formik.errors.difficulty_level ? (
                      <div className="text-danger">
                        {formatError(formik.errors.difficulty_level)}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group col-md-6 mb-3">
                    <label className={`form-label`}>
                      Recipe Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="recipe_title"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik?.values?.recipe_title}
                      maxLength={100}
                    />
                    {formik.touched.recipe_title &&
                    formik.errors.recipe_title ? (
                      <div className="text-danger">
                        {formatError(formik.errors.recipe_title)}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group col-md-12 mb-3">
                    <label className={`form-label`}>
                      Author / Chef<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="author_chef"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik?.values?.author_chef}
                    />
                    {formik.touched.author_chef && formik.errors.author_chef ? (
                      <div className="text-danger">
                        {formatError(formik.errors.author_chef)}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group col-12 mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      name="description"
                      onChange={formik.handleChange}
                      value={formik?.values?.description}
                    ></textarea>
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-danger">
                        {formatError(formik.errors.description)}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <label className={`form-label`}>
                      Total Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      name="total_time"
                      onChange={formik.handleChange}
                      className="form-control timepicker"
                      value={formik?.values?.total_time}
                      step="60"
                      aria-describedby="basic-addon1"
                    />
                    {formik.touched.total_time && formik.errors.total_time ? (
                      <div className="text-danger">
                        {formatError(formik.errors.total_time)}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label className={`form-label`}>
                      Prep Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      name="prep_time"
                      onChange={formik.handleChange}
                      className="form-control "
                      value={formik?.values?.prep_time}
                      step="60"
                      aria-describedby="basic-addon1"
                    />
                    {formik.touched.prep_time && formik.errors.prep_time ? (
                      <div className="text-danger">
                        {formatError(formik.errors.prep_time)}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label className={`form-label`}>
                      Cooking Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      name="cooking_time"
                      onChange={formik.handleChange}
                      className="form-control "
                      value={formik?.values?.cooking_time}
                      step="60"
                      aria-describedby="basic-addon1"
                    />
                    {formik.touched.cooking_time &&
                    formik.errors.cooking_time ? (
                      <div className="text-danger">
                        {formatError(formik.errors.cooking_time)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="border  border-grey p-4 rounded mt-4 white_bg">
                <div className="d-flex justify-content-between">
                  <h4 className="theme_color fw-bold mb-3">
                    {"Cooking Instructions"}
                    <span className="text-danger">*</span>
                  </h4>
                  <a href={`#new_step`}>
                    <button
                      className="btn btn-outline-success"
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("cooking_instruction", [
                          ...formik.values.cooking_instruction,
                          { instruction: "" },
                        ]);
                      }}
                    >
                      {"New step"}
                    </button>
                  </a>
                </div>

                <div className="row">
                  {formik?.values?.cooking_instruction?.map(
                    (element: any, index: number) => (
                      <>
                        <div
                          id={`new_step`}
                          key={index}
                          className={`form-group d-flex align-items-center w-100 mb-3 mt-4 gap-2`}
                        >
                          <i className="bi bi-text-indent-left"></i>
                          <input
                            type="text"
                            className="form-control"
                            name={`cooking_instruction[${index}].instruction`}
                            onChange={formik.handleChange}
                            value={
                              formik.values.cooking_instruction[index]
                                ?.instruction || ""
                            }
                          />
                          <i
                            className="bi bi-dash-lg remove_icon"
                            onClick={() =>
                              formik?.values?.cooking_instruction?.length >=
                                2 && removeFormFields(index)
                            }
                          ></i>
                        </div>
                        <div>
                          {formik.touched.cooking_instruction &&
                            formik.errors.cooking_instruction && //@ts-expect-error
                            formik.errors.cooking_instruction[index] && (
                              <div
                                className="text-danger"
                                style={{ marginLeft: "25px" }}
                              >
                                {
                                  //@ts-expect-error
                                  formik.errors.cooking_instruction[index]
                                    .instruction
                                }
                              </div>
                            )}
                        </div>
                      </>
                    ),
                  )}
                </div>
              </div>

              <div className="border  border-grey p-4 rounded mt-4  ">
                <div className="d-flex justify-content-between">
                  <h4 className="theme_color align-self-center fw-bold">
                    {"Ingredients"}
                  </h4>
                </div>
                <div className="d-flex align-items-center gap-4 mt-4">
                  <div className="p-4  ingredient_bg rounded">
                    {formik?.values?.ingredientsDetail?.map(
                      (item: any, key: number) => (
                        <div className="row">
                          <div className="col-11">
                            <div className="row">
                              <div className={`form-group col-12 mb-3`}>
                                <label className="form-label">
                                  {key < 1
                                    ? "Primary Products :"
                                    : ` Primary Products ${key + 1}:`}
                                </label>
                              </div>

                              <div className={`form-group col-md-4 mb-3`}>
                                <label
                                  htmlFor="description1"
                                  className="form-label"
                                >
                                  Product<span className="text-danger">*</span>
                                </label>

                                <Select
                                  cacheOptions={true}
                                  onChange={(e: any) => {
                                    const productCode = e?.productCode || "";
                                    handleIngredients(
                                      key,
                                      "productCode",
                                      productCode,
                                    ); // Always call handleIngredients
                                  }}
                                  defaultOptions={productlist.filter(
                                    (product: any) =>
                                      // Filter out already selected primary products
                                      !formik?.values?.ingredientsDetail?.some(
                                        (ingredient: any) =>
                                          ingredient?.productCode ===
                                          product?.productCode,
                                      ) &&
                                      // Filter out substitutes from previously selected ingredients
                                      !formik?.values?.ingredientsDetail?.some(
                                        (ingredient: any) =>
                                          ingredient?.substituteDetails?.some(
                                            (substitute: any) =>
                                              substitute?.productCode ===
                                              product?.productCode,
                                          ),
                                      ) &&
                                      // Filter out any substitutes already selected at the same level
                                      !formik?.values?.substituteIngredients?.some(
                                        (substitute: any) =>
                                          substitute?.productCode ===
                                          product?.productCode,
                                      ),
                                  )}
                                  data={productlist.filter(
                                    (product: any) =>
                                      // Filter out already selected primary products
                                      !formik?.values?.ingredientsDetail?.some(
                                        (ingredient: any) =>
                                          ingredient?.productCode ===
                                          product?.productCode,
                                      ) &&
                                      // Filter out substitutes from previously selected ingredients
                                      !formik?.values?.ingredientsDetail?.some(
                                        (ingredient: any) =>
                                          ingredient?.substituteDetails?.some(
                                            (substitute: any) =>
                                              substitute?.productCode ===
                                              product?.productCode,
                                          ),
                                      ) &&
                                      // Filter out any substitutes already selected at the same level
                                      !formik?.values?.substituteIngredients?.some(
                                        (substitute: any) =>
                                          substitute?.productCode ===
                                          product?.productCode,
                                      ),
                                  )}
                                />

                                {formik.touched.ingredientsDetail &&
                                  formik.errors.ingredientsDetail && //@ts-expect-error
                                  formik.errors.ingredientsDetail[key]
                                    ?.productCode && (
                                    <div className="text-danger">
                                      {
                                        //@ts-expect-error
                                        formik.errors.ingredientsDetail[key]
                                          .productCode
                                      }
                                    </div>
                                  )}
                              </div>
                              <div className={`form-group col-md-4 mb-3`}>
                                <label className="form-label">
                                  Qty<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="number"
                                  name="qty"
                                  onChange={(e) => {
                                    handleIngredients(
                                      key,
                                      "qty",
                                      e.target.value,
                                    );
                                  }}
                                  className="form-control"
                                  value={item?.qty}
                                  min={1}
                                />
                                {formik.touched.ingredientsDetail &&
                                  formik.errors.ingredientsDetail && //@ts-expect-error
                                  formik.errors.ingredientsDetail[key]?.qty && (
                                    <div className="text-danger">
                                      {
                                        //@ts-expect-error
                                        formik.errors.ingredientsDetail[key].qty
                                      }
                                    </div>
                                  )}
                              </div>

                              <div className={`form-group col-md-4 mb-3`}>
                                <label className="form-label">
                                  Product Code
                                </label>
                                <input
                                  type="text"
                                  name="sku"
                                  onChange={(e) => {
                                    handleIngredients(
                                      key,
                                      "sku",
                                      e.target.value,
                                    );
                                  }}
                                  readOnly
                                  className="form-control"
                                  value={item?.productdata?.sku}
                                  min={1}
                                />
                                {formik.touched.ingredientsDetail &&
                                  formik.errors.ingredientsDetail && //@ts-expect-error
                                  formik.errors.ingredientsDetail[key]?.sku && (
                                    <div className="text-danger">
                                      {
                                        //@ts-expect-error
                                        formik.errors.ingredientsDetail[key].sku
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                            {item && item?.productdata && (
                              <div className="container white_bg p-2" key={key}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <img
                                      src={
                                        item?.productdata.imageUrl || nom_image
                                      }
                                      alt="Placeholder"
                                      className="img-fluid"
                                      width={200}
                                      height={200}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <div className="content">
                                      <h2>{item.productdata.name}</h2>
                                      <p>
                                        {parse(item.productdata.description)}
                                      </p>
                                      <br></br>
                                      {item.productdata?.extraFieldsProduct
                                        .costPrice && (
                                        <p>
                                          Cost Price:
                                          {
                                            item.productdata?.extraFieldsProduct
                                              .costPrice
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div>
                              {item?.substituteDetails?.map(
                                (data: any, index: number) => (
                                  <div className="row">
                                    <div className={`form-group col-12 mb-3`}>
                                      <label className="form-label">
                                        {` Substitute Products ${index + 1}`}
                                      </label>
                                    </div>

                                    <div className={`form-group col-md-4 mb-3`}>
                                      <label
                                        htmlFor="description1"
                                        className="form-label"
                                      >
                                        Product
                                        <span className="text-danger">*</span>
                                      </label>

                                      <Select
                                        cacheOptions={true}
                                        onChange={(e: any) => {
                                          const productCode =
                                            e?.productCode || "";

                                          handleSubstituteIngredients(
                                            key,
                                            index,
                                            "productCode",
                                            productCode,
                                          );
                                        }}
                                        defaultOptions={productlist.filter(
                                          (product: any) =>
                                            // Filter out already selected primary products
                                            !formik?.values?.ingredientsDetail?.some(
                                              (ingredient: any) =>
                                                ingredient?.productCode ===
                                                product?.productCode,
                                            ) &&
                                            // Filter out substitutes from previously selected ingredients
                                            !formik?.values?.ingredientsDetail?.some(
                                              (ingredient: any) =>
                                                ingredient?.substituteDetails?.some(
                                                  (substitute: any) =>
                                                    substitute?.productCode ===
                                                    product?.productCode,
                                                ),
                                            ) &&
                                            // Filter out any substitutes already selected at the same level
                                            !formik?.values?.substituteIngredients?.some(
                                              (substitute: any) =>
                                                substitute?.productCode ===
                                                product?.productCode,
                                            ),
                                        )}
                                        data={productlist.filter(
                                          (product: any) =>
                                            // Filter out already selected primary products
                                            !formik?.values?.ingredientsDetail?.some(
                                              (ingredient: any) =>
                                                ingredient?.productCode ===
                                                product?.productCode,
                                            ) &&
                                            // Filter out substitutes from previously selected ingredients
                                            !formik?.values?.ingredientsDetail?.some(
                                              (ingredient: any) =>
                                                ingredient?.substituteDetails?.some(
                                                  (substitute: any) =>
                                                    substitute?.productCode ===
                                                    product?.productCode,
                                                ),
                                            ) &&
                                            // Filter out any substitutes already selected at the same level
                                            !formik?.values?.substituteIngredients?.some(
                                              (substitute: any) =>
                                                substitute?.productCode ===
                                                product?.productCode,
                                            ),
                                        )}
                                      />
                                      {formik.touched.ingredientsDetail &&
                                        formik.errors.ingredientsDetail && //@ts-expect-error
                                        formik.errors.ingredientsDetail[key]
                                          ?.substituteDetails[index]
                                          ?.productCode && (
                                          <div className="text-danger">
                                            {
                                              //@ts-expect-error
                                              formik.errors.ingredientsDetail[
                                                key
                                              ].substituteDetails[index]
                                                .productCode
                                            }
                                          </div>
                                        )}
                                    </div>
                                    <div
                                      className={`form-group col-md-3 mb-3 col-11`}
                                    >
                                      <label className="form-label">
                                        Qty
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="number"
                                        name="qty"
                                        onChange={(e) =>
                                          handleSubstituteIngredients(
                                            key,
                                            index,
                                            "qty",
                                            e.target.value,
                                          )
                                        }
                                        min={1}
                                        className="form-control"
                                      />
                                      {formik.touched.ingredientsDetail &&
                                        formik?.errors?.ingredientsDetail && //@ts-expect-error
                                        formik?.errors?.ingredientsDetail?.[key]
                                          ?.substituteDetails[index]?.qty && (
                                          <div className="text-danger">
                                            {
                                              //@ts-expect-error
                                              formik?.errors
                                                ?.ingredientsDetail?.[key]
                                                .substituteDetails?.[index]?.qty
                                            }
                                          </div>
                                        )}
                                    </div>
                                    <div className={`form-group col-md-4 mb-3`}>
                                      <label className="form-label">
                                        Product Code
                                      </label>
                                      <input
                                        type="text"
                                        name="sku"
                                        onChange={(e) =>
                                          handleSubstituteIngredients(
                                            key,
                                            index,
                                            "sku",
                                            e.target.value,
                                          )
                                        }
                                        readOnly
                                        value={data?.productdata?.sku}
                                        className="form-control"
                                        min={1}
                                      />
                                      {formik.touched.ingredientsDetail &&
                                        formik?.errors?.ingredientsDetail && //@ts-expect-error
                                        formik?.errors?.ingredientsDetail?.[key]
                                          ?.substituteDetails[index]?.sku && (
                                          <div className="text-danger">
                                            {
                                              //@ts-expect-error
                                              formik?.errors
                                                ?.ingredientsDetail?.[key]
                                                .substituteDetails?.[index]?.sku
                                            }
                                          </div>
                                        )}
                                    </div>
                                    <div
                                      className={`form-group col-md-1 col-1 pt-4 mb-3 d-flex align-items-center `}
                                    >
                                      <button
                                        className="btn p-0 pt-2"
                                        type="button"
                                        onClick={() =>
                                          handleRemoveSubstitute(key, index)
                                        }
                                      >
                                        <i className="bi bi-x-circle"></i>
                                      </button>
                                    </div>
                                    {data && data?.productdata && (
                                      <div
                                        className="container white_bg p-2"
                                        key={index}
                                      >
                                        <div className="row">
                                          <div className="col-md-6">
                                            <img
                                              src={
                                                data?.productdata?.imageUrl ||
                                                nom_image
                                              }
                                              alt="Placeholder"
                                              className="img-fluid"
                                              width={200}
                                              height={200}
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <div className="content">
                                              <h2>{data.productdata.name}</h2>
                                              <p>
                                                {parse(
                                                  data.productdata.description,
                                                )}
                                              </p>
                                              <br></br>
                                              {data.productdata
                                                .extraFieldsProduct
                                                .costPrice && (
                                                <p>
                                                  Cost Price:
                                                  {
                                                    data.productdata
                                                      .extraFieldsProduct
                                                      .costPrice
                                                  }
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ),
                              )}
                              <div className="w-100 d-flex justify-content-end mt-2">
                                <button
                                  className="btn btn-outline-success"
                                  type="button"
                                  onClick={() => addSubstituteIngredient(key)}
                                >
                                  Add Substitute Product
                                </button>
                              </div>
                            </div>
                          </div>
                          {key >= 1 && (
                            <div
                              key={key}
                              className="col-1 d-flex align-items-center"
                            >
                              <i
                                className="bi bi-dash-lg remove_icon"
                                onClick={() => removeIngredients(key)}
                              ></i>
                            </div>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-outline-success"
                    type="button"
                    onClick={() => {
                      const updatedIngredients = [
                        ...formik.values.ingredientsDetail,
                        {
                          productCode: "",
                          qty: "",
                          substituteDetails: [
                            {
                              productCode: "",
                              qty: "",
                            },
                          ],
                        },
                      ];
                      formik.setFieldValue(
                        "ingredientsDetail",
                        updatedIngredients,
                      );
                    }}
                  >
                    {"New Ingredient"}
                  </button>
                </div>
              </div>

              <div className="border  border-grey p-4 rounded mt-4 white_bg ">
                <div className="d-flex justify-content-between">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    {"Tags"}
                  </h4>
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <TagsInput
                      name="tags"
                      onChange={(tags) =>
                        formik.handleChange({
                          target: { name: "tags", value: tags },
                        })
                      }
                      value={formik.values.tags}
                    />
                    <small className="form-text text-muted">
                      <i className="bi bi-info-circle"></i> Press Enter after
                      typing your tag to add it.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="border border-grey p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    {"Media File"}
                  </h4>
                  <div className="new_category_banner_inner">
                    <div className="new_category_banner_content">
                      <div>
                        <i className="bi bi-arrow-up-circle upload_icon"></i>
                      </div>
                      <div>
                        <input
                          ref={fileInputRef}
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileInputChangeAndUpload}
                          name="imageUrl"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-success"
                          onClick={handleButtonClick}
                        >
                          Add Media
                        </button>
                      </div>
                      <div>
                        <span className="upload_img_text">
                          {"Click to upload a media"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {formik?.values?.imageUrl.length > 0 && (
                  <div className="border border-grey p-3 rounded mt-4 white_bg position-relative">
                    <h4 className="theme_color fw-bold mb-3 align-self-center">
                      {"Preview Media"}
                    </h4>
                    <div className="d-block position-relative">
                      <div className="new_category_banner_content h-100 p-2 position-relative">
                        <div className="row gx-3 gy-4">
                          {formik?.values?.imageUrl?.map(
                            (items: any, index: number) => {
                              const item = items;
                              return (
                                <div key={index} className="col-md-4 mb-4">
                                  <div
                                    style={{
                                      backgroundSize:
                                        item?.type === "image"
                                          ? "cover"
                                          : "contain",
                                      backgroundPosition: "center",
                                      height: "80px",
                                      position: "relative",
                                    }}
                                  >
                                    {item?.type === "image" && (
                                      <div className="image-container-preview">
                                        <img
                                          src={item.url}
                                          alt="img"
                                          className="image-preview"
                                        />
                                      </div>
                                    )}
                                    {item?.type === "video" && (
                                      <video
                                        src={item?.url}
                                        controls
                                        height="80px"
                                        width="100%"
                                      ></video>
                                    )}
                                    {item?.type === "audio" && (
                                      <audio
                                        src={item?.url}
                                        controls
                                        style={{ width: "100%" }}
                                      ></audio>
                                    )}
                                    <button
                                      className="btn-close position-absolute top-0 end-0 btn btn-danger"
                                      onClick={() => handleRemoveImage(index)}
                                      aria-label="Close"
                                      style={{ zIndex: 1 }}
                                      type="button"
                                    ></button>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewRecipes;
