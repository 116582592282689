import axios from "axios";
import { getAuthToken } from "../Search/utils";
import { GetNetProfit, GetRevenue } from "./query";

interface AggregationData {
  lastWeekTotal: number;
  dailyData: { date: string; total: number }[];
  previousWeekTotal: number;
}

export const getRevenueData = async (
  indexName: string,
  storeId: string | null,
  timeZone: string = "Asia/Calcutta",
): Promise<AggregationData> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  try {
    const response = await axios.post(searchUrl, GetRevenue, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const { last_week, previous_week } = response.data.aggregations;

    return {
      lastWeekTotal: last_week.weekly_total.value,
      dailyData: last_week.daily_data.buckets.map((bucket: any) => ({
        date: bucket.key_as_string,
        total: bucket.daily_total.value,
      })),
      previousWeekTotal: previous_week.weekly_total.value,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getOrdersData = async (
  indexName: string,
  storeId: string | null,
  timeZone: string = "Asia/Calcutta",
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const ReqQuery = {
    size: 0,
    aggs: {
      last_week: {
        filter: {
          bool: {
            must: [
              {
                range: {
                  createdDate: {
                    gte: "now-7d/d",
                    lt: "now",
                  },
                },
              },
              {
                term: {
                  "storeId.keyword": storeId, //storeId
                },
              },
              {
                term: {
                  "driverStatus.keyword": "Completed",
                },
              },
            ],
          },
        },
        aggs: {
          total_amount: {
            sum: {
              field: "total",
            },
          },
          daily_data: {
            date_histogram: {
              field: "createdDate",
              interval: "day",
              min_doc_count: 0,
            },
            aggs: {
              daily_total: {
                sum: {
                  field: "total",
                },
              },
            },
          },
        },
      },
    },
  };
  try {
    const response = await axios.post(searchUrl, ReqQuery, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    const { last_week } = response.data?.aggregations;
    return {
      dailyData: last_week.daily_data.buckets.map((bucket: any) => ({
        date: bucket.key_as_string,
        total: bucket.doc_count,
      })),
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getNetProfit = async (
  indexName: string,
  storeId: string | null,
  timeZone: string = "Asia/Calcutta",
): Promise<AggregationData> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  try {
    const response = await axios.post(searchUrl, GetNetProfit, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const { last_week, previous_week } = response.data.aggregations;

    return {
      lastWeekTotal: last_week.weekly_total.value,
      dailyData: last_week.daily_data.buckets.map((bucket: any) => ({
        date: bucket.key_as_string,
        total: bucket.daily_total.value,
      })),
      previousWeekTotal: previous_week.weekly_total.value,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

interface ProductData {
  itemName: string;
  imageURL?: string;
  sellingPriceInclPrice2: string;
  trendingProduct: number;
}

interface TrendingProductsResponse {
  products: ProductData[];
}

export const getTrendingProducts =
  async (): Promise<TrendingProductsResponse> => {
    const indexName = "product";
    let index = process.env.REACT_APP_STACK
      ? `${indexName}${process.env.REACT_APP_STACK}`
      : indexName;

    const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

    try {
      const response = await axios.post(
        searchUrl,
        {
          size: 20,
          query: {
            match_all: {},
          },
          sort: [
            {
              trendingProduct: {
                order: "desc",
              },
            },
          ],
          _source: [
            "itemName",
            "imageURL",
            "extraFieldsProduct.sellingPriceInclPrice2",
            "trendingProduct",
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAuthToken(),
          },
        },
      );

      const products = response.data.hits.hits.map((hit: any) => ({
        itemName: hit._source.itemName,
        imageURL: hit._source.imageURL,
        sellingPriceInclPrice2:
          hit._source.extraFieldsProduct.sellingPriceInclPrice2,
        trendingProduct: hit._source.trendingProduct,
      }));

      return {
        products,
      };
    } catch (error) {
      console.error("Error performing search query:", error);
      throw error;
    }
  };

export const getSalesStatistics = async (
  indexName: string,
  calendarInterval: string,
  timeZone: string = "Asia/Calcutta",
  orderId: string | null,
): Promise<AggregationData> => {
  const index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const requestBody = {
    aggs: {
      order: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: calendarInterval,
          time_zone: "Asia/Calcutta",
          min_doc_count: 1,
        },
        aggs: {
          total: {
            sum: {
              field: "total",
            },
          },
        },
      },
    },
    size: 0,
    stored_fields: ["*"],
    script_fields: {},
    docvalue_fields: [
      {
        field: "actionLog.timestamp",
        format: "date_time",
      },
      {
        field: "createdDate",
        format: "date_time",
      },
      {
        field: "driverDetails.deliveryDate",
        format: "date_time",
      },
      {
        field: "driverDetails.expireTime",
        format: "date_time",
      },
      {
        field: "otpCreationTime",
        format: "date_time",
      },
      {
        field: "updatedDate",
        format: "date_time",
      },
    ],
    _source: {
      excludes: [],
    },
    query: {
      bool: {
        must: [],
        filter: [
          {
            term: {
              "storeId.keyword": orderId,
            },
          },
          {
            term: {
              "driverStatus.keyword": "Completed",
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const buckets = response.data.aggregations.order.buckets;

    const dailyData = buckets.map((bucket: any) => ({
      date: bucket.key_as_string,
      total: bucket.total.value || 0,
    }));

    const lastWeekTotal = buckets.reduce(
      (acc: number, bucket: any) => acc + (bucket.total.value || 0),
      0,
    );

    return {
      lastWeekTotal,
      dailyData,
      previousWeekTotal: 0, // Modify if you have a way to calculate the previous week's total
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

//==========================API CHANGES==========================
interface ProductStock {
  SK: string;
  sellQuantity: number;
}

interface CombinedProductData {
  SK: string;
  sellQuantity: number;
  itemName: string;
  salesPrice: number;
  costPrice: number;
  imageURL: string;
}

export const getProductStock = async (
  productType: string,
  storeId: string,
): Promise<ProductStock[]> => {
  const indexName = "productstock";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 20,
    query: {
      bool: {
        must: [
          {
            term: {
              "productType.keyword": productType,
            },
          },
          {
            term: {
              "storeId.keyword": storeId,
            },
          },
          {
            range: {
              sellQuantity: {
                gt: 0,
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        sellQuantity: {
          order: "desc",
        },
      },
    ],
    _source: ["productCode", "sellQuantity", "SK"],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.hits.hits.map((hit: any) => hit._source);
};

export const getProductDetails = async (SK: string): Promise<any> => {
  const indexName = "product";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    query: {
      term: {
        "SK.keyword": SK,
      },
    },
    _source: [
      "itemName",
      "sku",
      "extraFieldsProduct.salesPrice",
      "extraFieldsProduct.costPrice",
      "imageURL",
    ],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  const source = response.data.hits.hits[0]?._source;
  return {
    SK: source?.SK,
    sku: source?.sku,
    itemName: source?.itemName,
    salesPrice: source?.extraFieldsProduct?.salesPrice,
    costPrice: source?.extraFieldsProduct?.costPrice,
    imageURL: source?.imageURL,
  };
};

export const getCombinedProductData = async (
  productType: string,
  storeId: string | null,
): Promise<CombinedProductData[]> => {
  const productStock = await getProductStock(
    productType,
    storeId ? storeId : "",
  );
  const combinedData = await Promise.all(
    productStock.map(async (stock) => {
      const details = await getProductDetails(stock.SK);
      return {
        SK: stock.SK,
        sku: details.sku,
        sellQuantity: stock.sellQuantity,
        itemName: details.itemName,
        salesPrice: details.salesPrice,
        costPrice: details.costPrice,
        imageURL: details.imageURL,
      };
    }),
  );

  return combinedData;
};
