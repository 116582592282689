import Table from "../../Blocks/Table/Table";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import {
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { columns_name } from "./data";
import { reportResponse } from "./utils";
import {
  getAllExportReports,
  serchReports,
} from "../../Api/Search/Reports/SalesReports";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import * as XLSX from "xlsx";

type SearchData = {
  fields: string;
  value: string;
};

const SalesReports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [storelist, setStore] = useState<any[]>([]);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const { storeId } = useStoreId();
  const [exportData, setExportData] = useState<any[]>([]);
  const [exportButtonEnable, setexportButtonEnable] = useState(true);
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });

  // eslint-disable-next-line
  const getSaleslist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      try {
        const res = await serchReports(
          "productstock",
          filtervalue,
          Pagination,
          storeId,
        );
        const result = await reportResponse(res);
        setStore(result?.modifiedData || []);
        setTotal(result?.total);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    getSaleslist(filtervalue, Pagination);
  }, [getSaleslist, filtervalue, Pagination]);

  // Handle filter change
  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  useEffect(() => {
    const getReports = async () => {
      await getAllExportReports("", "productstock", storeId)
        .then(async (res) => {
          const result = await reportResponse(res);
          setExportData(result?.modifiedData || []);
          setexportButtonEnable(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getReports();
  }, [storeId]);

  const exportToExcel = () => {
    if (exportData.length === 0) return;

    const fieldsToExport = [
      "description",
      "sku",
      "availableQuantity",
      "sellQuantity",
      "productType",
    ];

    const customHeaders: Record<string, string> = {
      description: "Name",
      sku: "SKU",
      availableQuantity: "Available Quantity",
      sellQuantity: "Sell Quantity",
      productType: "Product Type",
    };

    const toReadableHeader = (key: string) => {
      return (
        customHeaders[key] ||
        key
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
          .toUpperCase()
      );
    };

    const keys = fieldsToExport;
    const headers = keys.map(toReadableHeader);

    const data = [
      headers,
      ...exportData.map((item) => keys.map((key) => item[key])),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);

    const columnWidths = keys.map((_, colIndex) => {
      const maxLength = Math.max(
        ...data.map((row) =>
          row[colIndex] ? row[colIndex].toString().length : 0,
        ),
        headers[colIndex].length,
      );
      return { width: maxLength + 2 };
    });

    ws["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const now = new Date();

    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(now);
    const day = now.getDate().toString().padStart(2, "0");
    const year = now.getFullYear();
    const dateString = `${monthName}_${day}_${year}`;

    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const timeString = `${hours}:${minutes} ${period}`;
    const fileName = `sales_report_${dateString}_${timeString.replace(/ /g, "-")}.xlsx`;

    XLSX.writeFile(wb, fileName);
  };

  return (
    <div>
      <div className="row my-4">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Sales Reports"}</h3>
        </div>
        {!exportButtonEnable && (
          <div className="col-auto ms-auto">
            <button
              className="btn btn-outline-success"
              onClick={exportToExcel}
              type="button"
            >
              Export
            </button>
          </div>
        )}
      </div>

      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={columns_name}
          data={storelist}
          paginationShow={true}
          isLoading={isLoading}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
          filterdata={filterdata}
        />
      </div>
    </div>
  );
};

export default SalesReports;
