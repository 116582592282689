const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  // {
  //   field: "name",
  //   label: "Name",
  //   type: "text",
  //   sub_header: {
  //     inputtype: "input",
  //     placeholder: "Name",
  //   },
  // },

  {
    field: "rewardsPointsPerRand",
    label: "Rewards Points Per Rand",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Rewards Points Per Rand",
    },
  },
  {
    field: "rewardsRandsPerPoint",
    label: "Rewards Rands Per Point",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Rewards Points Per Rand",
    },
  },
  {
    field: "rewardsPointsExpirationDays",
    label: "Rewards Points Expiration Days",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Rewards Points Expiration Days",
    },
  },

  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

export { columns_name };
