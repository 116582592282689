import { useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { columns_name, selectedKeys } from "./data";
import { Routers } from "../../Routes/Routes";
import "react-toastify/dist/ReactToastify.css";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import swal from "sweetalert";
import { setProductPriority, updateProduct } from "../../Api/Product/mutations";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ProductResponse } from "./utils";
import { searchProduct } from "../../Api/Search/Product/Product";
import debounce from "lodash.debounce";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  isValidJSON,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import moment from "moment";

const Products = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState();
  const [productType] = useState("STORE");
  const [itemImported] = useState("All");

  const navigate = useNavigate();
  const { storeId } = useStoreId();
  type SearchData = {
    fields: string;
    value: string;
  };
  const { errorType, setAlert, setMessage, setErrorType } = useAlert();
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });

  // eslint-disable-next-line
  const GetProductlist = useCallback(
    debounce(
      async (
        filtervalue: SearchData[],
        Pagination: any,
        productType: string,
        itemImported: string,
      ) => {
        setIsLoading(true);
        await searchProduct(
          "product",
          filtervalue,
          Pagination,
          storeId,
          productType,
          itemImported,
        )
          .then(async (res) => {
            const result = await ProductResponse(res);
            setProduct(result?.modifiedData);
            setTotal(result?.total);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      },
      3000,
    ),
    [storeId],
  );

  useEffect(() => {
    GetProductlist(filtervalue, Pagination, productType, itemImported);
  }, [GetProductlist, filtervalue, Pagination, productType, itemImported]);

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Product ${data?.itemName}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      const newData = {
        ...data,
        isDeleted: getStatus(data?.isDeleted) === "Disable" ? true : false,
        extraFieldsProduct: JSON.stringify(data?.extraFieldsProduct),
        createdDate: moment(data?.createdDate).toISOString(),
        updatedDate: moment(data?.updatedDate).toISOString(),
      };
      selectedKeys.forEach((key: any) => {
        delete newData[key];
      });
      delete newData?.value;
      delete newData?.label;
      delete newData?.salesPrice;

      updateProduct(newData)
        .then((response) => {
          const result = JSON.parse(response.data.updateProduct);
          const errorMessage = JSON.parse(result.body).error;
          if (result?.statusCode === 200) {
            swal(
              getStatus(data?.isDeleted),
              `Product has been ${getStatus(data?.isDeleted)} successfully`,
              "success",
            );
            GetProductlist(filtervalue, Pagination, productType, itemImported);
          } else {
            swal(errorMessage);
          }
        })
        .catch((err) =>
          swal("Failed to disable product. Please try again later."),
        );
    }
  };

  const { alert, message } = useAlert();

  const handleEdit = (data: any) => {
    navigate(
      `${Routers.updateproduct
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))
        .replace(":productType", encodeURIComponent(data.productType))}`,
    );
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  const [priorityLoader, setPriorityLoader] = useState(false);
  const [priorityButtonEnable, setPriorityButtonEnable] = useState(true);
  const Priority = async () => {
    setPriorityLoader(true);
    const processedData = product.map((item: any) => {
      return {
        PK: item?.PK,
        SK: item?.SK,
        priority: item?.priority,
        entityName: item?.entityName,
        id: item?.id,
      };
    });
    console.log("Processed Data:", processedData);
    await setProductPriority(processedData, "product").then((res) => {
      console.log(res);
      const result = isValidJSON(res.data.updateBulk)
        ? JSON.parse(res.data.updateBulk)
        : res.data.updateBulk;
      if (result?.statusCode === 200) {
        setAlert(true);
        setErrorType("success");
        setMessage("Priority Set Successfully");
        setPriorityLoader(false);
        setPriorityButtonEnable(true);
      } else {
        setAlert(true);
        setErrorType("success");
        setMessage("An error occurred. Please try again.");
        setPriorityLoader(false);
      }
    });
  };

  const handlePriorityData = (data: any) => {
    setPriorityButtonEnable(data);
  };

  return (
    <>
      {alert && message && (
        <Toast type={errorType ? errorType : "success"} message={message} />
      )}
      <div>
        <div className="row mb-4 mt-4 justify-content-between">
          <div className="col-auto">
            <h3 className="theme_color fw-bolder mb-2 mb-sm-0">{"Products"}</h3>
          </div>
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-12 col-md-auto d-flex flex-column flex-md-row gap-2">
                <button
                  className="btn btn-outline-success w-100"
                  onClick={() => Priority()}
                  disabled={priorityLoader || priorityButtonEnable}
                >
                  {priorityLoader ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Priority Set"
                  )}
                </button>
              </div>

              <div className="col-12 col-md-auto d-flex flex-column flex-md-row gap-2">
                <button
                  className="btn btn-outline-success w-100 mt-2 mt-md-0"
                  disabled
                  type="button"
                >
                  New Product
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="border p-4  border-grey  rounded  white_bg">
          <Table
            columns={columns_name}
            data={product || []}
            paginationShow={true}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            total={total}
            isLoading={isLoading}
            filterdata={filterdata}
            handlePagination={handlePagination}
            Pagination={Pagination}
            handlePageSize={handlePageSize}
            draggable={true}
            handlePriorityData={handlePriorityData}
          ></Table>
        </div>
      </div>
    </>
  );
};

export default Products;
