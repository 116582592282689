import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import Overview from "./Tabs/Overview/Overview";
import CategoriesHandler from "./Tabs/Categories/Main";
import Main from "./Tabs/Menu/Main";
import MainItems from "./Tabs/Items/Main";
import Customizations from "./Tabs/Customizations/Customizations";

const MenuManager = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const tab = pathParts[pathParts.length - 1];
    if (
      [
        "overview",
        "menu",
        "menu-categories",
        "items",
        "customizations",
      ].includes(tab)
    ) {
      setActiveTab(tab);
    }
  }, [location.pathname]);

  return (
    <div className="menu_manager_menu_wraper">
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item" role="presentation">
          <Link
            to={`${Routers.overview}`}
            className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
          >
            Overview
          </Link>
        </li>
        {/* <li className="nav-item" role="presentation">
          <Link
            to={`${Routers.menu}`}
            className={`nav-link ${activeTab === "menu" ? "active" : ""}`}
          >
            Menu
          </Link>
        </li> */}
        {/* <li className="nav-item" role="presentation">
          <Link
            to={`${Routers.menucategories}`}
            className={`nav-link ${
              activeTab === "menu-categories" ? "active" : ""
            }`}
          >
            Categories
          </Link>
        </li> */}
        <li className="nav-item" role="presentation">
          <Link
            to={`${Routers.items}`}
            className={`nav-link ${activeTab === "items" ? "active" : ""}`}
          >
            Items
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to={`${Routers.customizations}`}
            className={`nav-link ${
              activeTab === "customizations" ? "active" : ""
            }`}
          >
            Customizations
          </Link>
        </li>
      </ul>
      <div
        className={`tab-content pt-4 ${activeTab === "items" || "customizations" ? "" : "card"}  mt-4`}
        id="tab-content"
      >
        {activeTab === "overview" && <Overview />}
        {activeTab === "menu" && <Main />}
        {activeTab === "menu-categories" && <CategoriesHandler />}
        {activeTab === "items" && <MainItems />}
        {activeTab === "customizations" && <Customizations />}
      </div>
    </div>
  );
};

export default MenuManager;
