import {
  CHANGE_PASSWORD,
  CREATE_USER,
  DISABLE_USER,
  UPDATE_USER,
  onUpdateDriverLocationAPI,
} from "./query";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";

const client = generateClient();

export const createUser = async (CreatePreSignUpUserInput: any) => {
  try {
    const response = await client.graphql({
      query: CREATE_USER,
      variables: { input: CreatePreSignUpUserInput },
    });
    return response;
  } catch (error) {
    toast.error("Failed to add User. Please try again later.");
    return error;
  }
};

export const disableUser = async (
  CreatePreSignUpUserInput: any,
  PK: string,
  SK: string,
  username: string,
  operation: string,
) => {
  try {
    const response = await client.graphql({
      query: DISABLE_USER,
      variables: {
        input: CreatePreSignUpUserInput,
        PK,
        SK,
        username,
        operation,
      },
    });
    return response;
  } catch (error) {
    toast.error("Failed to disable User. Please try again later.");
    return error;
  }
};

export const updateUser = async (
  PK: string | undefined,
  SK: string | undefined,
  CreatePreSignUpUserInput: any,
) => {
  try {
    const response = await client.graphql({
      query: UPDATE_USER,
      variables: { PK: PK, SK: SK, input: CreatePreSignUpUserInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePassword = async (UserActionInput: {
  username: string | undefined;
  action?: string;
  newPassword?: string;
}) => {
  try {
    const response = await client.graphql({
      query: CHANGE_PASSWORD,
      variables: { input: UserActionInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUser = async (PK: string, SK: string) => {
  try {
    const response = await client.graphql({
      query: onUpdateDriverLocationAPI,
      variables: { PK, SK }, // Pass PK and SK as individual variables
    });
    return response; // Return the response from the mutation
  } catch (error) {
    toast.error("Failed to get User. Please try again later.");
    return error; // Consider logging the error for debugging
  }
};
