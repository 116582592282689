import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Routers } from "../../Routes/Routes";
import { addCommunication } from "../../Api/Communication/mutation";
import { useAlert } from "../ContextAPI/ContextApi";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import Toast from "../Toast/Toast";
import { generateUUID } from "../../Common Functions/Function";

const NewCommunication = () => {
  const { setAlert, setMessage, setErrorType } = useAlert();
  const navigate = useNavigate();
  const [saveLoader, setsaveLoader] = useState(false);
  const { alert, message, errorType } = useAlert();
  let index = process.env.REACT_APP_STACK
    ? `notification${process.env.REACT_APP_STACK}`
    : "notification";
  const formik = useFormik({
    initialValues: {
      messagesCode: generateUUID(),
      description: "",
      messagesTitle: "",
      messagesBody: "",
      role: "",
      // delivery: "",
      channel: "",
      entityName: index,
    },
    validationSchema: Yup.object({
      messagesCode: Yup.string()
        .max(100, "Message Code must be 100 characters or less")
        .required("Message Code is required")
        .matches(/^[^\s]/, "No spaces allowed at the start")
        .matches(/^(?!.* {2,})/, "More than one space is not allowed")
        .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),

      description: Yup.string()
        .required("Description is required")
        .matches(/^[^\s]/, "No spaces allowed at the start")
        .matches(/^(?!.* {2,})/, "More than one space is not allowed")
        .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),
      messagesTitle: Yup.string()
        .max(100, "Message Title must be 100 characters or less")
        .required("Message Title is required")
        .matches(/^[^\s]/, "No spaces allowed at the start")
        .matches(/^(?!.* {2,})/, "More than one space is not allowed")
        .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),
      messagesBody: Yup.string()
        .required("Message Body is required")
        .matches(/^[^\s]/, "No spaces allowed at the start")
        .matches(/^(?!.* {2,})/, "More than one space is not allowed")
        .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),
      role: Yup.string().required("Role is required"),
      // target: Yup.string()
      //   .required("Target is required")
      //   .matches(/^[^\s]/, "No spaces allowed at the start")
      //   .matches(/^(?!.* {2,})/, "More than one space is not allowed")
      //   .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),
      // delivery: Yup.string().required("Delivery method is required"),
      channel: Yup.string()
        .required("Channel is required")
        .matches(/^[^\s]/, "No spaces allowed at the start")
        .matches(/^(?!.* {2,})/, "More than one space is not allowed")
        .matches(/^(?!.* $)/, "Spaces are not allowed at the end"),
    }),
    onSubmit: (values) => {
      setsaveLoader(true);

      const formattedValue = {
        PK: values.role,
        action: values.channel,
        messagesBody: values?.messagesBody,
        messagesTitle: values?.messagesTitle,
        entityName: values?.entityName,
        id: values?.messagesCode,
      };
      console.log("formattedValues====", formattedValue);
      addCommunication(formattedValue)
        .then((res: any) => {
          const result = res?.data?.communicationStateMachines
            ? JSON.parse(res.data.communicationStateMachines)
            : res?.data?.communicationStateMachines;

          const errorMessage = result?.body
            ? JSON.parse(result.body).error
            : result?.error;

          if (result?.statusCode === 200) {
            navigate(Routers?.communication);
            setAlert(true);
            setErrorType("success");
            setMessage("Communication Added Successfully");
            setsaveLoader(false);
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setsaveLoader(false);
          }
        })
        .catch((err) => {
          setsaveLoader(false);
          console.log(err);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />
      {alert && message && (
        <Toast type={errorType ? errorType : "error"} message={message} />
      )}
      <div className="d-flex justify-content-end gap-2">
        <Link className="text-decoration-none" to={Routers?.communication}>
          <button className="btn btn-outline-danger" type="button">
            {"Cancel"}
          </button>
        </Link>
        <button className="btn btn-outline-success" type="submit">
          {"Save"}
        </button>
      </div>
      <div className="heading-title">
        <h4 className="theme_color fw-bolder">
          <Link
            className="text-decoration-none theme_color"
            to={Routers?.communication}
          >
            <i className="bi bi-chevron-left me-3"></i>
          </Link>
          {"Create A New Message"}
        </h4>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
            <h4 className="theme_color fw-bold align-self-center mb-3">
              {"General"}
            </h4>
            <div className="row">
              <div className="form-group col-12 mb-3">
                <label htmlFor="messagesCode" className="form-label">
                  Message Code<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="messagesCode"
                  name="messagesCode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messagesCode}
                  maxLength={100}
                  readOnly
                />
                {formik.touched.messagesCode && formik.errors.messagesCode ? (
                  <div className="text-danger">
                    {formik.errors.messagesCode}
                  </div>
                ) : null}
              </div>

              <div className="form-group col-12 mb-3">
                <label htmlFor="description" className="form-label">
                  Description<span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  rows={4}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="text-danger">{formik.errors.description}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12">
          <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
            <h4 className="theme_color fw-bold align-self-center mb-3">
              {"Message Details"}
            </h4>
            {/* Role Dropdown */}
            <div className="row">
              <div className="form-group col-6 mb-3">
                <label htmlFor="role" className="form-label">
                  Role<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="role"
                  name="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                >
                  <option value="" label="Select role" />
                  <option value="chef" label="Chef" />
                  <option value="customer" label="Customer" />
                  <option value="driver" label="Driver" />
                  <option value="manager" label="Manager" />
                  <option value="shopper" label="Shopper" />
                </select>
                {formik.touched.role && formik.errors.role ? (
                  <div className="text-danger">{formik.errors.role}</div>
                ) : null}
              </div>

              <div className="form-group col-6 mb-3">
                <label htmlFor="channel" className="form-label">
                  Channel<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="channel"
                  name="channel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.channel}
                >
                  <option value="" label="Select channel" />
                  <option value="sendText" label="SMS" />
                  <option value="email" label="Email" />
                  <option value="notification" label="Notification" />
                </select>
                {formik.touched.channel && formik.errors.channel ? (
                  <div className="text-danger">{formik.errors.channel}</div>
                ) : null}
              </div>
              {/* <div className="form-group col-6 mb-3">
                <label htmlFor="target" className="form-label">
                  Target<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="target"
                  name="target"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.target}
                >
                  <option value="" label="Select target" />
                  <option value="all" label="All" />
                </select>
                {formik.touched.target && formik.errors.target ? (
                  <div className="text-danger">{formik.errors.target}</div>
                ) : null}
              </div> */}
            </div>
            <div className="row">
              {/* Delivery Dropdown */}
              {/* <div className="form-group col-6 mb-3">
                <label htmlFor="delivery" className="form-label">
                  Delivery<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="delivery"
                  name="delivery"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.delivery}
                >
                  <option value="" label="Select delivery method" />
                  <option value="all" label="All" />
                  <option value="email" label="Email" />
                  <option value="sms" label="SMS" />
                </select>
                {formik.touched.delivery && formik.errors.delivery ? (
                  <div className="text-danger">{formik.errors.delivery}</div>
                ) : null}
              </div> */}

              {/* Channel Dropdown */}
            </div>
            <div className="row">
              <div className="form-group col-12 mb-3">
                <label htmlFor="messagesTitle" className="form-label">
                  Message Title<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="messagesTitle"
                  name="messagesTitle"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messagesTitle}
                  maxLength={100}
                />
                {formik.touched.messagesTitle && formik.errors.messagesTitle ? (
                  <div className="text-danger">
                    {formik.errors.messagesTitle}
                  </div>
                ) : null}
              </div>

              <div className="form-group col-12 mb-3">
                <label htmlFor="messagesBody" className="form-label">
                  Message Body<span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="messagesBody"
                  name="messagesBody"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messagesBody}
                  rows={4}
                />
                {formik.touched.messagesBody && formik.errors.messagesBody ? (
                  <div className="text-danger">
                    {formik.errors.messagesBody}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewCommunication;
