// import React, { useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useAlert } from "../ContextAPI/ContextApi";

// interface ToastProps {
//   type: "success" | "warning" | "error";
//   message: string;
// }

// const Toast: React.FC<ToastProps> = ({ type, message }) => {
//   const { setAlert } = useAlert();

//   useEffect(() => {
//     if (type && message) {
//       toast(message, {
//         type,
//         autoClose: 2000,
//         onClose: () => {
//           console.log("Toast closed");
//           setAlert(false);
//         },
//         closeOnClick: true,
//       });
//     }
//   }, [type, message, setAlert]);

//   return (
//     <ToastContainer
//       position="top-right"
//       autoClose={2000}
//       hideProgressBar={false}
//       newestOnTop={false}
//       closeOnClick={true}
//       rtl={false}
//       pauseOnFocusLoss={false}
//       draggable={false}
//       pauseOnHover={false}
//       theme="light"
//     />
//   );
// };

// export default Toast;

import React, { useEffect, useRef } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useAlert } from "../ContextAPI/ContextApi";

interface ToastProps {
  type: "success" | "warning" | "error";
  message: string;
}

const Toast: React.FC<ToastProps> = ({ type, message }) => {
  const { setAlert } = useAlert();
  const hasShown = useRef(false); // Track if the toast has been shown for the current message

  useEffect(() => {
    if (type && message && !hasShown.current) {
      const toastOptions = {
        duration: 2000,
        onClose: () => {
          console.log("Toast closed");
          setAlert(false);
          hasShown.current = false; // Reset after closing
        },
      };
      setTimeout(() => {
        setAlert(false);
      }, 2000);

      switch (type) {
        case "success":
          toast.success(message, toastOptions);
          break;
        case "error":
          toast.error(message, toastOptions);
          break;
        case "warning":
          toast(message, {
            ...toastOptions,
            style: { background: "#f0ad4e", color: "#fff" },
          });
          break;
        default:
          break;
      }

      hasShown.current = true; // Set to true after showing the toast
    }
  }, [type, message, setAlert]);

  return (
    <Toaster position="top-right" toastOptions={{ className: "toaster" }} />
  );
};

export default Toast;
