import { Link, useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { Routers } from "../../Routes/Routes";
import { columns_name } from "./data";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import { updateRecipes } from "../../Api/Recipie/mutations";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { searchRecipies } from "../../Api/Search/Recipies/Recipies";
import { RecipiesResponse } from "./utils";
import debounce from "lodash.debounce";
import { useStoreId } from "../ContextAPI/StoreIdContext";

type SearchData = {
  fields: string;
  value: string;
};

const Recipes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [recpiesData, setRecipie] = useState([]);
  const navigate = useNavigate();
  const { alert, message } = useAlert();
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const { storeId } = useStoreId();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });
  const [total, setTotal] = useState();

  // eslint-disable-next-line
  const GetRecipieslist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchRecipies("recipe", filtervalue, Pagination, storeId)
        .then(async (res: any) => {
          const result = RecipiesResponse(res);
          setRecipie(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          console.log(error);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    GetRecipieslist(filtervalue, Pagination);
  }, [GetRecipieslist, filtervalue, Pagination]);
  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Recipie ${data?.recipeName}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });
    data.recipeDetail = JSON.stringify(data.recipeDetail);
    data.ingredients = JSON.stringify(data.ingredients);
    data.imageUrl = JSON.stringify(data?.imageUrl);
    data.isDeleted = getStatus(data?.isDeleted) === "Disable" ? true : false;

    if (willDelete) {
      updateRecipes(data, data?.PK, data?.SK)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.updateRecipe);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            const status = getStatus(
              JSON.parse(result.body)?.updateResult?.isDeleted,
            );
            const isActivated = status !== "Disable";

            swal(
              isActivated ? "Disabled" : "Activated",
              `Recipe has been ${isActivated ? "Disabled" : "Activated"} successfully`,
              "success",
            );

            GetRecipieslist(filtervalue, Pagination);
          } else {
            swal(errorMessage);
          }
        })
        .catch((err) =>
          swal("Failed to disable Recipie. Please try again later."),
        );
    }
  };

  const handleEdit = (data: {
    SK: string;
    PK: string;
    parent_category: string;
  }) => {
    navigate(
      `${Routers.updaterecipes
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))}`,
    );
  };

  return (
    <div>
      {alert && message && <Toast type="success" message={message} />}
      <div className="row my-4 justify-content-between">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Recipes"}</h3>
        </div>
        <div className="col-auto ms-auto">
          <Link className="text-decoration-none" to={Routers?.newrecipes}>
            <button className="btn btn-outline-success " type="button">
              {"New Recipe"}
            </button>
          </Link>
        </div>
      </div>

      <div className="border p-4  border-grey  rounded  white_bg">
        <Table
          columns={columns_name}
          data={recpiesData || []}
          paginationShow={true}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          total={total}
          isLoading={isLoading}
          filterdata={filterdata}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
        ></Table>
      </div>
    </div>
  );
};

export default Recipes;
