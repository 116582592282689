const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "fullName",
    label: "Full Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Full Name",
    },
  },
  {
    field: "email",
    label: "Email",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Email",
    },
  },
  {
    field: "phone",
    label: "Contact",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Contact",
    },
  },
  {
    field: "createdDate",
    label: "Created At",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "Created At",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const data = [
  {
    id: "1",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "28 March, 2024",
    status: "true",
  },
  {
    id: "2",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "28 March, 2024",
    status: "true",
  },
  {
    id: "3",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "29 March, 2024",
    status: "true",
  },
  {
    id: "4",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "30 March, 2024",
    status: "true",
  },
  {
    id: "5",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "29 March, 2024",
    status: "true",
  },
];

export { columns_name, data };
