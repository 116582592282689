export const CREATE_CATEGORY = `
  mutation MyMutation($input: CategoryInput!) {
    createCategory(input: $input)
  }
  
`;

export const UPDATE_CATEGORY = `
mutation MyMutation2($input: CategoryInput!) {
  updateCategory(input: $input) 
}

`;

export const DELETE_CATEGORY = `
mutation MyMutation($PK: String!, $SK: String!) {
  deleteCategory(PK: $PK, SK: $SK) 
}
`;
