import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  // itemName: Yup.string()
  //   .matches(/^[^\s]/, "No spaces allowed at the start")
  //   .matches(/^(?!.* {2,})/, "More than one space is not allowed")
  //   .matches(/^(?!.* $)/, "Spaces are not allowed at the end")
  //   .required("Name is required"),
  // sku: Yup.string().required("SKU is required"),
  // categoryId: Yup.string().required("Category is required"),
  // itemStatus: Yup.boolean().required("Status is required"),
  // subcategoryId: Yup.string().required("Sub-Category is required"),
  // Takeaway: Yup.array().of(
  //   Yup.object({
  //     title: Yup.string().required("Title is required"),
  //     substituteDetails: Yup.array().of(
  //       Yup.object({
  //         products: Yup.array().of(
  //           Yup.object({
  //             productCode: Yup.string().required("Product is required"),
  //           }),
  //         ),
  //       }),
  //     ),
  //   }),
  // ),
});

export default validationSchema;
