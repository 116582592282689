import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  itemName: Yup.string()
    .matches(/^[^\s]/, "No spaces allowed at the start")
    .matches(/^(?!.* {2,})/, "More than one space is not allowed")
    .matches(/^(?!.* $)/, "Spaces are not allowed at the end")
    .required("Name is required"),
  sku: Yup.string().required("Product Code is required"),
  // categoryId: Yup.string().required("Category is required"),
  // subcategoryId: Yup.string().required("Sub-Category is required"),
  storeCategories: Yup.array()
    .min(1, "At least one category is required")
    .required("Category is required"),
  storeSubCategories: Yup.array()
    .min(1, "At least one sub-category is required")
    .required("Sub-Category is required"),

  itemStatus: Yup.boolean().required("Status is required"),
  // productSize: Yup.number()
  //   .positive("Product Size must be a positive number greater than 0.")
  //   .typeError("Product Size must be a valid number.")
  //   .min(0, "Cost Price must be a positive number"),
  salesPrice: Yup.number()
    .required("Sales Price is required")
    .typeError("Sales Price must be a valid number")
    .min(0, "Sales Price must be a positive number"),
  costPrice: Yup.number()
    .required("Cost Price is required")
    .typeError("Cost Price must be a valid number")
    .min(0, "Cost Price must be a positive number"),
  // salesPrice: Yup.number()
  //   .required("Sales Price is required")
  //   .typeError("Sales Price must be a valid number")
  //   .moreThan(0, "Sales Price must be greater than 0"),
});

export default validationSchema;
