import React, { ReactNode, createContext, useContext, useState } from "react";

// Define context type with errorType
interface AlertContextType {
  alert: boolean;
  message: string | null;
  errorType: "success" | "error" | "warning" | null;
  setAlert: (
    alert: boolean,
    message?: string | null,
    errorType?: "success" | "error" | "warning" | null,
  ) => void;
  setMessage: (message: string | null) => void;
  setErrorType: (errorType: "success" | "error" | "warning" | null) => void;
}

// Create context
const AlertContext = createContext<AlertContextType | undefined>(undefined);

// Create provider component
interface LayoutProps {
  children: ReactNode;
}
export const AlertProvider: React.FC<LayoutProps> = ({ children }) => {
  const [alert, setAlertState] = useState<boolean>(false); // Initialize with false
  const [message, setMessageState] = useState<string | null>(null);
  const [errorType, setErrorTypeState] = useState<
    "success" | "error" | "warning" | null
  >(null);

  const setAlert: AlertContextType["setAlert"] = (
    newAlert,
    newMessage = null,
    newErrorType = null,
  ) => {
    setAlertState(newAlert);
    setMessageState(newMessage);
    setErrorTypeState(newErrorType);
  };

  const setMessage: AlertContextType["setMessage"] = (newMessage) => {
    setMessageState(newMessage);
  };

  const setErrorType: AlertContextType["setErrorType"] = (newErrorType) => {
    setErrorTypeState(newErrorType);
  };

  return (
    <AlertContext.Provider
      value={{ alert, message, errorType, setAlert, setMessage, setErrorType }}
    >
      {children}
    </AlertContext.Provider>
  );
};

// Custom hook to use the alert context
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
