import React, { useCallback, useEffect, useRef, useState } from "react";
import { Routers } from "../../Routes/Routes";
import { Link, useNavigate } from "react-router-dom";
import Select from "../../Atoms/Select";
import {
  formattedDate,
  generateUUID,
  handleFileUpload,
  isValidJSON,
} from "../../Common Functions/Function";
import { useFormik } from "formik";
import { useAlert } from "../ContextAPI/ContextApi";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import {
  getAllCategory,
  getAllCategoryById,
} from "../../Api/Search/Category/Category";
import { categoryResponse } from "../Categories/utils";
import ReactQuill from "react-quill";
import { TagsInput } from "react-tag-input-component";
import { ProductResponse } from "./utils";
import { getAllProducts } from "../../Api/Search/Coupon/Coupons";
import { addProduct } from "../../Api/Product/mutations";
import ImageLoader from "../../Effects/ImageLoader";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";

interface FormData {
  [key: string]: any;
}

function TakeAwayNewProduct() {
  let index = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { alert, message, errorType, setErrorType, setAlert, setMessage } =
    useAlert();
  const { storeId } = useStoreId();
  const [loader, setLoader] = useState(false);
  const [parentItems, setParentItems] = useState<any>([]);
  const [subItems, setSubItems] = useState<any>([]);
  const [productlist, setproductlist] = useState<any>([]);
  const UUID = generateUUID();
  const [saveLoader, setsaveLoader] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      itemName: "",
      sku: "",
      subcategoryId: "",
      categoryId: "",
      categorySK: "",
      subcategorySK: "",
      description: "",
      itemStatus: true,
      imageURL: "",
      storeId: storeId,
      tags: [],
      entityName: index,
      isDeleted: `false`,
      createdDate: formattedDate(),
      updatedDate: formattedDate(),
      productType: "MEAL",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setsaveLoader(true);
      const newData: FormData = {
        extraFieldsProduct: JSON.stringify(Takeaway),
        PK: "PRODUCT",
        SK: `ACTIVE#${storeId}#${values.sku}`,
        productCode: UUID,
        id: UUID,
        ...values,
      };

      await addProduct(newData)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.createProduct);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.takeawayproducts);
            setAlert(true);
            setErrorType("error");
            setMessage("Product Added Successfully");
            return;
          } else {
            setErrorType("error");
            setAlert(true);
            setMessage(errorMessage);
          }
          setsaveLoader(false);
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const getCategorylist = useCallback(async () => {
    await getAllCategory("", "category", "Takeaway", storeId)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setParentItems(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getCategorylist();
  }, [getCategorylist]);

  useEffect(() => {
    const fetchData = async () => {
      if (formik?.values?.categoryId) {
        await getAllCategoryById(
          "",
          "subcategory",
          "",
          formik?.values?.categoryId,
          storeId,
        )
          .then((res) => {
            const result = categoryResponse(res);
            setSubItems(result?.modifiedData);
          })
          .catch((error) => {
            console.error("Error fetching categories:", error);
          });
      }
    };

    fetchData();
  }, [formik?.values?.categoryId, storeId]);

  const getProductlist = useCallback(async () => {
    await getAllProducts("", "product", storeId)
      .then((res) => {
        const result = ProductResponse(res);
        setproductlist(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getProductlist();
  }, [getProductlist]);

  const handleCategory = (data: any) => {
    let val = isValidJSON(data) ? JSON.parse(data) : data;
    formik.setFieldValue("categorySK", val.SK);
    formik.setFieldValue("categoryId", val.id);
    formik.setFieldValue("subcategorySK", "");
    formik.setFieldValue("subcategoryId", "");
  };

  const handleSubCategory = (data: any) => {
    let val = isValidJSON(data) ? JSON.parse(data) : data;
    formik.setFieldValue("subcategorySK", val.SK);
    formik.setFieldValue("subcategoryId", val.id);
  };

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    await handleFileUpload(
      event,
      setLoader,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setMessage("First select a store");
      setErrorType("error");
      return;
    }

    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const [Takeaway, setTakeaway] = useState<any>([
    {
      title: "",
      isDefault: true,
      substituteDetails: [
        {
          subtitle: "",
          products: [
            {
              type: "radio",
              productCode: "",
            },
          ],
        },
      ],
    },
  ]);

  // Add a new Takeaway item
  const addTakeaway = () => {
    setTakeaway([
      ...Takeaway,
      {
        title: "",
        isDefault: false,
        substituteDetails: [
          {
            subtitle: "",
            products: [
              {
                type: "",
                productCode: "",
              },
            ],
          },
        ],
      },
    ]);
  };
  const removeTakeaway = (index: number) => {
    if (Takeaway.length > 0) {
      const updatedTakeaway = [...Takeaway];
      updatedTakeaway.splice(index, 1); // Remove the item at the specified index
      setTakeaway(updatedTakeaway);
    }
  };

  const handleRemoveTakeaway = (index: number) => {
    removeTakeaway(index);
  };

  // Add a new substitute detail to a specific Takeaway item
  const addSubstituteDetail = (takeawayIndex: number) => {
    const updatedTakeaway = [...Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails.push({
      subtitle: "",
      products: [
        {
          type: "",
          productCode: "",
        },
      ],
    });
    setTakeaway(updatedTakeaway);
  };

  // Remove a substitute detail from a specific Takeaway item
  const removeSubstituteDetail = (
    takeawayIndex: number,
    detailIndex: number,
  ) => {
    const updatedTakeaway = [...Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails = updatedTakeaway[
      takeawayIndex
    ].substituteDetails.filter((_: any, i: number) => i !== detailIndex);
    setTakeaway(updatedTakeaway);
  };

  // Add a new product to a specific substitute detail
  const addProducts = (takeawayIndex: number, detailIndex: number) => {
    const updatedTakeaway = [...Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails[detailIndex].products.push(
      {
        type: "",
        productCode: "",
      },
    );
    setTakeaway(updatedTakeaway);
  };

  // Remove a product from a specific substitute detail
  const removeProduct = (
    takeawayIndex: number,
    detailIndex: number,
    productIndex: number,
  ) => {
    const updatedTakeaway = [...Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails[detailIndex].products =
      updatedTakeaway[takeawayIndex].substituteDetails[
        detailIndex
      ].products.filter((_: any, i: number) => i !== productIndex);
    setTakeaway(updatedTakeaway);
  };

  const MainProduct = (data: any) => {
    formik.setFieldValue("itemName", data?.itemName || "");
    formik.setFieldValue("sku", data?.sku || "");
  };

  return (
    <div>
      {loader && <ImageLoader />}
      {alert && message && (
        <Toast type={errorType ? errorType : "error"} message={message} />
      )}
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />

      <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title mt-2 mt-md-0">
          <h4 className="theme_color fw-bolder">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.takeawayproducts}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            Create A Take-Away Product
          </h4>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Link className="text-decoration-none" to={Routers?.takeawayproducts}>
            <button className="btn btn-outline-danger" type="button">
              Cancel
            </button>
          </Link>
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={handleSubmit}
            disabled={loader || saveLoader}
          >
            Save
          </button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
              <h4 className="theme_color fw-bold align-self-center mb-3">
                {"General"}
              </h4>
              <div className={`form-group row mb-3`}>
                <div className="mb-3 col-md-6">
                  <label htmlFor="description1" className={`form-label`}>
                    Category<span className="text-danger">*</span>
                  </label>

                  <Select
                    cacheOptions={true}
                    onChange={handleCategory}
                    defaultOptions={parentItems}
                    data={parentItems}
                    value={parentItems.find(
                      (item: { SK: string }) =>
                        item?.SK === formik?.values.categorySK,
                    )}
                  />
                  {formik.touched.categoryId && formik.errors.categoryId && (
                    <div className="text-danger">
                      {formik.errors.categoryId}
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="description2" className={`form-label`}>
                    Sub-Category<span className="text-danger">*</span>
                  </label>

                  <Select
                    onChange={handleSubCategory}
                    defaultOptions={subItems}
                    data={subItems}
                    value={
                      formik?.values?.subcategorySK === ""
                        ? null
                        : subItems.find(
                            (item: { SK: string }) =>
                              item?.SK === formik?.values?.subcategorySK,
                          )
                    }
                  />
                  {formik.touched.subcategoryId &&
                    formik.errors.subcategoryId && (
                      <div className="text-danger">
                        {formik.errors.subcategoryId}
                      </div>
                    )}
                </div>
              </div>

              <div className={`form-group col-12 mb-3`}>
                {/* <label
                  htmlFor="exampleFormControlTextarea1"
                  className={`form-label`}
                >
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="itemName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.itemName}
                />
                {formik.touched.itemName && formik.errors.itemName ? (
                  <div className="text-danger">{formik.errors.itemName}</div>
                ) : null} */}
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className={`form-label`}
                >
                  Name<span className="text-danger">*</span>
                </label>
                <Select
                  type="text"
                  onChange={(selectedOption) => MainProduct(selectedOption)}
                  name="itemName"
                  value={
                    productlist.find(
                      (option: { value: string }) =>
                        option.value === formik.values.itemName,
                    ) || null
                  }
                  placeholder="Select Product"
                  defaultOptions={productlist}
                  data={productlist}
                />
                {formik.touched.itemName && formik.errors.itemName ? (
                  <div className="text-danger">{formik.errors.itemName}</div>
                ) : null}
              </div>

              <div className={`form-group col-12 mb-3`}>
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className={`form-label`}
                >
                  Product Code<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="sku"
                  readOnly
                  onChange={formik.handleChange}
                  value={formik.values?.sku}
                />
                {formik.touched.sku && formik.errors.sku ? (
                  <div className="text-danger">{formik.errors.sku}</div>
                ) : null}
              </div>
              <div className="row">
                <div className={`form-group col-12 mb-3`}>
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Tags
                  </label>
                  <div className="form-group col-12">
                    <TagsInput
                      name="tags"
                      onChange={(tags) =>
                        formik.handleChange({
                          target: { name: "tags", value: tags },
                        })
                      }
                      value={formik.values.tags}
                    />
                    <small className="form-text text-muted">
                      <i className="bi bi-info-circle"></i> Press Enter after
                      typing your tag to add it.
                    </small>
                  </div>
                </div>
              </div>

              <div className={`form-group col-12 mb-3`}>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Description
                  </label>
                  <ReactQuill
                    theme="snow"
                    onChange={(content, delta, source, editor) => {
                      formik.setFieldValue("description", editor.getHTML());
                    }}
                    value={formik.values.description}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6] }],
                        ["bold", "italic", "link"],
                        [{ list: "ordered" }],
                        ["blockquote"],
                      ],
                    }}
                  />
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="dynamic-fields col-12">
                    <h4 className="theme_color fw-bold mb-3">Takeaway Items</h4>
                    {Takeaway.map((takeaway: any, takeawayIndex: number) => (
                      <>
                        <div key={takeawayIndex} className="card mb-3">
                          <div className="card-body">
                            <div className="takeaway-item">
                              <div className="mb-3">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={takeaway.title}
                                  onChange={(e) => {
                                    const updatedTakeaway = [...Takeaway];
                                    updatedTakeaway[takeawayIndex].title =
                                      e.target.value;
                                    setTakeaway(updatedTakeaway);
                                  }}
                                  placeholder="Title"
                                />
                              </div>

                              {takeaway.substituteDetails.map(
                                (detail: any, detailIndex: number) => (
                                  <div
                                    key={detailIndex}
                                    className="substitute-detail mb-3"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-8 col-md-10 mb-2">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={detail.subtitle}
                                          onChange={(e) => {
                                            const updatedTakeaway = [
                                              ...Takeaway,
                                            ];
                                            updatedTakeaway[
                                              takeawayIndex
                                            ].substituteDetails[
                                              detailIndex
                                            ].subtitle = e.target.value;
                                            setTakeaway(updatedTakeaway);
                                          }}
                                          placeholder="Subtitle"
                                        />
                                      </div>
                                      <div className="col-4 col-md-2 d-flex justify-content-end">
                                        {takeaway.substituteDetails.length >
                                          1 && (
                                          <i
                                            onClick={() =>
                                              removeSubstituteDetail(
                                                takeawayIndex,
                                                detailIndex,
                                              )
                                            }
                                            className="bi bi-dash-circle remove_icon"
                                          ></i>
                                        )}
                                      </div>
                                    </div>

                                    {detail.products.map(
                                      (product: any, productIndex: number) => (
                                        <div
                                          key={productIndex}
                                          className="product-item row mb-2 align-items-center"
                                        >
                                          <div className="col-12 col-sm-8 col-md-5 mb-2">
                                            <select
                                              className="form-control"
                                              value={product.type}
                                              onChange={(e) => {
                                                const updatedTakeaway = [
                                                  ...Takeaway,
                                                ];
                                                updatedTakeaway[
                                                  takeawayIndex
                                                ].substituteDetails[
                                                  detailIndex
                                                ].products[productIndex].type =
                                                  e.target.value;
                                                setTakeaway(updatedTakeaway);
                                              }}
                                            >
                                              <option value="" disabled>
                                                Select Type
                                              </option>
                                              <option value="checkbox">
                                                Checkbox
                                              </option>
                                              <option value="radio">
                                                Radio
                                              </option>
                                            </select>
                                          </div>

                                          <div className="col-12 col-sm-8 col-md-5 mb-2">
                                            <Select
                                              type="text"
                                              value={product.value}
                                              onChange={(e) => {
                                                const updatedTakeaway = [
                                                  ...Takeaway,
                                                ];
                                                updatedTakeaway[
                                                  takeawayIndex
                                                ].substituteDetails[
                                                  detailIndex
                                                ].products[
                                                  productIndex
                                                ].productCode = e?.value;
                                                setTakeaway(updatedTakeaway);
                                              }}
                                              placeholder="Product Code"
                                              defaultOptions={productlist}
                                              data={productlist}
                                            />
                                          </div>

                                          <div className="col-12 col-sm-6 col-md-2 d-flex justify-content-end">
                                            {detail.products.length > 1 && (
                                              <i
                                                onClick={() =>
                                                  removeProduct(
                                                    takeawayIndex,
                                                    detailIndex,
                                                    productIndex,
                                                  )
                                                }
                                                className="bi bi-dash-circle remove_icon"
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                      ),
                                    )}

                                    <div className="d-flex gap-3 justify-content-end">
                                      <button
                                        onClick={() =>
                                          addProducts(
                                            takeawayIndex,
                                            detailIndex,
                                          )
                                        }
                                        className="btn btn-outline-success"
                                      >
                                        Add Product
                                      </button>

                                      {takeaway.substituteDetails.length - 1 ===
                                        detailIndex && (
                                        <button
                                          onClick={() =>
                                            addSubstituteDetail(takeawayIndex)
                                          }
                                          className="btn btn-outline-success"
                                        >
                                          Add Sub-Title
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end gap-2 mb-3">
                          {takeawayIndex > 0 && (
                            <div className="d-flex">
                              <button
                                onClick={() =>
                                  handleRemoveTakeaway(takeawayIndex)
                                }
                                className="btn btn-outline-danger"
                              >
                                Remove Takeaway
                              </button>
                            </div>
                          )}
                          <div className="d-flex">
                            <button
                              onClick={addTakeaway}
                              className="btn btn-outline-success"
                            >
                              Add Takeaway
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="border  border-grey  p-3 rounded mt-4 white_bg">
              <h4 className="theme_color fw-bold mb-3 align-self-center">
                {"Product Banner"}
              </h4>
              <div className="new_category_banner_inner">
                <div className="new_category_banner_content">
                  <div>
                    <i className="bi bi-arrow-up-circle upload_icon"></i>
                  </div>
                  <div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*,audio/*,video/*"
                      style={{ display: "none" }}
                      onChange={handleFileInputChangeAndUpload}
                      name="imageURL"
                    />
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={handleButtonClick}
                    >
                      Add Image
                    </button>
                  </div>
                  <div>
                    <span className="upload_img_text">
                      {"Click to upload an image"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {typeof formik?.values?.imageURL === "string" &&
              formik?.values?.imageURL && (
                <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    {"Preview Image"}
                  </h4>
                  <div className="new_category_banner_inner d-block">
                    <div className="new_category_banner_content h-100 p-2">
                      <div className="image-container-preview">
                        <img
                          src={formik?.values?.imageURL}
                          alt="img"
                          className="image-preview"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div className="border border-grey p-3 rounded mt-4 white_bg">
              <h4 className="theme_color fw-bold mb-3 align-self-center">
                STATUS
              </h4>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="itemStatus"
                  id="flexRadioDefault1"
                  value="disabled"
                  checked={!formik.values.itemStatus}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "itemStatus",
                      !formik.values.itemStatus,
                    );
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Disabled
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="itemStatus"
                  id="flexRadioDefault2"
                  value="enabled"
                  checked={formik.values.itemStatus}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "itemStatus",
                      !formik.values.itemStatus,
                    );
                  }}
                />
                <label
                  className="form-check-label theme_color"
                  htmlFor="flexRadioDefault2"
                >
                  Enabled
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakeAwayNewProduct;
