// StoreIdContext.js
import { createContext, useContext, useState, useMemo, ReactNode } from "react";
import { isValidJSON } from "../../Common Functions/Function";

interface StoreIdContextType {
  storeId: string | null;
  storeName: string | null;
  storeType: string | null;
  latitude: number | null; // New latitude property
  longitude: number | null; // New longitude property
  storeList: string[];
  warehouseList: string[];
  refetchStoreList: boolean;
  updateStoreId: (newStoreId: string | null) => void;
  updateStoreName: (newStoreName: string | null) => void;
  updateStoreType: (newStoreType: string | null) => void;
  updateLatitude: (newLatitude: number | null) => void; // New update function
  updateLongitude: (newLongitude: number | null) => void; // New update function
  updateStoreList: (newStoreList: string[]) => void;
  updateWarehouseList: (newWarehouseList: string[]) => void;
  updateRefetchStoreList: (newRefetch: boolean) => void;
}

const StoreIdContext = createContext<StoreIdContextType>({
  storeId: null,
  storeName: null,
  storeType: null,
  latitude: null,
  longitude: null,
  storeList: [],
  warehouseList: [],
  refetchStoreList: false,
  updateStoreId: () => {},
  updateStoreName: () => {},
  updateStoreType: () => {},
  updateLatitude: () => {}, // Default function
  updateLongitude: () => {}, // Default function
  updateStoreList: () => {},
  updateWarehouseList: () => {},
  updateRefetchStoreList: () => {},
});

export const useStoreId = () => useContext(StoreIdContext);

interface StoreIdProviderProps {
  children: ReactNode;
}

export const StoreIdProvider = ({ children }: StoreIdProviderProps) => {
  const [storeId, setStoreId] = useState<string | null>(
    localStorage.getItem("storeId"),
  );

  const [storeName, setStoreName] = useState<string | null>(
    localStorage.getItem("storeName"),
  );

  const [storeType, setStoreType] = useState<string | null>(
    localStorage.getItem("storeType"),
  );

  const [latitude, setLatitude] = useState<number | null>(
    isValidJSON(localStorage.getItem("latitude"))
      ? JSON.parse(localStorage.getItem("latitude") as string)
      : localStorage.getItem("latitude") || null,
  ); // New latitude state

  const [longitude, setLongitude] = useState<number | null>(
    isValidJSON(localStorage.getItem("longitude"))
      ? JSON.parse(localStorage.getItem("longitude") as string)
      : localStorage.getItem("longitude") || null,
  ); // New longitude state

  const [storeList, setStoreList] = useState<string[]>(
    JSON.parse(localStorage.getItem("storeList") || "[]"),
  );

  const [warehouseList, setWarehouseList] = useState<string[]>(
    JSON.parse(localStorage.getItem("warehouseList") || "[]"),
  );

  const [refetchStoreList, setRefetchStoreList] = useState<boolean>(false);

  const updateStoreId = (newStoreId: string | null) => {
    if (newStoreId === null) {
      localStorage.removeItem("storeId");
    } else {
      localStorage.setItem("storeId", newStoreId);
    }
    setStoreId(newStoreId);
  };

  const updateStoreName = (newStoreName: string | null) => {
    if (newStoreName === null) {
      localStorage.removeItem("storeName");
    } else {
      localStorage.setItem("storeName", newStoreName);
    }
    setStoreName(newStoreName);
  };

  const updateStoreType = (newStoreType: string | null) => {
    if (newStoreType === null) {
      localStorage.removeItem("storeType");
    } else {
      localStorage.setItem("storeType", newStoreType);
    }
    setStoreType(newStoreType);
  };

  const updateLatitude = (newLatitude: number | null) => {
    if (newLatitude === null) {
      localStorage.removeItem("latitude");
    } else {
      localStorage.setItem("latitude", JSON.stringify(newLatitude));
    }
    setLatitude(newLatitude);
  };

  const updateLongitude = (newLongitude: number | null) => {
    if (newLongitude === null) {
      localStorage.removeItem("longitude");
    } else {
      localStorage.setItem("longitude", JSON.stringify(newLongitude));
    }
    setLongitude(newLongitude);
  };

  const updateStoreList = (newStoreList: string[]) => {
    localStorage.setItem("storeList", JSON.stringify(newStoreList));
    setStoreList(newStoreList);
  };

  const updateWarehouseList = (newWarehouseList: string[]) => {
    localStorage.setItem("warehouseList", JSON.stringify(newWarehouseList));
    setWarehouseList(newWarehouseList);
  };

  const updateRefetchStoreList = (newRefetch: boolean) => {
    setRefetchStoreList(newRefetch);
  };

  const contextValue: StoreIdContextType = useMemo(
    () => ({
      storeId,
      storeName,
      storeType,
      latitude,
      longitude,
      storeList,
      warehouseList,
      refetchStoreList,
      updateStoreId,
      updateStoreName,
      updateStoreType,
      updateLatitude,
      updateLongitude,
      updateStoreList,
      updateWarehouseList,
      updateRefetchStoreList,
    }),
    [
      storeId,
      storeName,
      storeType,
      latitude,
      longitude,
      storeList,
      warehouseList,
      refetchStoreList,
    ],
  );

  return (
    <StoreIdContext.Provider value={contextValue}>
      {children}
    </StoreIdContext.Provider>
  );
};
