import axios from "axios";
import { getAuthToken } from "../utils";

interface SearchData {
  fields: string;
  value: string | { from: string; to: string };
}

interface Pagination {
  from: number;
  size: number;
}

interface Pagination {
  from: number;
  size: number;
}

export const searchCoupon = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object") {
        const rangeQuery: any = {};
        if (item.value.from !== "") {
          rangeQuery["gte"] = item.value.from.trim();
        }
        if (item.value.to !== "") {
          rangeQuery["lte"] = item.value.to.trim();
        }
        return {
          range: {
            [`extraFieldsProduct.${item.fields}`]: rangeQuery,
          },
        };
      } else if (item.fields === "startDate") {
        return {
          query_string: {
            fields: [item.fields],
            query: `${item.value}`,
          },
        };
      } else if (item.fields === "endDate") {
        return {
          query_string: {
            fields: [item.fields],
            query: `${item.value}`,
          },
        };
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value.trim()}*`,
          },
        };
      }
    });
  let val = { match_all: {} };

  // let val = { term: { storeId: storeId } };

  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    sort: [
      {
        createdAt: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [val],
      },
    },
  };
  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getAllCoupons = async (
  query: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  let val = { match_all: {} };

  const queryBody = {
    size: 10000,

    // query: {
    //   bool: {
    //     filter: [{ match: { isDeleted: "false" } }, val],
    //   },
    // },

    query: {
      bool: {
        filter: [val],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getAllProducts = async (
  query: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  let val = { term: { storeId: storeId } };

  const queryBody = {
    size: 10000,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        filter: [{ match: { isDeleted: "false" } }, val],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getProductByCode = async (
  productCode: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  let val = { term: { storeId: storeId } };

  const queryBody = {
    size: 1,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        filter: [
          { match: { isDeleted: false } },
          { match: { productCode: productCode } },
          val,
        ],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return [response.data?.hits?.hits[0]?._source];
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};
