import { ReactComponent as Search } from "../../../../Asset/search.svg";
import forwordArrow from "../../../../Asset/forword-arrow.png";
import cross from "../../../../Asset/cross.svg";
const CustomizationFirst = () => {
  return (
    <div>
      <div className="top-link">
        <span>Customizations</span>
        <img src={forwordArrow} alt="" />
        <p>Add to items</p>
      </div>
      <h5 className="mt-3 add-items-text">Add to items </h5>
      <p className="add-items-sub-text">
        Select which items these customizations will be{" "}
      </p>
      <div>
        <form className="d-flex">
          <div className="filter_wrap">
            <Search />
            <input
              type="search"
              className="form-control theme_color"
              aria-label="Sizing example input "
              aria-describedby="inputGroup-sizing-sm"
              placeholder="Search"
            />
          </div>
        </form>
      </div>
      <div className="mt-3">
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Pepperoni Pizza</p>
            <p className="sub-title">R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Vegetarian Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">BBQ Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Oxtail Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Margherita Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Four Season Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Hawaiin Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Mexicana Pizza</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center  items-main">
          <div className="first-items"></div>
          <div className="items">
            <p className="title">Chicken and Peppadew</p>
            <p>R170,00</p>
          </div>
          <div className="cross-img">
            <img src={cross} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizationFirst;
