//firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBrKdg4DotIYg1a-VGXkjpqSW4a3p39L_c",
  authDomain: "sa-pantry-431306.firebaseapp.com",
  projectId: "sa-pantry-431306",
  storageBucket: "sa-pantry-431306.firebasestorage.app",
  messagingSenderId: "544991910534",
  appId: "1:544991910534:web:909082d6031ac177ed0f07",
  measurementId: "G-CLXYVKED0G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get messaging instance
const messaging = getMessaging(app);

// Export the messaging instance for use in other parts of your app
export { messaging };
