export const DriverResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.userId,
      ...val._source,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};
