import { CREATE_COUPON } from "./query";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";

const client = generateClient();

export const addCoupons = async (CouponInput: any) => {
  try {
    const response = await client.graphql({
      query: CREATE_COUPON,
      variables: { input: CouponInput },
    });
    return response;
  } catch (error) {
    //@ts-expect-error
    toast.error(error?.errors[0].message);
    return error;
  }
};
