import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchAuthSession, updateUserAttributes } from "aws-amplify/auth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormEffect from "../../Effects/FormEffect";
import { PasswordSchema, ProfileSchema } from "./ValidationScheme";
import countryData from "../../Data/CountryCode.json";
import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { updatePassword, type UpdatePasswordInput } from "aws-amplify/auth";
import { useUserInfo } from "../ContextAPI/UserContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Profile = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone_number: "",
    country_code: "+27",
  });
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [profileLoader, setProfileLoader] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);
  const { updateUserInfo } = useUserInfo();
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const getUser = async () => {
    const session = await fetchAuthSession({ forceRefresh: true });
    const { name, email, phone_number } =
      (session?.tokens?.idToken?.payload as {
        name?: string;
        email?: string;
        phone_number?: string;
      }) || {};

    let countryCode = "";
    let phoneNumber = phone_number || "";
    let matchedCountry: any = null;
    const countryCodeWithoutPlus = phone_number?.replace(/^\+/, "");
    countryData.forEach((country) => {
      if (countryCodeWithoutPlus?.startsWith(country.phone)) {
        if (
          !matchedCountry ||
          country.phone.length > matchedCountry.phone.length
        ) {
          matchedCountry = country;
          countryCode = `+${country.phone}`;
          phoneNumber = countryCodeWithoutPlus.replace(country.phone, "");
        }
      }
    });
    if (matchedCountry) {
      const phoneLength = Array.isArray(matchedCountry.phoneLength)
        ? matchedCountry.phoneLength[0]
        : matchedCountry.phoneLength;
      if (phoneNumber.length !== phoneLength) {
        phoneNumber = "";
      }
    }

    setUser((prev) => ({
      ...prev,
      name: name || "",
      email: email || "",
      phone_number: phoneNumber,
      country_code: countryCode,
    }));
    setLoading(false);
  };
  useEffect(() => {
    getUser();
  }, []);

  const handleSubmit = async (
    values: typeof user,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setProfileLoader(true);
    try {
      await ProfileSchema.validate(values, { abortEarly: false });
      const userAttributes = {
        name: values?.name,
        email: values?.email,
        phone_number: values?.country_code + values?.phone_number,
      };
      await updateUserAttributes({ userAttributes })
        .then(async (res) => {
          const session = await fetchAuthSession({ forceRefresh: true });
          updateUserInfo(JSON.stringify(session?.tokens?.idToken?.payload));
          setProfileLoader(false);
          toast.success("Profile updated successfully.");
          getUser();
        })
        .catch((err) => {
          setProfileLoader(false);
          console.log(err);
        });
    } catch (errors) {
      console.error("Validation errors:", errors);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePasswordSubmit = async (
    values: { oldPassword: string; newPassword: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setPasswordLoader(true);
    // if (values.oldPassword !== values.newPassword) {
    //   setPasswordsMatch(true);
    //   setPasswordLoader(false);
    //   return;
    // }
    setPasswordLoader(true);
    try {
      await updatePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      } as UpdatePasswordInput)
        .then((res) => {
          toast.success("Password updated successfully.");
          setPasswordLoader(false);
        })
        .catch((error) => {
          const { message } = error;
          toast.error(message);
          console.log(error);
          setPasswordLoader(false);
        });
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const Cancel = () => {
    navigate(Routers?.dashboard);
  };

  return (
    <div className="w-100">
      <ToastContainer />
      <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title mt-2 mt-md-0">
          <h4 className="theme_color fw-bold">
            <Link
              className="text-decoration-none theme_color"
              to={Routers.dashboard}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            Profile
          </h4>
        </div>
      </div>

      {isLoading ? (
        <FormEffect />
      ) : (
        <>
          <div className="container">
            <div className="container">
              <div className="border border-grey p-3 rounded mt-3 mt-md-4 white_bg">
                <Formik
                  initialValues={user}
                  validationSchema={ProfileSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="name-input" className="form-label">
                              Name<span className="text-danger">*</span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              id="name-input"
                              name="name"
                              onBlur={handleBlur}
                              maxLength={50}
                              max={50}
                              min={3}
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="email-input" className="form-label">
                              Email
                            </label>
                            <Field
                              type="email"
                              className="form-control"
                              id="email-input"
                              name="email"
                              onBlur={handleBlur}
                              readOnly
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="phone-input" className="form-label">
                              Contact
                            </label>
                            <div className="input-group">
                              <Field
                                as="select"
                                className="form-select country-code-select"
                                id="country-code"
                                name="country_code"
                              >
                                {countryData.map((item) => (
                                  <option
                                    key={item.phone}
                                    value={`+${item.phone}`}
                                  >
                                    {`+${item.phone}`}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                type="number"
                                className="form-control"
                                id="phone-input"
                                onBlur={handleBlur}
                                name="phone_number"
                              />
                            </div>
                            <ErrorMessage
                              name="phone_number"
                              component="div"
                              className="text-danger"
                            />
                            <ErrorMessage
                              name="country_code"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-2">
                        <button
                          className="btn btn-outline-danger"
                          type="button"
                          onClick={() => {
                            Cancel();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="submit"
                          disabled={profileLoader}
                        >
                          {profileLoader ? (
                            <div
                              className="spinner-border spinner-border"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Update"
                          )}{" "}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* Password Update Form */}
          <div className="container">
            <div className="container">
              <div className="border border-grey p-3 rounded mt-3 mt-md-4 white_bg">
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  onSubmit={handlePasswordSubmit}
                  validationSchema={PasswordSchema}
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form>
                      <div className="mb-3">
                        <label htmlFor="old-password" className="form-label">
                          Old Password
                        </label>
                        <div className="position-relative">
                          <Field
                            type={oldPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="old-password"
                            name="oldPassword"
                            onBlur={handleBlur}
                            maxLength={12}
                          />
                          <span
                            className="position-absolute end-0 top-50 translate-middle-y me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setOldPasswordVisible(!oldPasswordVisible)
                            }
                          >
                            {oldPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="new-password" className="form-label">
                          New Password
                        </label>
                        <div className="position-relative">
                          <Field
                            type={newPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="new-password"
                            name="newPassword"
                            onBlur={handleBlur}
                            maxLength={12}
                          />
                          <span
                            className="position-absolute end-0 top-50 translate-middle-y me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setNewPasswordVisible(!newPasswordVisible)
                            }
                          >
                            {newPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="confirm-password"
                          className="form-label"
                        >
                          Confirm Password
                        </label>
                        <div className="position-relative">
                          <Field
                            type={confirmPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="confirm-password"
                            name="confirmPassword"
                            onBlur={handleBlur}
                            maxLength={12}
                          />
                          <span
                            className="position-absolute end-0 top-50 translate-middle-y me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          >
                            {confirmPasswordVisible ? (
                              <FaEye />
                            ) : (
                              <FaEyeSlash />
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="d-flex justify-content-end gap-2">
                        <button
                          className="btn btn-outline-danger"
                          type="button"
                          onClick={Cancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="submit"
                        >
                          {passwordLoader ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Update Password"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
