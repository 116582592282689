import { Link } from "react-router-dom";
import { Routers } from "../../../../Routes/Routes";
import { MenuShow } from "./data";
import { ReactComponent as Vector } from "../../../../Asset/Vector.svg";

interface MenuProps {
  handler: (category: string) => void;
}

const NewMenu: React.FC<MenuProps> = ({ handler }) => {
  return (
    <div className="menu_wrapper">
      <div className="row justify-content-between mb-4 align-items-center">
        <div className="col-auto">
          <Link
            to={Routers?.menumanager}
            onClick={() => handler(MenuShow.MENU)}
            className="text-decoration-none"
          >
            <Vector />
          </Link>
        </div>
        <div className="col-auto d-flex gap-2">
          <button
            className="btn btn-outline-danger"
            onClick={() => handler(MenuShow.MENU)}
            type="button"
          >
            Cancel
          </button>
          <button className="btn btn-outline-info" type="button">
            Duplicate
          </button>
          <button className="btn btn-outline-success" type="button">
            Save
          </button>
        </div>
      </div>

      <div className="row mb-4 justify-content-between align-items-center">
        <div className="col-auto">Name</div>
        <div className="col-auto d-flex ">
          <i className="bi bi-file-earmark-spreadsheet me-2"></i>
          <p className="m-0">Add Note</p>
        </div>
      </div>
      <div className="border-bottom mb-4"></div>

      <div className="row">
        <div className="col-md-8">
          <h4 className="fw-bolder mb-4">Menu Availability</h4>
          <h6 className="fw-bolder mb-4">Menu is active</h6>

          <div className="row mb-4 align-items-center">
            <div className="col-auto fw-bold">
              Customers can view and order from this menu
            </div>
            <div className="col-auto d-flex align-items-center">
              <div className={`form-group `}>
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border rounded p-4 mb-4">
            <div className="row align-items-center mb-4 gap-2 ">
              <div className="col-md-6">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Start Time
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example "
                >
                  <option selected>Select...</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Start End
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example "
                >
                  <option selected>Select...</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Open 24 Hours
              </label>
            </div>
          </div>

          <p className="text-danger">Add More Days and Times</p>
        </div>
      </div>
    </div>
  );
};

export default NewMenu;
