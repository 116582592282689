import { CREATE_RECIPES, DELETE_RECIPES, UPDATE_RECIPES } from "./query";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addRecipes = async (RecipeInput: any) => {
  try {
    const response = await client.graphql({
      query: CREATE_RECIPES,
      variables: { input: RecipeInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteRecpipes = async ({
  PK,
  SK,
}: {
  PK: string;
  SK: string;
}) => {
  try {
    const data = await client.graphql({
      query: DELETE_RECIPES,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateRecipes = async (
  RecipeInput: any,
  PK: string | undefined,
  SK: string | undefined,
) => {
  try {
    const response = await client.graphql({
      query: UPDATE_RECIPES,
      variables: { input: RecipeInput, PK: PK, SK: SK },
    });

    return response;
  } catch (error) {
    return error;
  }
};
