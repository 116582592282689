import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { searchDriver } from "../../Api/Search/Driver/Driver";
import {
  handlePageSizes,
  handlePaginations,
  isValidJSON,
} from "../../Common Functions/Function";
import { toast } from "react-toastify";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { DriverResponse } from "./utils";
import moment from "moment";
import { updateUser } from "../../Api/User/mutations";

interface User {
  group: string;
}
type SearchData = {
  fields: string;
  value: string;
};

const RoasterDriver = () => {
  const { alert, message, errorType } = useAlert();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User[]>([]);
  const [filtervalue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const { storeId } = useStoreId();

  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });
  // eslint-disable-next-line
  const getDriverlist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchDriver("user", filtervalue, Pagination, storeId)
        .then(async (res) => {
          const result = await DriverResponse(res);
          setUser(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching categories:", error);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    getDriverlist(filtervalue, Pagination);
  }, [getDriverlist, filtervalue, Pagination]);

  // const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e && e.target) {
  //     const { name, value } = e.target;
  //     updateFilterAndPagination(
  //       filtervalue,
  //       setFilterValue,
  //       setPagination,
  //       name,
  //       value
  //     );
  //   }
  // };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  const AssignTO = async (e: any, data: any) => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    const { value } = e.target;
    const userAttributes = {
      assignedTo: value,
    };
    await updateUser(data?.PK, data?.SK, userAttributes)
      .then(async (res: any) => {
        const parsedOuterJson = isValidJSON(res?.data?.updateUser)
          ? JSON.parse(res?.data?.updateUser)
          : res?.data?.updateUser;

        const statusCode = parsedOuterJson.statusCode;
        if (statusCode === 200) {
          setAlert(true);
          setMessage("Assign To successfully");
        } else {
          setAlert(true);
          setMessage("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  return (
    <div>
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <div className="row my-4 justify-content-between">
        <div className="col-auto">
          <h3 className="theme_color fw-bolder mb-0">{"Roaster Drivers"}</h3>
        </div>
      </div>
      <div className="border p-4 border-grey  rounded  white_bg">
        <table className="table table-fixed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Assign To</th>
              <th>Contact</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? [...Array(10)].map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {[...Array(5)].map((val, cellIndex) => (
                      <td key={cellIndex}>
                        <div className="shimmer-cell"></div>
                      </td>
                    ))}
                  </tr>
                ))
              : user &&
                user.map((roaster: any, index) => (
                  <tr key={index}>
                    <td>{roaster.fullName}</td>
                    <td>{roaster.email}</td>
                    <td>
                      {!storeId ? (
                        <option value="">{"First select a store"}</option>
                      ) : (
                        <select
                          className="form-select"
                          onChange={(e) => {
                            AssignTO(e, roaster);
                          }}
                          disabled
                        >
                          <>
                            <option value={roaster?.assignedTo}>
                              {roaster?.assignedTo
                                ? roaster.assignedTo.replace(/^DRIVER#\d+#/, "")
                                : "Not Assigned Yet"}
                            </option>

                            {roaster.assignedTo !==
                              `DRIVER#${storeId}#MEAL` && (
                              <option value={`DRIVER#${storeId}#MEAL`}>
                                MEAL
                              </option>
                            )}

                            {roaster.assignedTo !==
                              `DRIVER#${storeId}#STORE` && (
                              <option value={`DRIVER#${storeId}#STORE`}>
                                STORE
                              </option>
                            )}
                          </>
                        </select>
                      )}
                    </td>
                    <td>{roaster.phone}</td>
                    <td>
                      {moment(roaster.createdDate).format("DD MMM, YYYY")}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <ul className="pagination gap-2 left-side">
          <li className="page-item theme_color align-self-center pr-4">
            <span>{"Show"}</span>
          </li>
          <input
            className="form-control  theme_color"
            style={{ width: "50px" }}
            min={1}
            max={user.length}
            height={"36px"}
            onChange={handlePageSize}
            value={Pagination?.pageSize}
          />
          <li className="page-item theme_color align-self-center">
            <span>{"Per Page"}</span>
          </li>
        </ul>

        <ul className="pagination justify-content-center gap-2 align-items-center">
          <li
            className={`page-item ${
              Pagination?.from === 1 ? "disabled" : ""
            } round_circle_white  pointer `}
            onClick={() =>
              Pagination && Pagination?.page_no > 1 && handlePagination("prev")
            }
          >
            <i className="bi bi-caret-left"></i>
          </li>
          <li
            className={`page-item    
                 round_circle_green`}
          >
            {Pagination?.page_no}
          </li>

          {Pagination &&
            total &&
            Pagination?.page_no < Math.ceil(total / Pagination?.pageSize) && (
              <li
                className={`page-item round_circle_white  pointer`}
                onClick={() => handlePagination("next")}
              >
                <i className="bi bi-caret-right"></i>{" "}
              </li>
            )}
          <li className="page-item">{total ? total : user.length} records</li>
        </ul>
      </div>
    </div>
  );
};

export default RoasterDriver;
