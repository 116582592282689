const shopper_columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "fullName",
    label: "Full Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Full Name",
    },
  },
  {
    field: "email",
    label: "Email",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Email",
    },
  },
  {
    field: "phone",
    label: "Contact",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Contact",
    },
  },
  {
    field: "createdDate",
    label: "Created At",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "Created At",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const chefs_columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "fullName",
    label: "Full Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Full Name",
    },
  },
  {
    field: "email",
    label: "Email",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Email",
    },
  },
  {
    field: "phone",
    label: "Contact",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Contact",
    },
  },
  {
    field: "createdDate",
    label: "Created At",
    type: "date",
    sub_header: {
      inputtype: "input",
      size: "158px",
      placeholder: "Created At",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

export { chefs_columns_name, shopper_columns_name };
