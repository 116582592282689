import React from "react";
import backArrow from "./../../../../Asset/back-arrow.svg";
import { ReactComponent as Search } from "../../../../Asset/search.svg";
import Downarrow from "../../../../Asset/down-arrow.png";
import menu from "../../../../Asset/menu.svg";
import more from "../../../../Asset/more.png";
import "./Items.css";

import Select, { StylesConfig } from "react-select";
import { Link } from "react-router-dom";
import { Routers } from "../../../../Routes/Routes";
import AddModifierGroupModal from "./AddModifierGroupModal";
import CopyModifireGroupModal from "./CopyModifireGroupModal";

interface MenuCategoriesProps {
  handler: (category: string) => void;
}
const customStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    zIndex: 99,
    backgroundColor: state.isFocused ? "#54634B" : "rgba(242, 225, 206, 0.2)",
    color: state.isFocused ? "#FCF9F5" : "#54634B",
    "&:hover": {
      backgroundColor: "#54634B",
      color: "#FCF9F5",
    },
  }),
};
const options = [
  { value: "Vegan", label: "Vegan" },
  { value: "Roast", label: "Roast" },
];
const NewItems: React.FC<MenuCategoriesProps> = ({ handler }) => {
  return (
    <>
      <div className="new-item-container">
        <div className="top-view">
          <img src={backArrow} alt="" />
          <button className="btn btn-outline-success" type="button">
            New Menu
          </button>
        </div>
        <div className="border p-4  border-grey  rounded  white_bg mt-4">
          <div className={`form-group col-12 mb-3`}>
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              Name
            </label>
            <input type="text" className="form-control" name="name" />
          </div>
          <div>
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              Photo
            </label>
            <div className="new-item-img-container">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="new_category_banner_inner">
                    <div className="new_category_banner_content">
                      <div>
                        <button type="button" className="btn ">
                          Drop image here to upload
                        </button>
                      </div>
                      <div>Or</div>
                      <div>
                        <span className="browse-filetext">Browse File</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 ">
                  <p className="img-text">
                    Photos help customers decide what to order and can increase
                    sales.
                  </p>
                  <p className="img-file-text">
                    File requirement JPG, PNG, GIF, or WEBP up to 10MB Minimum
                    pixels required: 320 for width and height
                  </p>
                  <div>
                    <button
                      type="button"
                      className="btn  "
                      disabled
                      style={{
                        background: "#54634B",
                        color: "#FCF9F5",
                        fontWeight: "600",
                      }}
                    >
                      Add Photo
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline ml-2"
                      disabled
                      style={{
                        marginLeft: "10px",
                        borderColor: "#54634B",
                        fontWeight: "600",
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`form-group col-12 mb-3`}>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Description (optional)
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={4}
                  name="description"
                ></textarea>
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <label className="form-label">Sell item on its own?</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
          <div className="mb-4 mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Categories
              </label>
              <form className="d-flex">
                <div className="filter_wrap">
                  <Search />
                  <input
                    type="search"
                    className="form-control theme_color"
                    aria-label="Sizing example input "
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Search"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row align-items-center mb-4 gap-2 mt-3">
            <div className="col-md-12">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Price and how it’s Sold
              </label>
              <select
                className="form-select"
                aria-label="Default select example "
              >
                <option selected>Price By</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="border p-4  border-grey  rounded  white_bg mt-4">
        <div className="row">
          <div className={`form-group col-md-4 mb-3`}>
            <label className="form-label">
              Default Price{" "}
              <span className="label-span">(Add Menu Specific Pricing)</span>
            </label>
            <input type="text" className="form-control " />
            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label ">Sold by Delivery</label>
            </div>
          </div>
          <div className={`form-group col-md-4 mb-3`}>
            <label className="form-label">
              Default Price{" "}
              <span className="label-span">(Add Menu Specific Pricing)</span>
            </label>
            <input type="text" className="form-control " />
            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label ">Sold by Delivery</label>
            </div>
          </div>
          <div className={`form-group col-md-4 mb-3`}>
            <label className="form-label">VAT</label>
            <input type="text" className="form-control " />
            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label ">Sold by Delivery</label>
            </div>
          </div>
        </div>
      </div>

      <div className="border p-4  border-grey  rounded  white_bg mt-4 mb-4">
        <div className="row">
          <label className="form-label">Temperature</label>
        </div>
        <div className="mb-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="option1"
            />
            <label className="form-check-label">Heated</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="option2"
            />
            <label className="form-check-label">Unheated</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="option2"
            />
            <label className="form-check-label">Cold</label>
          </div>
        </div>

        <div>
          <div className="row">
            <label className="form-label">Tax Category</label>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <Select
                className="multi-select"
                options={options}
                isMulti
                menuPlacement="auto"
                styles={customStyles}
                placeholder="Choose a category that best describes this item for tax compliance"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div className=" d-flex justify-content-start gap-2">
            <Link className="text-decoration-none" to={Routers?.recipes}>
              <button className="btn btn-outline-success" type="button">
                vegetarian
              </button>
            </Link>
            <button className="btn btn-outline-success " type="button">
              Vegan
            </button>
          </div>
        </div>
      </div>

      <div className="border p-4  border-grey  rounded  white_bg mt-4 mb-4">
        <div className="row">
          <label className="form-label cus-item-label">Customize Item</label>
        </div>
        <p>
          Modifier groups allow customers to use toppings, sides and more to
          customize items
        </p>
        <div className="row">
          <div className="col-md-12">
            <span className="add-modifiler-group-text">
              <AddModifierGroupModal />
            </span>
            <span className="add-modifiler-group-text">
              <CopyModifireGroupModal />
            </span>
            <span className="add-modifiler-group-text">Clear</span>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <p className="expend-all">Expend All</p>
          <p className="expend-all">Collapse All</p>
        </div>
        <div className="d-flex accordion-container">
          <div className="left-section">
            <img src={Downarrow} alt="" />
          </div>
          <div className="right-section">
            <div className="accordion-item">
              <div
                className="accordion-header d-flex justify-content-between align-items-center"
                id="headingOne"
              >
                <div className="d-flex align-items-center">
                  <div className="menu-img">
                    <img src={menu} alt="" />
                  </div>
                  <div className="choose-base">
                    <p>Choose your Base</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="customer-select-text">
                    <p>Customer Selects 1</p>
                  </div>
                  <div className="menu-img">
                    <img src={more} alt="" />
                  </div>
                </div>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="body-data">
                    <div>
                      <p>Regulat Base</p>
                    </div>
                    <div>
                      <p>Rs. 300.00</p>
                    </div>
                  </div>
                  <div className="body-data">
                    <div>
                      <p>Gulten free Base</p>
                    </div>
                    <div>
                      <p>Rs. 300.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewItems;
