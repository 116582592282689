import React from "react";

interface LoadingOverlayProps {
  isVisible: boolean;
  message?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isVisible,
  message,
}) => {
  if (!isVisible) return null;

  return (
    <div className="form-blocker">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="message">{message || "Loading..."}</div>
    </div>
  );
};

export default LoadingOverlay;
