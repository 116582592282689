import {
  CREATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from "./query";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addSubCategory = async (subCategoryInput: any) => {
  try {
    const response = await client.graphql({
      query: CREATE_SUB_CATEGORY,
      variables: { input: subCategoryInput },
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const updateSubCategory = async (CategoryInput: any) => {
  try {
    const response = await client.graphql({
      query: UPDATE_SUB_CATEGORY,
      variables: { input: CategoryInput },
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const deleteSubCategory = async ({
  PK,
  SK,
}: {
  PK: string;
  SK: string;
}) => {
  try {
    const data = await client.graphql({
      query: DELETE_SUB_CATEGORY,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    return error;
  }
};
