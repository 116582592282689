import React, { useState } from "react";
import NewMenu from "./NewMenu";
import Menu from "./Menu";
import { MenuShow } from "./data";

const Main = () => {
  const [selectedMenu, setSelectedMenu] = useState(MenuShow.MENU);

  const handler = (category: React.SetStateAction<string>) => {
    setSelectedMenu(category);
  };

  const renderCategory = () => {
    switch (selectedMenu) {
      case MenuShow.MENU:
        return <Menu handler={handler} />;
      case MenuShow.MENU_NEW:
        return <NewMenu handler={handler} />;
      default:
        <Menu handler={handler} />;
    }
  };

  return <>{renderCategory()}</>;
};

export default Main;
