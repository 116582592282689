import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Table from "../../Blocks/Table/Table";
import { columns_name } from "./data";
import { useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import debounce from "lodash.debounce";
import { processCustomerData } from "./utils";
import { searchCustomer } from "../../Api/Search/Customer/Customer";
import {
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";

interface User {
  group: string;
}
type SearchData = {
  fields: string;
  value: string;
};

const Customer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User[]>([]);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });

  // eslint-disable-next-line
  const getCustomerlist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchCustomer("user", filtervalue, Pagination)
        .then(async (res) => {
          const result = await processCustomerData(res);
          setUser(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching categories:", error);
        });
    }, 3000),
    [],
  );

  useEffect(() => {
    getCustomerlist(filtervalue, Pagination);
  }, [getCustomerlist, filtervalue, Pagination]);

  const navigate = useNavigate();

  const handleInfo = (data: { userId: string }) => {
    navigate(
      `${Routers.customerdetails.replace(
        ":userId",
        encodeURIComponent(data.userId),
      )}`,
    );
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  return (
    <div>
      <div className="row my-4">
        <div className="col-12 d-flex align-items-center">
          <h3 className="theme_color mb-0 fw-bolder">{"Customers"}</h3>
        </div>
      </div>

      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={columns_name}
          data={user || []}
          total={total}
          paginationShow={true}
          filterdata={filterdata}
          isLoading={isLoading}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          handleInfo={handleInfo}
        />
      </div>
    </div>
  );
};

export default Customer;
