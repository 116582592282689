import { FormConfigItem } from "../../Blocks/Form/interface";

const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "description",
    label: "Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Name",
    },
  },
  {
    field: "sku",
    label: "Product Code",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Product Code",
    },
  },
  {
    field: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Available Quantity",
    },
  },
  {
    field: "sellQuantity",
    label: "Sold Quantity",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Sold Quantity",
    },
  },
  {
    field: "productType",
    label: "Product Type",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Product Type",
    },
  },
];

const data = [
  {
    id: "1",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
  {
    id: "2",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
  {
    id: "3",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
];

const formconfig: FormConfigItem[] = [
  {
    heading: "General",
    sub_header: [
      {
        label: "Store Code",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "storeCode",
        required: true,
        readonly: true,
      },
      {
        label: "Store Title",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "name",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Reward Amount",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "rewardAmount",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Reward Rate(pts)",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "rewardRate",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Store Description",
        fieldtype: "textarea",
        type: "textarea",
        size: "col-12",
        name: "description",
        required: false,
      },
      // {
      //   label: "Store Manager",
      //   fieldtype: "input",
      //   type: "text",
      //   size: "col-12",
      //   name: "storeManager",
      //   required: true,
      //   min: 3,
      //   max: 50,
      // },
      {
        label: "Store Location",
        fieldtype: "location",
        type: "text",
        size: "col-12",
        name: "location",
        required: true,
      },

      {
        label: "Latitude",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "lat",
        required: true,
        readonly: true,
      },
      {
        label: "Longitude",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "long",
        required: true,
        readonly: true,
      },
      {
        label: "File",
        fieldtype: "file",
        type: "file",
        size: "col-6",
        name: "imageURL",
        required: false,
      },
      {
        label: "Status",
        fieldtype: "status",
        type: "text",
        size: "col-6",
        name: "itemStatus",
        required: false,
      },
    ],
  },
];

const inputfield: { [key: string]: string | boolean } = {
  PK: "",
  SK: "",
  name: "",
  storeCode: "",
  description: "",
  location: "",
  lat: "",
  long: "",
  storeManager: "",
};

export { columns_name, data, formconfig, inputfield };
