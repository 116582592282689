import Swal from "sweetalert2";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

export const handleNoCurrentUserError = async (): Promise<void> => {
  try {
    // Directly check Cognito session
    await checkSession();
  } catch (error) {
    console.error("Session check failed with error:", error);
    const errorMessage = await getErrorMessage();
    await showAlertAndLogout(errorMessage);
  }
};

// Check Cognito session
const checkSession = async (): Promise<void> => {
  try {
    await fetchAuthSession();
  } catch (error) {
    throw new Error("No current user");
  }
};

// Extract error message based on error type or message
const getErrorMessage = async (): Promise<string> => {
  return "Your session has expired. Please login again.";
};

export const showAlertAndLogout = async (
  errorMessage: string,
): Promise<void> => {
  try {
    setTimeout(() => {
      Logout();
    }, 2200);
    await Swal.fire({
      title: "Session Expired",
      text: errorMessage,
      icon: "error",
      timer: 5000,
      showConfirmButton: false,
      allowOutsideClick: false, // Prevent closing the modal by clicking outsides
      allowEscapeKey: false, // Prevent closing the modal with the Escape key
    });
  } catch (swalError) {
    console.error("Error displaying alert:", swalError);
    // Ensure logout and redirection if alert fails
    Logout();
  }
};

export const handleTimeout = async (): Promise<void> => {
  await showAlertAndLogout("Your session has timed out. Please log in again.");
};

const Logout = async () => {
  try {
    await signOut();
    localStorage.clear();
    window.location.href = "/login";
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
