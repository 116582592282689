export const ManagerResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.userId,
      ...val._source,
      drivertype: val._source?.assignedTo?.replace(/^DRIVER#\d+#/, ""),
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};
