import axios from "axios";
import { getAuthToken } from "./utils";

export const searchWithPKSK = async (
  indexName: string,
  PK: string | undefined,
  SK: string | undefined,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const queryBody = {
    query: {
      bool: {
        must: [
          {
            term: {
              "PK.keyword": PK,
            },
          },
          {
            term: {
              "SK.keyword": SK,
            },
          },
        ],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};
