import { CREATE_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } from "./query";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addCategory = async (CategoryInput: any) => {
  try {
    const response: any = await client.graphql({
      query: CREATE_CATEGORY,
      variables: { input: CategoryInput },
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const updateCategory = async (CategoryInput: any) => {
  try {
    const response: any = await client.graphql({
      query: UPDATE_CATEGORY,
      variables: { input: CategoryInput },
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const deleteCategory = async ({
  PK,
  SK,
}: {
  PK: string;
  SK: string;
}) => {
  try {
    const data = await client.graphql({
      query: DELETE_CATEGORY,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    return error;
  }
};
