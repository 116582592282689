// export const COMMUNICATION = `
//   mutation MyMutation($input: SubscriptionInput!) {
//     sendSmsEmailNotifications(input: $input)
//   }
// `;

export const COMMUNICATION = `
  mutation MyMutation($input: SubscriptionInput!) {
    communicationStateMachines(input: $input)
  }
`;
