import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import country from "../../Data/Countries.json";
import { useAlert } from "../ContextAPI/ContextApi";
import { createUser } from "../../Api/User/mutations";
import { formattedDate } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { useFormik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { DriverProfileSchema } from "./ValidationScheme";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";

const DriverSignUp = () => {
  const { storeId } = useStoreId();
  let index = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";
  const [progress, setProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const { alert, message, errorType } = useAlert();

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      name: "",
      phone_number: "",
      confirmPassword: "",
      country_code: "+27",
      entityName: index,
      storeId: storeId,
      assignedTo: "BOTH",
    },
    validationSchema: DriverProfileSchema,
    onSubmit: async (values) => {
      if (storeId === "") {
        toast.error("First select a store");
        return;
      }

      let index = process.env.REACT_APP_STACK
        ? `user${process.env.REACT_APP_STACK}`
        : "user";

      setProgress(true);
      let CreatePreSignUpUserInput = {
        PK: "driver",
        SK: "Active#",
        group: "driver",
        email: values?.email,
        fullName: values?.name,
        username: values?.username,
        password: values?.password,
        phone: `${values?.country_code}${values.phone_number}`,
        entityName: index,
        createdDate: formattedDate(),
        isDeleted: false,
        isOnline: false,
        assignedTo: `DRIVER#${storeId}#${values?.assignedTo}`,
        storeId: storeId,
      };

      createUser(CreatePreSignUpUserInput)
        .then((response: any) => {
          const userCreateUpdateDisabled = JSON.parse(
            response.data.userCreateUpdateDisabled,
          );
          const statusCode = userCreateUpdateDisabled.statusCode;
          const result = JSON.parse(userCreateUpdateDisabled.body);

          const errorMessage =
            result?.error ||
            result?.errorMessage ||
            result?.message ||
            "Unknown error occurred";

          if (statusCode === 200) {
            navigate(Routers?.drivers);
            setProgress(false);
            setAlert(true);
            setErrorType("success");
            setMessage("Driver Added Successfully");
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setProgress(false);
          }
        })
        .catch((error: any) => {
          setAlert(true);
          setErrorType("error");
          setProgress(false);
          setMessage(
            error?.Error?.message ||
              error?.errors[0].message ||
              "Unknown error occurred",
          );
        });
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="w-100">
      <ToastContainer />
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={progress} message="Please wait..." />

      <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title mt-2 mt-md-0">
          <h4 className="theme_color fw-bold">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.drivers}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            Create A driver
          </h4>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Link
            className="text-decoration-none theme_color"
            to={Routers?.drivers}
          >
            <button className="btn btn-outline-danger" type="button">
              Cancel
            </button>
          </Link>
          <button
            className="btn btn-outline-success "
            type="button"
            onClick={() => formik.handleSubmit()} // Correct the type issue here
          >
            {progress ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Create driver"
            )}
          </button>
        </div>
      </div>

      <div className="container">
        <div className="container">
          <div className="border border-grey p-3 rounded mt-3 mt-md-4 white_bg">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="name-input" className="form-label">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name-input"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    required
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email-input" className="form-label">
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email-input"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    required
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="username-input" className="form-label">
                    Username<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username-input"
                    name="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    required
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-danger">{formik.errors.username}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="phone-input" className="form-label">
                    Contact<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <select
                      className="form-select country-code-select"
                      id="country-code"
                      name="country_code"
                      onChange={formik.handleChange}
                      value={formik.values.country_code}
                    >
                      {country?.map((item) => (
                        <option
                          value={`+${item?.phone_code}`}
                          key={item.phone_code}
                        >
                          {`+${item?.phone_code}`}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      className="form-control"
                      id="phone-input"
                      name="phone_number"
                      onChange={formik.handleChange}
                      value={formik.values.phone_number}
                      required
                      min={0}
                      autoComplete="off"
                    />
                  </div>
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="text-danger">
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="password-input" className="form-label">
                  Assign To<span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={formik.handleChange}
                  name="assignedTo"
                  disabled
                >
                  <option value="BOTH">BOTH</option>
                  <option value="MEAL">MEAL</option>
                  <option value="STORE">STORE</option>
                </select>
                {formik.touched.assignedTo && formik.errors.assignedTo ? (
                  <div className="text-danger">{formik.errors.assignedTo}</div>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="password-input" className="form-label">
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="password-input"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      required
                      autoComplete="off"
                    />
                    <span
                      className="position-absolute end-0 top-50 translate-middle-y me-2"
                      style={{ cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="confirm-password-input"
                    className="form-label"
                  >
                    Confirm Password<span className="text-danger">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control"
                      id="confirm-password-input"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      required
                      autoComplete="off"
                    />
                    <span
                      className="position-absolute end-0 top-50 translate-middle-y me-2"
                      style={{ cursor: "pointer" }}
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-danger">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverSignUp;
