import { ReactComponent as Search } from "../../../../Asset/search.svg";
import plus from "../../../../Asset/plus.png";
import CustomizationFirst from "./CustomizationFirst";
// import CustomizationThird from "./CustomizationThird";
// import CustomizationFourth from "./CustomizationFourth";
// import CustomizationFive from "./CustomizationFive";
// import CustomizationSecond from "./CustomizationSecond";
import "./Customizations.scss";
import { useCallback, useEffect, useState } from "react";
import { addOption } from "../../../../Api/MenuManager/mutation";
import { useAlert } from "../../../ContextAPI/ContextApi";
import { generateUUID } from "../../../../Common Functions/Function";
import Toast from "../../../Toast/Toast";
import { useStoreId } from "../../../ContextAPI/StoreIdContext";
import { getAllOption } from "../../../../Api/Search/MenuManager/MenuManager";
import { OptionResponse } from "./utils";

type StateType = {
  PK: string;
  SK: string;
  entityName: string;
  isDeleted: boolean;
  itemAction: string;
  itemOption: string[];
  itemDescription: string;
  itemTitle: string;
  itemType: string;
};

const Customizations = () => {
  const [state, setState] = useState<StateType>({
    PK: "",
    SK: "",
    entityName: "",
    isDeleted: false,
    itemAction: "create",
    itemOption: [""],
    itemDescription: "",
    itemTitle: "",
    itemType: "Meal",
  });
  const { storeId } = useStoreId();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { alert, message, errorType } = useAlert();

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof StateType,
  ) => {
    const values = [...(state[fieldName] as string[])];
    values[index] = event.target.value;
    setState({ ...state, [fieldName]: values });
  };

  const handleAddField = (fieldName: keyof StateType) => {
    setState({
      ...state,
      [fieldName]: [...(state[fieldName] as string[]), ""],
    });
  };

  const handleRemoveField = (index: number, fieldName: keyof StateType) => {
    const values = [...(state[fieldName] as string[])];
    values.splice(index, 1);
    setState({ ...state, [fieldName]: values });
  };

  const { setAlert, setMessage, setErrorType } = useAlert();

  // const updateField = (field, value) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     [field]: value,
  //   }));
  // };

  const NewOption = () => {
    setIsLoading(true);
    const UUID = generateUUID();
    let index = process.env.REACT_APP_STACK
      ? `menu${process.env.REACT_APP_STACK}`
      : "menu";

    const input = {
      ...state,
      PK: "OPTION",
      SK: `ACTIVE#${UUID}`,
      entityName: index,
      id: UUID,
      itemOption: JSON.stringify(state?.itemOption),
    };

    addOption(input, setAlert, setErrorType, setMessage)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  const getAllOptionList = useCallback(async () => {
    await getAllOption("", "menu", storeId)
      .then(async (res) => {
        const result = await OptionResponse(res);
        setOptions(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getAllOptionList();
  }, [getAllOptionList, storeId]);

  return (
    <div className="customiza-tab-container">
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}

      <div className="row">
        <div className="col-md-8">
          <div className="left-section">
            <form className="d-flex">
              <div className="filter_wrap">
                <Search />
                <input
                  type="search"
                  className="form-control theme_color"
                  aria-label="Sizing example input "
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Search"
                />
              </div>
            </form>
            <div className="plus-btn">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src={plus} alt="" />
                Create a new option
              </button>
            </div>
            <div className="mt-4">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {options?.map((item: any, index: number) => {
                  const headingId = `flush-heading${index}`;
                  const collapseId = `flush-collapse${index}`;

                  return (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={headingId}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${collapseId}`}
                          aria-expanded="false"
                          aria-controls={collapseId}
                        >
                          {item?.itemTitle}
                        </button>
                      </h2>
                      <div
                        id={collapseId}
                        className="accordion-collapse collapse"
                        aria-labelledby={headingId}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div>
                            {item?.itemOption?.map(
                              (val: any, valIndex: number) => (
                                <p className="option-text" key={valIndex}>
                                  {val}
                                </p>
                              ),
                            )}
                          </div>
                          <div>Description :- {item?.itemDescription}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="right-section">
            <CustomizationFirst />
            {/* <CustomizationSecond/> */}
            {/* <CustomizationThird/> */}
            {/* <CustomizationFourth /> */}
            {/* <CustomizationFive/> */}

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div
                  className="modal-content"
                  style={{ height: "60vh", overflowY: "auto" }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      New Option
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className={`form-group col-12 mb-3`}>
                        <label htmlFor="itemTitle" className={`form-label`}>
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="itemTitle"
                          name="itemTitle"
                          value={state.itemTitle}
                          onChange={(e) =>
                            setState({ ...state, itemTitle: e.target.value })
                          }
                          required
                        />
                      </div>

                      <div className={`form-group col-12 mb-3`}>
                        <label
                          htmlFor="itemDescription"
                          className={`form-label`}
                        >
                          Item Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="itemDescription"
                          name="itemDescription"
                          value={state.itemDescription}
                          onChange={(e) =>
                            setState({
                              ...state,
                              itemDescription: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      {state.itemOption.map((option, index) => (
                        <div key={index} className={`form-group col-12 mb-3`}>
                          <label
                            htmlFor={`itemOption${index}`}
                            className={`form-label`}
                          >
                            Item Option {index + 1}
                          </label>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="text"
                              className="form-control"
                              id={`itemOption${index}`}
                              name="itemOption"
                              value={option}
                              onChange={(e) =>
                                handleInputChange(index, e, "itemOption")
                              }
                              required
                            />

                            {state.itemOption.length > 1 && (
                              <i
                                className="bi bi-x-circle"
                                onClick={() =>
                                  handleRemoveField(index, "itemOption")
                                }
                              ></i>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="d-flex justify-content-end">
                        <span
                          className="cursor-pointer"
                          onClick={() => handleAddField("itemOption")}
                        >
                          Add more +
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      onClick={() => NewOption()}
                      className="btn btn-outline-success"
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Save Options"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customizations;
