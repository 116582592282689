import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAlert } from "../../ContextAPI/ContextApi";
import { SubCategoryInput } from "../data";
import { useFormik } from "formik";
import validationSchema from "./validation";
import { handleFileUpload } from "../../../Common Functions/Function";
import ImageLoader from "../../../Effects/ImageLoader";
import { Routers } from "../../../Routes/Routes";
import FormEffect from "../../../Effects/FormEffect";
import { TagsInput } from "react-tag-input-component";
import { categoryResponse } from "../utils";
import { updateSubCategory } from "../../../Api/Sub-Category/mutation";
import { getAllCategory } from "../../../Api/Search/Category/Category";
import { searchWithPKSK } from "../../../Api/Search/mutation";
import Select from "../../../Atoms/Select";
import { useStoreId } from "../../ContextAPI/StoreIdContext";
import Toast from "../../Toast/Toast";
import LoadingOverlay from "../../../Effects/LoadingOverlay";
import { useDropzone } from "react-dropzone";

const UpdateSubCategories = () => {
  const { PK, SK } = useParams();
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const [isLoading, setisLoading] = useState(false);
  const [userData, setuserData] = useState<SubCategoryInput>();
  const [parentItems, setParentItems] = useState<any>([]);
  const [saveLoader, setsaveLoader] = useState(false);
  const [dataLoader, setdataLoader] = useState(false);
  const { storeId } = useStoreId();
  const { alert, message, errorType } = useAlert();
  let index = process.env.REACT_APP_STACK
    ? `subcategory${process.env.REACT_APP_STACK}`
    : "subcategory";

  const formik = useFormik({
    initialValues: {
      id: "",
      storeId: storeId,
      categoryId: "",
      categorySK: "",
      description: "",
      tags: [],
      entityName: index,
      storeType: "",
      name: "",
      imageURL: "",
      status: true,
      itemType: "",
      PK: "",
      SK: "",
      isDeleted: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setsaveLoader(true);
      await updateSubCategory(values)
        .then((res) => {
          const result = JSON.parse(res?.data?.updateSubCategory);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.categories);
            setAlert(true);
            setErrorType("success");
            setMessage("Sub-Category Update Successfully");
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
          }
          setsaveLoader(false);
        })
        .catch((error: any) => {
          setAlert(true);
          setErrorType("error");
          setMessage(error?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const getCategoryById = useCallback(
    async (PK: string | undefined, SK: string | undefined) => {
      setdataLoader(true);
      await searchWithPKSK("subcategory", PK, SK)
        .then(async (res) => {
          const result = await categoryResponse(res);
          setuserData(result?.modifiedData[0]);
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    [],
  );

  useEffect(() => {
    getCategoryById(PK, SK);
  }, [getCategoryById, PK, SK]);

  const getCategorylist = useCallback(async () => {
    await getAllCategory("", "category", "", storeId)
      .then((res) => {
        const result = categoryResponse(res);
        setParentItems(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getCategorylist();
  }, [getCategorylist]);

  useEffect(() => {
    if (userData) {
      setdataLoader(false);
      formik.setValues({
        ...formik.values,
        name: userData.name || "",
        categoryId: userData.categoryId || "",
        categorySK: userData.categorySK,
        description: userData.description || "",
        imageURL: userData.imageURL || "",
        id: userData.id || "",
        status: JSON.parse(userData?.status),
        tags: userData.tags || [],
        entityName: userData?.entityName,
        itemType: userData?.itemType || "",
        PK: userData?.PK,
        SK: userData?.SK,
        isDeleted: userData?.isDeleted,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const e: any = {
      target: {
        files: event,
        name: "imageURL",
      },
    };
    await handleFileUpload(
      e,
      setisLoading,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const handleSubmit = () => {
    if (storeId === "") {
      toast.error("First select a store");
      return;
    }
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const handleCategoryChange = (e: any) => {
    formik.setValues({
      ...formik.values,
      categorySK: e.SK,
      categoryId: e.id,
      itemType: e.itemType,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //@ts-expect-error
    onDrop: handleFileInputChangeAndUpload, //@ts-expect-error
    accept: "image/*",
    onDragEnter: () => console.log("Drag Enter"),
    onDragLeave: () => console.log("Drag Leave"),
    onDropAccepted: (acceptedFiles) =>
      console.log("Files accepted:", acceptedFiles),
    onDropRejected: (fileRejections) =>
      console.log("Files rejected:", fileRejections),
  });

  return (
    <div>
      {isLoading && <ImageLoader />}
      <ToastContainer />
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />

      <form onSubmit={formik.handleSubmit}>
        <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
          <div className="heading-title mt-3 mt-md-0">
            <h4 className="theme_color fw-bolder">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.categories}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              {"Update A Sub-Category"}
            </h4>
          </div>
          <div className=" d-flex justify-content-end gap-2">
            <Link className="text-decoration-none" to={Routers?.categories}>
              <button className="btn btn-outline-danger" type="button">
                {"Cancel"}
              </button>
            </Link>
            <button
              className="btn btn-outline-success d-inline-flex align-items-center"
              disabled={isLoading || saveLoader}
              type="button"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="container">
          {dataLoader ? (
            <FormEffect />
          ) : (
            <div className="row">
              <div className="col-md-8">
                <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                  <h4 className="theme_color fw-bold align-self-center mb-3">
                    {"General"}
                  </h4>
                  <div className="row">
                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label`}
                      >
                        Parent Category<span className="text-danger">*</span>
                      </label>
                      <Select
                        cacheOptions={true}
                        onChange={handleCategoryChange}
                        onBlur={formik.handleBlur}
                        defaultOptions={parentItems}
                        data={parentItems}
                        value={parentItems.find(
                          (item: { SK: string }) =>
                            item?.SK === formik?.values.categorySK,
                        )}
                      />
                      {formik.touched.categorySK && formik.errors.categorySK ? (
                        <div className="text-danger">
                          {formik.errors.categorySK}
                        </div>
                      ) : null}
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label `}
                      >
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        maxLength={100}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                      ) : null}
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlInput1"
                        className={`form-label`}
                      >
                        Category Type<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        name="itemType"
                        maxLength={100}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.itemType === "AIL"
                            ? "Aisle"
                            : formik.values.itemType
                        }
                        readOnly
                      />
                      {formik.touched.itemType && formik.errors.itemType ? (
                        <div className="text-danger">
                          {formik.errors.itemType}
                        </div>
                      ) : null}
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Tags
                      </label>

                      <div className="row">
                        <div className="form-group col-12">
                          <TagsInput
                            name="tags"
                            onChange={(tags) =>
                              formik.handleChange({
                                target: { name: "tags", value: tags },
                              })
                            }
                            value={formik.values.tags}
                          />
                          <small className="form-text text-muted">
                            <i className="bi bi-info-circle"></i> Press Enter
                            after typing your tag to add it.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label ${formik.touched.description && formik.errors.description && "text-danger"}`}
                      >
                        Description
                      </label>
                      <ReactQuill
                        theme="snow"
                        onChange={(content, delta, source, editor) => {
                          formik.setFieldValue("description", editor.getHTML());
                        }}
                        value={formik.values.description}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6] }],
                            ["bold", "italic", "link"],
                            [{ list: "ordered" }],
                            ["blockquote"],
                          ],
                        }}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="text-danger">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    {"Category Banner"}
                  </h4>
                  <div
                    {...getRootProps({
                      className: `new_category_banner_inner text-center ${
                        isDragActive ? "active-drop-zone" : ""
                      }`,
                      style: {
                        border: isDragActive
                          ? "2px dashed #4caf50"
                          : "2px dashed #ccc",
                        padding: "20px",
                        cursor: "pointer",
                      },
                    })}
                  >
                    <input {...getInputProps()} style={{ display: "none" }} />
                    <div className="new_category_banner_content">
                      <div>
                        <i className="bi bi-arrow-up-circle upload_icon"></i>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-outline-success"
                        >
                          Add Image
                        </button>
                      </div>
                      <div>
                        <span className="upload_img_text">
                          {"Click to upload an image"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {formik.values.imageURL && (
                    <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                      <h4 className="theme_color fw-bold mb-3 align-self-center">
                        {"Preview Image"}
                      </h4>
                      <div className="new_category_banner_inner d-block">
                        <div className="new_category_banner_content h-100 p-2">
                          <div className="image-container-preview">
                            <img
                              src={formik.values.imageURL}
                              alt="img"
                              className="image-preview"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="border border-grey p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    STATUS
                  </h4>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="flexRadioDefault1"
                      value="disabled"
                      checked={!formik.values.status}
                      onChange={(event) => {
                        formik.setFieldValue("status", !formik.values.status);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Disabled
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="flexRadioDefault2"
                      value="enabled"
                      checked={formik.values.status}
                      onChange={(event) => {
                        formik.setFieldValue("status", !formik.values.status);
                      }}
                    />
                    <label
                      className="form-check-label theme_color"
                      htmlFor="flexRadioDefault2"
                    >
                      Enabled
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default UpdateSubCategories;
