// data.ts
export type SelectOption = {
  value: string;
  label: string;
  data: any;
};

export const transformDataToOptions = (data: any[]): SelectOption[] => {
  return data.map((item) => ({
    value: item.id,
    label: item.name,
    ...item,
  }));
};

export const List = [
  {
    id: "AIL",
    value: "AIL",
    label: "Aisle",
    data: "AIL",
  },
  {
    id: "Takeaway",
    value: "Takeaway",
    label: "Takeaway",
    data: "Takeaway",
  },
  {
    id: "Recipe",
    value: "Recipe",
    label: "Recipe",
    data: "Recipe",
  },
];
