import Chef from "./Chefs";
import Shopper from "./Shopper";

const InStore = () => {
  return (
    <div>
      <Shopper />
      <Chef />
    </div>
  );
};

export default InStore;
