import { Link, useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { shopper_columns_name } from "./data";
import { Routers } from "../../Routes/Routes";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { InStoreResponse } from "./utils";
import { searchShopper } from "../../Api/Search/In-Store/Shopper";
import {} from "react-toastify";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import swal from "sweetalert";
import { disableUser } from "../../Api/User/mutations";

interface User {
  group: string;
}

type SearchData = {
  fields: string;
  value: string;
};

const Shopper = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shopper, setshopper] = useState<User[]>([]);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const { storeId } = useStoreId();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });
  let index = process.env.REACT_APP_STACK
    ? `shopper${process.env.REACT_APP_STACK}`
    : "shopper";
  // eslint-disable-next-line
  const getShopperlist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchShopper("user", filtervalue, Pagination, storeId)
        .then(async (res) => {
          const result = await InStoreResponse(res);
          setshopper(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching setchef:", error);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    getShopperlist(filtervalue, Pagination);
  }, [getShopperlist, filtervalue, Pagination]);

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  const navigate = useNavigate();
  const shopperEdit = (data: { PK: string; SK: string }) => {
    navigate(
      `${Routers.updateShopper
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))}`,
    );
  };

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Driver ${data?.username}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      try {
        const CreatePreSignUpUserInput = {
          PK: data?.PK,
          SK: data?.SK,
          username: data?.username,
          id: data?.id,
          isDeleted: `${data?.isDeleted === false ? true : false}`,
          storeId: storeId,
          entityName: index,
        };

        const res: any = await disableUser(
          CreatePreSignUpUserInput,
          data?.PK,
          data?.SK,
          data?.username,
          data?.isDeleted === false ? "DISABLE" : "ENABLE",
        );
        const result = JSON.parse(res?.data?.userCreateUpdateDisabled);
        const errorMessage = JSON.parse(result.body).message;

        if (result?.statusCode === 200) {
          swal(
            getStatus(data?.isDeleted),
            `Shopper has been ${getStatus(data?.isDeleted)} successfully`,
            "success",
          );
          getShopperlist(filtervalue, Pagination);
        } else {
          swal(errorMessage);
        }
      } catch (error) {
        swal(
          `Failed to ${getStatus(data?.isDeleted)} Shopper. Please try again later.`,
        );
      }
    }
  };
  return (
    <div>
      <div className="row my-4 justify-content-between">
        <div className="col-auto ">
          <h3 className="theme_color mb-0 fw-bolder font-weight-bold ">
            {"Shopper"}
          </h3>
        </div>
        <div className="col-auto ms-auto">
          <Link className="text-decoration-none" to={Routers?.shopersignup}>
            <button className="btn btn-outline-success " type="button">
              {"New Shopper"}
            </button>
          </Link>
        </div>
      </div>

      <div className="border p-4 mb-4  border-grey  rounded  white_bg">
        <Table
          columns={shopper_columns_name}
          data={shopper || []}
          paginationShow={true}
          isLoading={isLoading}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
          filterdata={filterdata}
          handleEdit={shopperEdit}
          handleDelete={handleDelete}
        ></Table>
      </div>
    </div>
  );
};

export default Shopper;
