import { generateClient } from "aws-amplify/api";
import { DOWNLOAD_INVOICE } from "./query";

const client = generateClient();

export const downloadInvoice = async (OrderInput: any) => {
  try {
    const response: any = await client.graphql({
      query: DOWNLOAD_INVOICE,
      variables: { input: OrderInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};
