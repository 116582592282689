import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import {
  formattedDate,
  generateUUID,
  handleFileUpload,
  isValidJSON,
} from "../../../Common Functions/Function";
import { Routers } from "../../../Routes/Routes";
import { useAlert } from "../../ContextAPI/ContextApi";
import validationSchema from "./validation";
import { useFormik } from "formik";
import ImageLoader from "../../../Effects/ImageLoader";
import { TagsInput } from "react-tag-input-component";
import { SubCategoryInput } from "../data";
import { categoryResponse } from "../utils";
import { addSubCategory } from "../../../Api/Sub-Category/mutation";
import { getAllCategory } from "../../../Api/Search/Category/Category";
import { getAllSubCategory } from "../../../Api/Search/Category/SubCategory";
import Select from "../../../Atoms/Select";
import { searchWithPKSK } from "../../../Api/Search/mutation";
import { useStoreId } from "../../ContextAPI/StoreIdContext";
import Toast from "../../Toast/Toast";
import LoadingOverlay from "../../../Effects/LoadingOverlay";
import { useDropzone } from "react-dropzone";

const NewSubCategories = () => {
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const { alert, message, errorType } = useAlert();
  const [parentItems, setParentItems] = useState<any>([]);
  const [subItems, setSubItems] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [saveLoader, setsaveLoader] = useState(false);
  const [previous] = useState(false);
  const [userData, setuserData] = useState<SubCategoryInput>();
  const [sortKey, setsortKey] = useState("");
  const { storeId } = useStoreId();
  let index = process.env.REACT_APP_STACK
    ? `subcategory${process.env.REACT_APP_STACK}`
    : "subcategory";
  const formik = useFormik({
    initialValues: {
      storeId: storeId,
      categoryId: "",
      categorySK: "",
      description: "",
      tags: [],
      storeType: "",
      name: "",
      imageURL: "",
      status: true,
      entityName: index,
      itemType: "",
      isDeleted: `${storeId}-false`,
      createdDate: formattedDate(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const UUID = generateUUID();
      let val = previous ? sortKey : `Active#${UUID}`;
      setsaveLoader(true);
      const newData = {
        PK: values?.categorySK,
        SK: val,
        id: UUID,
        ...values,
      };
      await addSubCategory(newData)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.createSubCategory);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.categories);
            setAlert(true);
            setErrorType("success");
            setMessage("Sub-Category Added Successfully");
            setsaveLoader(false);
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setsaveLoader(false);
            return;
          }
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const getCategorylist = useCallback(async () => {
    await getAllCategory("", "category", "", storeId)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setParentItems(result?.modifiedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storeId]);

  useEffect(() => {
    getCategorylist();
  }, [getCategorylist]);

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const e: any = {
      target: {
        files: event,
        name: "imageURL",
      },
    };
    await handleFileUpload(
      e,
      setisLoading,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const getChildCategory = useCallback(async () => {
    await getAllSubCategory("", "subcategory", "", storeId)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setSubItems(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching child categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getChildCategory();
  }, [getChildCategory]);

  const getCategoryById = useCallback(async (data: any) => {
    const selectedItem = isValidJSON(data) ? JSON.parse(data) : data;
    setsortKey(selectedItem?.SK);
    await searchWithPKSK("subcategory", selectedItem?.PK, selectedItem?.SK)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setuserData(result?.modifiedData[0]);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    if (userData) {
      formik.setValues({
        ...formik.values,
        name: userData.name || "",
        description: userData.description || "",
        imageURL: userData.imageURL || "",
        status: JSON.parse(userData?.status),
        tags: userData.tags || [],
        categorySK: userData?.categorySK || "",
        itemType: userData?.itemType || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const handleCategory = (data: any) => {
    let val = data;
    formik.setFieldValue("categorySK", val.SK);
    formik.setFieldValue("categoryId", val.id);
    formik.setFieldValue("itemType", val.itemType);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //@ts-expect-error
    onDrop: handleFileInputChangeAndUpload, //@ts-expect-error
    accept: "image/*",
    onDragEnter: () => console.log("Drag Enter"),
    onDragLeave: () => console.log("Drag Leave"),
    onDropAccepted: (acceptedFiles) =>
      console.log("Files accepted:", acceptedFiles),
    onDropRejected: (fileRejections) =>
      console.log("Files rejected:", fileRejections),
  });

  return (
    <div>
      {isLoading && <ImageLoader />}
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />
      <form onSubmit={formik.handleSubmit}>
        <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
          <div className="heading-title mt-3 mt-md-0">
            <h4 className="theme_color fw-bolder">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.categories}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              {"Create A New Sub Category"}
            </h4>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Link className="text-decoration-none" to={Routers.categories}>
              <button className="btn btn-outline-danger" type="button">
                {"Cancel"}
              </button>
            </Link>
            <button
              className={`btn btn-outline-success d-inline-flex align-items-center ${
                isLoading ? "disabled" : ""
              }`}
              onClick={handleSubmit}
              type="button"
              disabled={isLoading || saveLoader}
            >
              Save
            </button>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                <h4 className="theme_color fw-bold align-self-center mb-3">
                  {"General"}
                </h4>
                <div className="row">
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label`}
                    >
                      Parent Category<span className="text-danger">*</span>
                    </label>
                    <Select
                      cacheOptions={true}
                      onChange={handleCategory}
                      onBlur={formik.handleBlur}
                      defaultOptions={parentItems}
                      data={parentItems}
                    />
                    {formik.touched.categorySK && formik.errors.categorySK ? (
                      <div className="text-danger">
                        {formik.errors.categorySK}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group ">
                    {/* <div className="form-check d-flex gap-2 justify-content-end">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheckbox"
                        onChange={(e) => setPrevious(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox"
                      >
                        Add Existing Category
                      </label>
                    </div> */}
                    {previous && (
                      <div className="mb-3 col-md-12">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className={`form-label`}
                        >
                          Existing Category
                        </label>
                        <Select
                          cacheOptions={true}
                          onChange={(e) => {
                            getCategoryById(e);
                          }}
                          defaultOptions={subItems}
                          data={subItems}
                        />
                      </div>
                    )}
                  </div>

                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label`}
                    >
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      maxLength={100}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                  </div>

                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label`}
                    >
                      Category Type<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      name="itemType"
                      maxLength={100}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.itemType === "AIL"
                          ? "Aisle"
                          : formik.values.itemType
                      }
                      readOnly
                    />
                    {formik.touched.itemType && formik.errors.itemType ? (
                      <div className="text-danger">
                        {formik.errors.itemType}
                      </div>
                    ) : null}
                  </div>

                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label`}
                    >
                      Tags
                    </label>

                    <div className="row">
                      <div className="form-group col-12">
                        <TagsInput
                          name="tags"
                          onChange={(tags) =>
                            formik.handleChange({
                              target: { name: "tags", value: tags },
                            })
                          }
                          value={formik.values.tags}
                        />
                        <small className="form-text text-muted">
                          <i className="bi bi-info-circle"></i> Press Enter
                          after typing your tag to add it.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label ${formik.touched.description && formik.errors.description && "text-danger"}`}
                    >
                      Description
                    </label>
                    <ReactQuill
                      theme="snow"
                      onChange={(content, delta, source, editor) => {
                        formik.setFieldValue("description", editor.getHTML());
                      }}
                      value={formik.values.description}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6] }],
                          ["bold", "italic", "link"],
                          [{ list: "ordered" }],
                          ["blockquote"],
                        ],
                      }}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-danger">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="border border-grey p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  {"Category Banner"}
                </h4>
                <div
                  {...getRootProps({
                    className: `new_category_banner_inner text-center ${
                      isDragActive ? "active-drop-zone" : ""
                    }`,
                    style: {
                      border: isDragActive
                        ? "2px dashed #4caf50"
                        : "2px dashed #ccc",
                      padding: "20px",
                      cursor: "pointer",
                    },
                  })}
                >
                  <input {...getInputProps()} />{" "}
                  <div className="new_category_banner_content">
                    <div>
                      <i className="bi bi-arrow-up-circle upload_icon"></i>
                    </div>
                    <div>
                      <button type="button" className="btn btn-outline-success">
                        Add Image
                      </button>
                    </div>
                    <div>
                      <span className="upload_img_text">
                        {"Click to upload an image"}
                      </span>
                    </div>
                  </div>
                </div>
                {formik.values.imageURL && (
                  <div className="border border-grey p-3 rounded mt-4 white_bg">
                    <h4 className="theme_color fw-bold mb-3 align-self-center">
                      {"Preview Image"}
                    </h4>
                    <div className="new_category_banner_inner d-block">
                      <div className="new_category_banner_content h-100 p-2">
                        <div className="image-container-preview">
                          <img
                            src={formik.values.imageURL}
                            alt="img"
                            className="image-preview"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="border border-grey p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  STATUS
                </h4>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="flexRadioDefault1"
                    value="disabled"
                    checked={!formik.values.status}
                    onChange={(event) => {
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Disabled
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="flexRadioDefault2"
                    value="enabled"
                    checked={formik.values.status}
                    onChange={(event) => {
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                  />
                  <label
                    className="form-check-label theme_color"
                    htmlFor="flexRadioDefault2"
                  >
                    Enabled
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewSubCategories;
