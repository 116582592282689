import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { formconfig } from "./data";
import Form from "../../Blocks/Form/Form";
import { useRef, useState } from "react";
import { addStore } from "../../Api/Store/mutation";
import { useAlert } from "../ContextAPI/ContextApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formattedDate, generateUUID } from "../../Common Functions/Function";
import Toast from "../Toast/Toast";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import LoadingOverlay from "../../Effects/LoadingOverlay";

const NewStore = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { alert, message } = useAlert();
  const { updateRefetchStoreList } = useStoreId();
  let index = process.env.REACT_APP_STACK
    ? `store${process.env.REACT_APP_STACK}`
    : "store";

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();

  interface FormData {
    [key: string]: any;
  }
  const UUID = generateUUID();
  const inputfield: { [key: string]: string | boolean } = {
    PK: "",
    SK: "",
    name: "",
    storeCode: UUID,
    rewardAmount: "",
    rewardRate: "",
    description: "",
    location: "",
    lat: "",
    long: "",
    storeManager: "",
    itemStatus: true,
    deliveryCost: "50",
    storeOrderNumberPrefix: "S1-20241234",
    storeMaxCartItemsAllowed: "20",
    storeMaxDriverTipAmount: "9999",
    storeMaxProductQuantity: "20",
    storeMinCartItemsAllowed: "0",
    storeMinDriverTipAmount: "0",
    storeMinProductQuantity: "0",
    storeYearBasedOrderFormat: "",
    storeMealBasedOrderFormat: "M1-20241234",
    storeStoreBasedOrderFormat: "S1-20241234",
    storeBothOrderFormat: "W1-20241234",
  };

  const handleSubmit = async (formData: FormData) => {
    setIsLoading(true);
    const newData: FormData = {
      PK: `store`,
      SK: `active#${UUID}`,
      storeCode: UUID,
      id: UUID,
      entityName: index,
      createdDate: formattedDate(),
      isDeleted: `false`,
      storePolygon: JSON.stringify([
        { lat: formData?.lat, lng: formData?.long },
      ]),
    };

    const mergeData = {
      ...formData,
      ...newData,
    };

    console.log(mergeData);
    try {
      await addStore(mergeData)
        .then((res) => {
          const result = JSON.parse(res?.data?.createStore);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.store);
            setAlert(true);
            setErrorType("success");
            setMessage("Store Added Successfully");
            updateRefetchStoreList(false);

            return;
          } else {
            setErrorType("error");
            setAlert(true);
            setMessage(errorMessage);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
        });
    } catch (error) {
      const { message } = error as Error;
      setIsLoading(false);
      toast.error(message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {alert && message && <Toast type="error" message={message} />}
      <LoadingOverlay isVisible={isLoading} message="Please wait..." />

      <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title">
          <h4 className="theme_color fw-bolder">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.store}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            {"Create New Store"}
          </h4>
        </div>
        <div className=" d-flex justify-content-end gap-2">
          <Link className="text-decoration-none" to={Routers?.store}>
            <button className="btn btn-outline-danger" type="button">
              {"Cancel"}
            </button>
          </Link>
          <button
            className="btn btn-outline-success "
            type="button"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save
          </button>
        </div>
      </div>

      <Form
        formconfig={formconfig}
        onSubmit={handleSubmit}
        ref={formRef}
        inputfield={inputfield}
      />
    </div>
  );
};

export default NewStore;
