import axios from "axios";
import { getAuthToken } from "../utils";

interface SearchData {
  fields: string;
  value: string | { from: string; to: string };
}

interface Pagination {
  from: number;
  size: number;
}

interface Pagination {
  from: number;
  size: number;
}

export const searchProduct = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
  productType?: string,
  itemImported?: any,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }

  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object") {
        console.log(item);
        return {
          range: {
            [`extraFieldsProduct.${item.fields}`]: {
              gte: item.value.from !== "" ? parseFloat(item.value.from) : 0,
              lte:
                item.value.to !== ""
                  ? parseFloat(item.value.to)
                  : Number.MAX_VALUE,
            },
          },
        };
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value.trim()}*`,
          },
        };
      }
    });

  type FilterClause =
    | { match: { isDeleted: string } }
    | {
        match: {
          storeId?: string;
          productType?: string;
          itemImported?: string;
        };
      }
    | { match_all: {} };

  const filters: FilterClause[] = [];

  filters.push({ match: { storeId: storeId } as any });

  // if (productType !== "All") {
  //   filters.push({ match: { productType } });
  // }

  if (itemImported !== "All") {
    filters.push({ match: { itemImported: JSON.parse(itemImported) } });
  }
  const queryBody: any = {
    from: pagination.from,
    size: pagination.size,
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [
          ...filters,
          ...(productType === "NA"
            ? [
                {
                  term: {
                    "productType.keyword": productType,
                  },
                },
              ]
            : [
                {
                  terms: {
                    "productType.keyword": [productType, "BOTH"],
                  },
                },
              ]),
        ],
      },
    },
  };

  // if (data?.[0]?.fields === "salesPrice") {
  //   queryBody.sort = [
  //     {
  //       [`extraFieldsProduct.${data[0].fields}.keyword`]: {
  //         order: "asc",
  //       },
  //     },
  //   ];
  // } else {
  queryBody.sort = [
    {
      updatedDate: {
        order: "desc",
      },
    },
  ];

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    console.log(response.data?.hits);
    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const searchExtraTakeAwayProduct = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
  productType?: string,
  itemImported?: any,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }

  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object") {
        return {
          range: {
            [`extraFieldsProduct.${item.fields}`]: {
              gte: item.value.from !== "" ? parseFloat(item.value.from) : 0,
              lte:
                item.value.to !== ""
                  ? parseFloat(item.value.to)
                  : Number.MAX_VALUE,
            },
          },
        };
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value.trim()}*`,
          },
        };
      }
    });

  type FilterClause =
    | { match: { isDeleted: string } }
    | {
        match: {
          storeId?: string;
          productType?: string;
          itemImported?: string;
        };
      }
    | { match_all: {} };

  const filters: FilterClause[] = [];

  filters.push({ match: { storeId: storeId } as any });

  // if (productType !== "All") {
  //   filters.push({ match: { productType } });
  // }

  // if (itemImported !== "All") {
  //   filters.push({ match: { itemImported: JSON.parse(itemImported) } });
  // }
  const queryBody: any = {
    from: pagination.from,
    size: pagination.size,
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [
          ...filters, // spread existing filters
          { match: { productType: "EXTRATAKEAWAY" } }, // add match filter for productType
        ],
      },
    },
  };

  if (data?.[0]?.fields === "salesPrice") {
    queryBody.sort = [
      {
        [`extraFieldsProduct.${data[0].fields}.keyword`]: {
          order: "asc",
        },
      },
    ];
  } else {
    queryBody.sort = [
      {
        updatedDate: {
          order: "desc",
        },
      },
    ];
  }

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getAllProducts = async (
  query: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  let val = { term: { storeId: storeId } };

  const queryBody = {
    size: 10000,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        filter: [{ match: { isDeleted: "false" } }, val],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getAllProductsByProductType = async (
  query: string,
  indexName: string,
  storeId: string | null,
  productType: string,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const queryBody = {
    size: 10000,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        filter: [
          { match: { isDeleted: "false" } },
          { match: { itemStatus: "true" } },
          { term: { storeId: storeId } },
          productType
            ? { match: { productType: productType } }
            : { match_all: {} },
        ],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getProductByCode = async (
  productCode: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  let val = { term: { storeId: storeId } };

  const queryBody = {
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    size: 1,
    query: {
      bool: {
        filter: [
          { match: { isDeleted: false } },
          { match: { productCode: productCode } },
          val,
        ],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return [response.data?.hits?.hits[0]?._source];
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getAllProductsWithAvailableQuantity = async (
  storeId: string | null,
  productType: string,
) => {
  const productIndex = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product-dev";

  const productStockIndex = process.env.REACT_APP_STACK
    ? `productstock${process.env.REACT_APP_STACK}`
    : "productstock-dev";

  const baseUrl = process.env.REACT_APP_OPEN_SEARCH_BASE_URL;

  const headers = {
    "Content-Type": "application/json",
    Authorization: await getAuthToken(),
  };

  const storeFilter = storeId ? { term: { storeId: storeId } } : {};

  const queryBody = {
    size: 10000,

    query: {
      bool: {
        filter: [
          { match: { isDeleted: "false" } },
          storeFilter,
          productType
            ? { match: { productType: productType } }
            : { match_all: {} },
        ],
      },
    },
  };

  const queryBody2 = {
    size: 10000,

    query: {
      bool: {
        filter: [storeFilter],
      },
    },
  };

  try {
    const [productResponse, productStockResponse] = await Promise.all([
      axios.post(`${baseUrl}/${productIndex}/_search`, queryBody, { headers }),
      axios.post(`${baseUrl}/${productStockIndex}/_search`, queryBody2, {
        headers,
      }),
    ]);

    const productData =
      productResponse.data?.hits?.hits.map(
        (hit: { _source: any }) => hit._source,
      ) || [];
    const productStockData =
      productStockResponse.data?.hits?.hits.map(
        (hit: { _source: any }) => hit._source,
      ) || [];

    const stockMap = productStockData.reduce(
      (
        acc: { [x: string]: any },
        stock: { sku: string | number; availableQuantity: any },
      ) => {
        acc[stock.sku] = stock.availableQuantity;
        return acc;
      },
      {},
    );

    const combinedData = productData.map(
      (product: { sku: string | number }) => {
        const availableQuantity = stockMap[product.sku] || 0;
        return {
          ...product,
          availableQuantity,
        };
      },
    );

    return combinedData;
  } catch (error) {
    console.error("Error fetching product and stock data:", error);
    throw error;
  }
};
