import axios from "axios";
import { getAuthToken } from "../utils";

interface SearchData {
  fields: string;
  value: string | { from: string; to: string };
}

interface Pagination {
  from: number;
  size: number;
}

interface Pagination {
  from: number;
  size: number;
}
export const searchShopper = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object") {
        const rangeQuery: any = {};
        if (item.value.from !== "") {
          rangeQuery["gte"] = item.value.from.trim();
        }
        if (item.value.to !== "") {
          rangeQuery["lte"] = item.value.to.trim();
        }
        return {
          range: {
            [item.fields]: rangeQuery,
          },
        };
      } else if (item.fields === "createdDate") {
        return {
          query_string: {
            fields: [item.fields],
            query: `${item.value}`,
          },
        };
      } else if (item.fields === "email") {
        if (item?.value?.includes("@")) {
          return {
            match: {
              [item.fields]: {
                query: item.value,
                operator: "and",
              },
            },
          };
        } else {
          return {
            wildcard: {
              [item.fields]: `*${item.value.trim()}*`,
            },
          };
        }
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value.trim()}*`,
          },
        };
      }
    });
  let val = { term: { storeId: storeId } };
  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [
          {
            match: { group: "shopper" },
          },
          val,
        ],
      },
    },
  };
  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};
