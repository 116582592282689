import { Link } from "react-router-dom";
import { ReactComponent as Vector } from "../../../../Asset/Vector.svg";
import { Routers } from "../../../../Routes/Routes";
import { Categories } from "./data";
import { useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface MenuCategoriesProps {
  handler: (category: string) => void;
}

const MenuNewCategories: React.FC<MenuCategoriesProps> = ({ handler }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [formValues, setFormValues] = useState<any>({
    name: "",
    parent_category: "",
    description: "",
    url_key: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    imageURL: "",
    status: true,
    type: "parent",
  });

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const generateUUID = () => {
    return "xxxxxx".replace(/x/g, function () {
      return ((Math.random() * 16) | 0).toString(16);
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;
    const UUID = generateUUID();
    let inputValue: string | boolean = value;

    if (type === "radio") {
      inputValue = value === "enabled" ? true : false;
    } else if (type === "checkbox") {
      inputValue = (event.target as HTMLInputElement).checked;
    }

    const newData = {
      pk: `CAT#${UUID}`,
      sk: `${formValues?.status === true ? "Active" : "Deactive"}#${UUID}`,
      product_code: UUID,
      id: UUID,
    };

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: inputValue,
      PK: newData.pk,
      SK: newData.sk,
      id: newData.id,
    }));
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      setFormValues((prevValues: any) => ({ ...prevValues, [name]: files[0] }));
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <ToastContainer />

      <form onSubmit={handleSubmit}>
        <div className="menu_new_categories_wraper">
          <div className="row justify-content-between mb-4">
            <div className="col-auto d-flex align-items-center">
              <Link
                to={Routers?.menumanager}
                onClick={() => handler(Categories.MENU_CATEGORIES)}
                className="text-decoration-none"
              >
                <Vector />
              </Link>
            </div>
            <div className="col-auto d-flex gap-2 justify-content-end">
              <button
                className="btn btn-outline-danger"
                onClick={() => handler(Categories.MENU_CATEGORIES)}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-outline-info" type="button">
                Duplicate
              </button>
              <button className="btn btn-outline-success" type="submit">
                Save
              </button>
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="col-auto">
              <div className="add_note d-flex ">
                <i className="bi bi-file-earmark-spreadsheet me-2"></i>
                <p>Add Note</p>
              </div>
            </div>
            <div className="col-auto">Breads</div>
          </div>

          <div className="border-bottom mb-4"></div>

          <h6 className="theme_color align-self-center h4 fw-bold mb-3">
            {"Categories"}
          </h6>

          <div className="row">
            <div className="col-md-8">
              <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                <h4 className="theme_color fw-bold align-self-center mb-3">
                  {"General"}
                </h4>
                <div className="row">
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={`form-group col-12 mb-3`}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={4}
                        onChange={handleInputChange}
                        name="description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  {"Category Banner"}
                </h4>
                <div className="new_category_banner_inner">
                  <div className="new_category_banner_content">
                    <div>
                      <i className="bi bi-arrow-up-circle upload_icon"></i>
                    </div>
                    <div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        name="imageURL"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={handleButtonClick}
                      >
                        Add Image
                      </button>
                    </div>
                    <div>
                      <span className="upload_img_text">
                        {"Click to upload an image"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border border-grey p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  STATUS
                </h4>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="flexRadioDefault1"
                    value="disabled"
                    checked={formValues.status === false}
                    onChange={handleInputChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Disabled
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="flexRadioDefault2"
                    value="enabled"
                    checked={formValues.status === true}
                    onChange={handleInputChange}
                  />
                  <label
                    className="form-check-label theme_color"
                    htmlFor="flexRadioDefault2"
                  >
                    Enabled
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="mb-4">Menus for Category to Appear in</div>
              <div className="form-check theme_color">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Menu
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MenuNewCategories;
