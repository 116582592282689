const ProductResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.productCode,
      value: val._source.productCode,
      label: val._source.itemName,
      salesPrice: val?._source?.extraFieldsProduct?.salesPrice,
      costPrice: val?._source?.extraFieldsProduct?.costPrice,
      ...val._source,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { ProductResponse };

const ProductResponseMATCHSKU = (data: any) => {
  const modifiedData = data?.map((val: any) => {
    return {
      id: val.productCode,
      value: val.productCode,
      label: val.itemName,
      ...val,
      ...val.extraFieldsProduct,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { ProductResponseMATCHSKU };

export const List = [
  {
    id: "MEAL",
    value: "MEAL",
    label: "MEAL",
    data: "MEAL",
  },
  {
    id: "STORE",
    value: "STORE",
    label: "STORE",
    data: "STORE",
  },
  {
    id: "EXTRATAKEAWAY",
    value: "EXTRATAKEAWAY",
    label: "MODIFIERS",
    data: "EXTRATAKEAWAY",
  },
  {
    id: "BOTH",
    value: "BOTH",
    label: "BOTH (MEAL, STORE)",
    data: "BOTH",
  },
];

export const ListCheckBox = [
  {
    id: "checkbox",
    value: "checkbox",
    label: "Checkbox",
    data: "checkbox",
  },
  {
    id: "radio",
    value: "radio",
    label: "Radio",
    data: "radio",
  },
];

export const selectedKeys = [
  "productBrand",
  "productSize",
  "costPrice",
  "salesPrice",
  "packWeightGrams",
  "packSizeInmm",
  "localInternational",
  "halaalKosher",
  "vegetarianVegan",
  "onlinePicture",
  "glutenFree",
  "alcoholic",
  "imported",
  "packSizeBreadth",
  "packSizeHeight",
  "packSizeLength",
  "halaal",
  "kosher",
  "vegetarian",
  "vegan",
  "sugarFree",
];
