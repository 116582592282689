import { isValidJSON } from "../../Common Functions/Function";

const StoreResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.productCode,
      ...val._source,
      itemStatus: isValidJSON(val._source.itemStatus)
        ? JSON.parse(val._source.itemStatus)
        : val._source.itemStatus,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { StoreResponse };
