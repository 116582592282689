import React, { useState, useEffect } from "react";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { updateStore } from "../../Api/Store/mutation";
import { useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import { isValidJSON } from "../../Common Functions/Function";
import FormEffect from "../../Effects/FormEffect";

interface LatLng {
  lat: number;
  lng: number;
}

const PolygonDraw: React.FC = () => {
  const [updateLoader, setUpdateLoader] = useState(false);
  const { alert, message, errorType, setAlert, setMessage, setErrorType } =
    useAlert();
  const [selectedStore, setSelectedStore] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true); // New state for loader
  const { storeId, storeList, latitude, longitude } = useStoreId();
  const navigate = useNavigate();
  const [polygonCoords, setPolygonCoords] = useState<LatLng[]>([]);
  const [currentLocation] = useState<{
    lat: any;
    lng: any;
  }>({
    lat: latitude,
    lng: longitude,
  });

  useEffect(() => {
    // Show loader for 5 seconds before rendering
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 5000);

    return () => clearTimeout(loaderTimeout); // Cleanup timeout
  }, []);

  useEffect(() => {
    if (storeList && storeId) {
      const matchedStore: any = storeList.find(
        (store: any) => store.id === storeId,
      );
      if (matchedStore) {
        setSelectedStore(matchedStore);

        let parsedPolygon: LatLng[] = [];
        const { storePolygon } = matchedStore;
        if (storePolygon) {
          if (isValidJSON(storePolygon)) {
            parsedPolygon = JSON.parse(storePolygon);
          } else if (Array.isArray(storePolygon)) {
            parsedPolygon = storePolygon;
          } else {
            parsedPolygon = [storePolygon];
          }
        }

        setPolygonCoords(parsedPolygon.length ? parsedPolygon : polygonCoords);
        setIsLoading(false);
      }
    } // eslint-disable-next-line
  }, [storeList, storeId]);

  const mapContainerStyle = {
    height: "calc(100vh - 180px)",
    width: "100%",
  };

  const zoomLevel = 12;

  const polygonOptions = {
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: true,
    geodesic: false,
    zIndex: 1,
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newCoords: LatLng = {
        lng: event.latLng.lng(),
        lat: event.latLng.lat(),
      };
      setPolygonCoords((prevCoords) => [...prevCoords, newCoords]);
    }
  };

  const handlePolygonRemove = () => {
    setPolygonCoords([]);
  };

  const handlePolygonSave = async () => {
    setUpdateLoader(true);
    try {
      const updatedStore = {
        ...selectedStore,
        storePolygon: JSON.stringify(polygonCoords),
      };
      const res = await updateStore(
        updatedStore,
        updatedStore?.PK,
        updatedStore?.SK,
      );
      const result = JSON.parse(res?.data?.updateStore);
      const errorMessage = JSON.parse(result.body).message;

      if (result?.statusCode === 200) {
        navigate(Routers?.polygon);
        setAlert(true);
        setErrorType("success");
        setMessage("Polygon updated successfully");
      } else {
        setErrorType("error");
        setAlert(true);
        setMessage(errorMessage);
      }
    } catch (err: any) {
      setErrorType("error");
      setAlert(true);
      setMessage(err?.errors?.[0]?.message || "An error occurred");
    } finally {
      setUpdateLoader(false);
    }
  };

  return (
    <div>
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <LoadingOverlay isVisible={updateLoader} message="Please wait..." />

      {showLoader ? (
        // Bootstrap loader
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {storeId && (
            <div className="d-flex gap-2 m-2 justify-content-end">
              <button
                className="btn btn-outline-danger"
                onClick={handlePolygonRemove}
              >
                Clear Polygon
              </button>
              <button
                className="btn btn-outline-success"
                disabled={updateLoader}
                onClick={handlePolygonSave}
              >
                Save Polygon
              </button>
            </div>
          )}

          {currentLocation && storeId ? (
            isLoading ? (
              <FormEffect />
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={currentLocation}
                zoom={zoomLevel}
                onClick={handleMapClick}
              >
                {polygonCoords.length > 0 && (
                  <Polygon paths={polygonCoords} options={polygonOptions} />
                )}
              </GoogleMap>
            )
          ) : (
            <b>First Select a store</b>
          )}
        </>
      )}
    </div>
  );
};

export default PolygonDraw;
