const columns_bestsellers = [
  {
    field: "product",
    label: "",
    type: "text",
  },
  {
    field: "price",
    label: "Price",
    type: "text",
  },

  {
    field: "sold",
    label: "Sold",
    type: "text",
  },
  {
    field: "profit",
    label: "Profit",
    type: "text",
  },
];

const data_bestsellers = [
  {
    id: "1",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "2",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "3",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "4",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "5",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
];

const columns_trending = [
  {
    field: "imageURL",
    label: "",
    type: "img",
  },
  {
    field: "itemName",
    label: "",
    type: "text",
  },
  {
    field: "sellingPriceInclPrice2",
    label: "",
    type: "text",
  },
];

const data_trending = [
  {
    id: "1",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "2",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "3",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "4",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "5",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
];

const getVisitor = (data: { date: string; count: number }[]) => {
  const categories = data.map((item) => item.date);
  const counts = data.map((item) => item.count);

  const sales_statics = {
    chart: {
      type: "column",
    },
    title: {
      text: "", // Empty title, adjust if needed
    },
    xAxis: {
      categories: categories,
      crosshair: false,
      accessibility: {
        description: "Dates", // Adjust description as needed
      },
    },
    yAxis: {
      gridLineColor: "transparent",
      min: 0,
      title: {
        text: ".", // Empty axis title, adjust if needed
      },
    },
    tooltip: {
      valueSuffix: "", // Adjust based on your data (e.g., units)
    },
    plotOptions: {
      column: {
        pointPadding: 0.4,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false, // Disable credits
    },
    series: [
      {
        name: "Visitor Count", // Adjust the series name if needed
        data: counts,
        color: "#54634B", // Column color
        showInLegend: false, // Hide legend
      },
    ],
  };

  return sales_statics;
};

export { getVisitor };

const linegraph = {
  chart: {
    renderTo: "container",
    type: "spline",
  },

  title: {
    text: "",
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  legend: {
    symbolWidth: 80,
  },
  tooltip: {
    crosshairs: false,
    shared: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      data: [2, 14, 1, 13, 4, 12],
      showInLegend: false,
      gridLineColor: "transparent",
      color: "#983939",
    },
  ],
};

const getLineGraphOptions = (data: any, color = "#983939") => {
  return {
    chart: {
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      symbolWidth: 80,
    },
    tooltip: {
      crosshairs: false,
      shared: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: data,
        showInLegend: false,
        gridLineColor: "transparent",
        color: color,
      },
    ],
  };
};

const getLineGraphOptionsSales = (category: any, data: any) => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: category,
      crosshair: false,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      gridLineColor: "transparent",

      min: 0,
      title: {
        text: ".",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.4,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: data,
        color: "#54634B",
        showInLegend: false,
      },
    ],
  };
};

const productStockDetails = [
  {
    field: "imageURL",
    label: "Image",
    type: "img",
  },
  {
    field: "itemName",
    label: "Product Name",
    type: "text",
  },
  {
    field: "sku",
    label: "SKU",
    type: "text",
  },

  {
    field: "salesPrice",
    label: "Price",
    type: "text",
  },
  {
    field: "sellQuantity",
    label: "Sell QTY",
    type: "text",
  },
  {
    field: "netProfit",
    label: "Profit",
    type: "text",
  },
];

export {
  columns_bestsellers,
  data_bestsellers,
  columns_trending,
  data_trending,
  linegraph,
  getLineGraphOptions,
  getLineGraphOptionsSales,
  productStockDetails,
};
