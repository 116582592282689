import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useAlert } from "../../ContextAPI/ContextApi";
import { addCategory } from "../../../Api/Category/mutations";
import {
  formattedDate,
  generateUUID,
  handleFileUpload,
} from "../../../Common Functions/Function";
import validationSchema from "./Validation";
import ReactQuill from "react-quill";
import ImageLoader from "../../../Effects/ImageLoader";
import { TagsInput } from "react-tag-input-component";
import { Routers } from "../../../Routes/Routes";
import { useStoreId } from "../../ContextAPI/StoreIdContext";
import Select from "../../../Atoms/Select";
import Toast from "../../Toast/Toast";
import LoadingOverlay from "../../../Effects/LoadingOverlay";
import { useDropzone } from "react-dropzone";

const NewCategory = () => {
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const [isLoading, setisLoading] = useState(false);
  const [saveLoader, setsaveLoader] = useState(false);
  const { storeId, storeType } = useStoreId();
  const { alert, message, errorType } = useAlert();

  let index = process.env.REACT_APP_STACK
    ? `category${process.env.REACT_APP_STACK}`
    : "category";
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      imageURL: "",
      status: true,
      tags: [],
      storeId: storeId,
      storeType: storeType,
      entityName: index,
      itemType: "",
      isDeleted: `${storeId}-false`,
      createdDate: formattedDate(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setsaveLoader(true);
      setSubmitting(true);
      const UUID = generateUUID();
      const newData = {
        PK: `Category`,
        SK: `Active#${UUID}`,
        id: UUID,
        ...values,
      };
      await addCategory(newData)
        .then((response) => {
          const result = JSON.parse(response?.data?.createCategory);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.categories);
            setAlert(true);
            setErrorType("success");
            setMessage("Category Added Successfully");
            setsaveLoader(false);
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setsaveLoader(false);
          }
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const e: any = {
      target: {
        files: event,
        name: "imageURL",
      },
    };
    await handleFileUpload(
      e,
      setisLoading,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const handleCategory = (data: any) => {
    let val = data;
    formik.setFieldValue("itemType", val.id);
  };

  const List = [
    {
      id: "AIL",
      value: "AIL",
      label: "Aisle",
      data: "AIL",
    },
    {
      id: "Takeaway",
      value: "Takeaway",
      label: "Takeaway",
      data: "Takeaway",
    },
    {
      id: "Recipe",
      value: "Recipe",
      label: "Recipe",
      data: "Recipe",
    },
  ];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //@ts-expect-error
    onDrop: handleFileInputChangeAndUpload, //@ts-expect-error
    accept: "image/*",
    onDragEnter: () => console.log("Drag Enter"),
    onDragLeave: () => console.log("Drag Leave"),
    onDropAccepted: (acceptedFiles) =>
      console.log("Files accepted:", acceptedFiles),
    onDropRejected: (fileRejections) =>
      console.log("Files rejected:", fileRejections),
  });

  return (
    <>
      {isLoading && <ImageLoader />}
      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="menu_new_categories_wraper">
          <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
            <h6 className="theme_color align-self-center h4 fw-bold mb-3">
              {"Create New Category"}
            </h6>
            <div className="row justify-content-between mb-4">
              <div className="col-auto d-flex align-items-center">
                {/* <Vector /> */}
              </div>
              <div className="col-auto d-flex gap-2 justify-content-end">
                <Link className="text-decoration-none" to={Routers.categories}>
                  <button className="btn btn-outline-danger" type="button">
                    Cancel
                  </button>
                </Link>
                <button
                  className="btn btn-outline-success"
                  type="button"
                  disabled={isLoading || saveLoader}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                <h4 className="theme_color fw-bold align-self-center mb-3">
                  {"General"}
                </h4>
                <div className="row">
                  <div className={`form-group col-12 mb-3 `}>
                    <label htmlFor="name" className={`form-label `}>
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      maxLength={100}
                      required
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                  </div>

                  <div className={`form-group col-12 mb-3`}>
                    <div className="mb-3">
                      <label htmlFor="description" className={`form-label `}>
                        Category Type<span className="text-danger">*</span>
                      </label>
                      <Select
                        cacheOptions={true}
                        onChange={handleCategory}
                        defaultOptions={List}
                        data={List}
                      />
                      {formik.touched.itemType && formik.errors.itemType ? (
                        <div className="text-danger">
                          {formik.errors.itemType}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Tags
                    </label>

                    <div className="row">
                      <div className="form-group col-12">
                        <TagsInput
                          name="tags"
                          onChange={(tags) =>
                            formik.handleChange({
                              target: { name: "tags", value: tags },
                            })
                          }
                          value={formik.values.tags}
                        />
                        <small className="form-text text-muted">
                          <i className="bi bi-info-circle"></i> Press Enter
                          after typing your tag to add it.
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className={`form-group col-12 mb-3`}>
                    <div className="mb-3">
                      <label htmlFor="description" className={`form-label `}>
                        Description
                      </label>
                      <ReactQuill
                        theme="snow"
                        onChange={(content, delta, source, editor) => {
                          formik.setFieldValue("description", editor.getHTML());
                        }}
                        value={formik.values.description}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6] }],
                            ["bold", "italic", "link"],
                            [{ list: "ordered" }],
                            ["blockquote"],
                          ],
                        }}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="text-danger">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="border border-grey p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  {"Category Banner"}
                </h4>
                <div
                  {...getRootProps({
                    className: `new_category_banner_inner text-center ${
                      isDragActive ? "active-drop-zone" : ""
                    }`,
                    style: {
                      border: isDragActive
                        ? "2px dashed #4caf50"
                        : "2px dashed #ccc",
                      padding: "20px",
                      cursor: "pointer",
                      transition: "border-color 0.2s ease-in-out", // Smooth transition
                      position: "relative", // Ensure positioning is relative
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="new_category_banner_content">
                    <div>
                      <i
                        className="bi bi-arrow-up-circle upload_icon"
                        style={{ fontSize: "2rem", marginBottom: "10px" }}
                      ></i>
                    </div>
                    <div className="custom-file-uploader mb-3">
                      <button type="button" className="btn btn-outline-success">
                        Add Images
                      </button>
                    </div>
                    <div>
                      <span className="upload_img_text">
                        {isDragActive
                          ? "Drop the images here..."
                          : "Click to upload images"}
                      </span>
                    </div>
                  </div>
                </div>

                {formik.values.imageURL && (
                  <div className="border border-grey p-3 rounded mt-4 white_bg">
                    <h4 className="theme_color fw-bold mb-3 align-self-center">
                      {"Preview Image"}
                    </h4>
                    <div className="new_category_banner_inner d-block">
                      <div className="new_category_banner_content h-100 p-2">
                        <div className="image-container-preview">
                          <img
                            src={formik.values.imageURL}
                            alt="img"
                            className="image-preview"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="border border-grey p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  STATUS
                </h4>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="flexRadioDefault1"
                    value="disabled"
                    checked={!formik.values.status}
                    onChange={(event) => {
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Disabled
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="flexRadioDefault2"
                    value="enabled"
                    checked={formik.values.status}
                    onChange={(event) => {
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                  />
                  <label
                    className="form-check-label theme_color"
                    htmlFor="flexRadioDefault2"
                  >
                    Enabled
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewCategory;
