import Table from "../../../../Blocks/Table/Table";
import { Categories, columns_name, data } from "./data";
import { ReactComponent as Search } from "../../../../Asset/search.svg";
interface MenuCategoriesProps {
  handler: (category: string) => void;
}

const MenuCategories: React.FC<MenuCategoriesProps> = ({ handler }) => {
  return (
    <div className="menu_categories_wraper">
      <div className="row justify-content-between mb-4">
        <div className="col-auto ">
          <h3 className="theme_color fw-bolder ">{"Categories"}</h3>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => handler(Categories.MENU_NEW_CATEGORIES)}
            type="button"
          >
            {"New Category"}
          </button>
        </div>
      </div>
      <div className="filter_wrap">
        <Search />
        <input
          type="search"
          className="form-control theme_color"
          aria-label="Sizing example input "
          aria-describedby="inputGroup-sizing-sm"
          placeholder="Search"
        />
      </div>

      <Table columns={columns_name} data={data} paginationShow={true}></Table>
    </div>
  );
};

export default MenuCategories;
