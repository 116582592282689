import { createContext, useContext, useState, useMemo, ReactNode } from "react";

interface UserInofContextType {
  userInfo: string | null;
  updateUserInfo: (newUserInfo: string | null) => void;
}

const UserInfoContext = createContext<UserInofContextType>({
  userInfo: null,
  updateUserInfo: () => {},
});

export const useUserInfo = () => useContext(UserInfoContext);

interface UserInfoProviderProps {
  children: ReactNode;
}

export const UserInfoProvider = ({ children }: UserInfoProviderProps) => {
  const [userInfo, setUserInfo] = useState<string | null>(
    localStorage.getItem("user-info"),
  );

  const updateUserInfo = (newUserInfo: string | null) => {
    if (newUserInfo === null) {
      localStorage.removeItem("user-info");
    } else {
      localStorage.setItem("user-info", newUserInfo);
    }
    setUserInfo(newUserInfo);
  };

  const contextValue: UserInofContextType = useMemo(
    () => ({
      userInfo,
      updateUserInfo,
    }),
    [userInfo],
  );

  return (
    <UserInfoContext.Provider value={contextValue}>
      {children}
    </UserInfoContext.Provider>
  );
};
