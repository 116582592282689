import React, { useState } from "react";
import { ENUMitems } from "./data";
import Items from "./Items";
import NewItems from "./NewItems";

const MainItems = () => {
  const [selectedCategory, setSelectedCategory] = useState(ENUMitems.ITEMS);

  const handler = (category: React.SetStateAction<string>) => {
    setSelectedCategory(category);
  };

  const renderCategory = () => {
    switch (selectedCategory) {
      case ENUMitems.ITEMS:
        return <Items handler={handler} />;
      case ENUMitems.ITEMS_NEW:
        return <NewItems handler={handler} />;
      default:
        <Items handler={handler} />;
    }
  };

  return <>{renderCategory()}</>;
};

export default MainItems;
