const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "fullName",
    label: "Full Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Full Name",
    },
  },
  {
    field: "email",
    label: "Email",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Email",
    },
  },
  {
    field: "drivertype",
    label: "Assigned To",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Email",
    },
  },

  {
    field: "phone",
    label: "Contact",
    type: "text",
    sub_header: {
      inputtype: "input",
      size: "210px",
      placeholder: "Contact",
    },
  },
  {
    field: "isOnline",
    label: "isDuty",
    type: "icon",
  },
  {
    field: "createdDate",
    label: "Created At",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "Created At",
    },
  },
  {
    field: "Details",
    label: "Details",
    type: "Details",
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const data = [
  {
    id: "1",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "28 March, 2024",
    status: "true",
  },
  {
    id: "2",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "28 March, 2024",
    status: "true",
  },
  {
    id: "3",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "29 March, 2024",
    status: "true",
  },
  {
    id: "4",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "30 March, 2024",
    status: "true",
  },
  {
    id: "5",
    full_name: "Guy Hawkins",
    Cell: "curtis.weaver@example.com",
    created_at: "29 March, 2024",
    status: "true",
  },
];

export { columns_name, data };

interface OrderDetail {
  imageURL: string;
  productName: string;
  productCode: string;
  salesPrice: string;
  quantity: number;
}

interface Address {
  address: string;
  addressName: string;
  aptNumber: string;
  isDefault: boolean;
  latitude: number;
  longitude: number;
}

interface OrderData {
  IdUser: string;
  PK: string;
  SK: string;
  actualDelivery: string;
  address: Address[];
  chat: string; // Assuming this is a JSON string that might be parsed later
  createdDate: string;
  deliveryCharge: number;
  entityName: string;
  estimatedDelivery: string;
  id: string;
  isDeleted: string;
  isSplited: boolean;
  orderDetails: OrderDetail[];
  promocode: string;
  reforder: string;
  specialinStructions: string; // Assuming this is a JSON string that might be parsed later
  status: string;
  subtotal: number;
  tip: number;
  total: number;
  updatedDate: string;
}

export type { OrderDetail, OrderData };
