const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "fullName",
    label: "Full Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Full Name",
    },
  },
  {
    field: "email",
    label: "Email",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Email",
    },
  },

  // {
  //   field: "status",
  //   label: "Status",
  //   type: "icon",
  //   sub_header: {
  //     inputtype: "select",
  //   },
  // },
  {
    field: "createdDate",
    label: "Created At",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "Created At",
    },
  },
  {
    field: "Details",
    label: "Details",
    type: "Details",
  },
];

const data = [
  {
    id: "1",
    full_name: "Guy Hawkins",
    email: "curtis.weaver@example.com",
    created_at: "28 March, 2024",
    status: "true",
  },
  {
    id: "2",
    full_name: "Guy Hawkins",
    email: "curtis.weaver@example.com",
    created_at: "28 March, 2024",
    status: "true",
  },
  {
    id: "3",
    full_name: "Guy Hawkins",
    email: "curtis.weaver@example.com",
    created_at: "29 March, 2024",
    status: "true",
  },
  {
    id: "4",
    full_name: "Guy Hawkins",
    email: "curtis.weaver@example.com",
    created_at: "29 March, 2024",
    status: "true",
  },
  {
    id: "5",
    full_name: "Guy Hawkins",
    email: "curtis.weaver@example.com",
    created_at: "30 March, 2024",
    status: "true",
  },
];

const orders = [
  {
    id: 1,
    orderId: "#6575",
    created: "2 min Ago",
    total: "R 546",
    payment: "CC",
    status: "Completed",
    items: [
      {
        sku: "#5434",
        name: "Roast Chicken",
        price: "R 999.00",
        qty: "x1",
        discount: "5%",
        total: "R 999.00",
      },
    ],
    subtotal: {
      subtotal: "R 999.00",
      delivery: "R 5.50",
      discount: "R 999.00",
      total: "R 9999.00",
    },
  },
  {
    id: 2,
    orderId: "#6576",
    created: "2 min Ago",
    total: "R 546",
    payment: "CC",
    status: "Canceled",
    items: [
      {
        sku: "#5434",
        name: "Roast Chicken",
        price: "R 999.00",
        qty: "x1",
        discount: "5%",
        total: "R 999.00",
      },
    ],
    subtotal: {
      subtotal: "R 999.00",
      delivery: "R 5.50",
      discount: "R 999.00",
      total: "R 9999.00",
    },
  },

  {
    id: 3,
    orderId: "#6577",
    created: "2 min Ago",
    total: "R 546",
    payment: "CC",
    status: "Pending",
    items: [
      {
        sku: "#5434",
        name: "Roast Chicken",
        price: "R 999.00",
        qty: "x1",
        discount: "5%",
        total: "R 999.00",
      },
    ],
    subtotal: {
      subtotal: "R 999.00",
      delivery: "R 5.50",
      discount: "R 999.00",
      total: "R 9999.00",
    },
  },
];
interface Address {
  address: string;
  addressName: string;
  aptNumber: string;
  isDefault: boolean;
  latitude: number;
  longitude: number;
}

interface PaymentCard {
  cardName: string;
  nameOnCard: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  isDefault: boolean;
}

interface CustomerDetail {
  PK: string;
  SK: string;
  UserPoolId: string;
  address: Address[]; // Updated to Address[]
  createdDate: string;
  email: string;
  entityName: string;
  favourites: string; // This will be parsed into an array if necessary
  fullName: string;
  group: string;
  list: string; // This will be parsed into an array if necessary
  paymentcards: PaymentCard[]; // Updated to PaymentCard[]
  phone: string;
  storeId: string | null;
  updatedDate: string;
  userId: string;
  username: string;
}

export type { Address, PaymentCard, CustomerDetail };

export { columns_name, data, orders };

interface OrderDetail {
  imageURL: string;
  productName: string;
  productCode: string;
  salesPrice: string;
  quantity: number;
}

interface OrderData {
  IdUser: string;
  PK: string;
  SK: string;
  actualDelivery: string;
  address: Address[];
  chat: string; // Assuming this is a JSON string that might be parsed later
  createdDate: string;
  deliveryCharge: number;
  entityName: string;
  estimatedDelivery: string;
  id: string;
  isDeleted: string;
  isSplited: boolean;
  orderDetails: OrderDetail[];
  promocode: string;
  reforder: string;
  specialinStructions: string; // Assuming this is a JSON string that might be parsed later
  status: string;
  subtotal: number;
  tip: number;
  total: number;
  updatedDate: string;
}

export type { OrderDetail, OrderData };
