import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cooking_instruction: Yup.array().of(
    Yup.object().shape({
      instruction: Yup.string().trim().required("Instruction is required"),
    }),
  ),
  ingredientsDetail: Yup.array().of(
    Yup.object().shape({
      // sku: Yup.string().trim().required("SKU is required"),
      productCode: Yup.string().trim().required("Product code is required"),
      qty: Yup.number().required("Quantity is required"),
      substituteDetails: Yup.array().of(
        Yup.object().shape({
          productCode: Yup.string()
            .trim()
            .required("Substitute product code is required"),
          qty: Yup.number().required("Substitute quantity is required"),
          // sku: Yup.string().trim().required("Substitute SKU is required"),
        }),
      ),
    }),
  ),
  // description: Yup.string().trim().required("Description is required"),
  // recpies_code: Yup.string().trim().required("Recipe code is required"),
  difficulty_level: Yup.string()
    .trim()
    .required("Difficulty level is required"),
  recipe_title: Yup.string().trim().required("Recipe title is required"),
  author_chef: Yup.string().trim().required("Author chef is required"),
  total_time: Yup.string().trim().required("Total time is required"),
  prep_time: Yup.string().trim().required("Prep time is required"),
  cooking_time: Yup.string().trim().required("Cooking time is required"),
  recipeName: Yup.string().trim().required("Recipe name is required"),
  categoryId: Yup.string().trim().required("Category is required"),
  subcategoryId: Yup.string().trim().required("Sub-CategoryId  is required"),

  // storeId: Yup.string().trim().required("Store is required"),
  //   imageURL: Yup.string().trim().required('Image URL is required'),
});

export default validationSchema;
