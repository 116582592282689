export const CREATE_SUB_CATEGORY = `
  mutation MyMutation($input: subCategoryInput!) {
    createSubCategory(input: $input)
  }
  
`;

export const UPDATE_SUB_CATEGORY = `
mutation MyMutation($input: subCategoryInput!) {
    updateSubCategory(input: $input) 
}

`;

export const DELETE_SUB_CATEGORY = `
mutation MyMutation($PK: String!, $SK: String!) {
    deleteSubCategory(PK: $PK, SK: $SK) 
}
`;
