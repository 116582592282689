export const processCustomerData = (data: any) => {
  const modifiedData = data?.hits?.reduce((acc: any[], val: any) => {
    const transformedRecord = {
      id: val._source.userId,
      ...val._source,
    };

    acc.push(transformedRecord);
    return acc;
  }, []);

  const finalData = {
    modifiedData,
    total: data?.total?.value,
  };

  return finalData;
};

export const processOrderResponse = (data: any) => {
  let successfulOrderCount = 0;
  let failedOrderCount = 0;
  const modifiedData = data?.hits?.map((val: any) => {
    if (val._source.status === "Completed") {
      successfulOrderCount += 1;
    } else if (
      val._source.paymentStatus === "DECLINED" ||
      val._source.status === "failed"
    ) {
      failedOrderCount += 1;
    }

    return {
      id: val._source.productCode,
      ...val._source,
    };
  });

  let finalData = {
    modifiedData,
    successfulOrderCount,
    failedOrderCount,
    total: data?.total?.value,
  };

  return finalData;
};
