import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "../../ContextAPI/ContextApi";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { searchCategory } from "../../../Api/Search/Category/Category";
import { categoryResponse } from "../utils";
import { updateCategory } from "../../../Api/Category/mutations";
import { Routers } from "../../../Routes/Routes";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  isValidJSON,
  updateFilterAndPagination,
} from "../../../Common Functions/Function";
import Toast from "../../Toast/Toast";
import {} from "react-toastify";
import Table from "../../../Blocks/Table/Table";
import { columns_name } from "../data";
import swal from "sweetalert";
import { useStoreId } from "../../ContextAPI/StoreIdContext";
import { setProductPriority } from "../../../Api/Product/mutations";

type SearchData = {
  fields: string;
  value: string;
};

const Category = () => {
  const { alert, message, errorType, setAlert, setMessage, setErrorType } =
    useAlert();

  const navigate = useNavigate();
  const [parentItems, setParentItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const { storeId } = useStoreId();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });

  //// eslint-disable-next-line
  const getCategorylist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchCategory("category", filtervalue, Pagination, storeId)
        .then(async (res) => {
          const result = categoryResponse(res);
          setParentItems(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching categories:", err);
          setIsLoading(false);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    getCategorylist(filtervalue, Pagination);
  }, [getCategorylist, filtervalue, Pagination]);

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Category ${data?.name}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      const payload = {
        ...data,
        isDeleted:
          getStatus(data?.isDeleted) === "Disable"
            ? `${storeId}-true`
            : `${storeId}-false`,
      };
      delete payload.label;
      delete payload.value;

      await updateCategory(payload)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.updateCategory);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            swal(
              getStatus(data?.isDeleted),
              `Category has been ${getStatus(data?.isDeleted)} successfully`,
              "success",
            );
            getCategorylist(filtervalue, Pagination);
          } else {
            swal(errorMessage);
          }
        })
        .catch((error) => {
          swal(
            `Failed to ${getStatus(data?.isDeleted)} Category. Please try again later.`,
          );
        });
    }
  };

  const categoryEdit = (data: { PK: string; SK: string }) => {
    navigate(
      `${Routers.updatecategories
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))}`,
    );
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  const [priorityLoader, setPriorityLoader] = useState(false);
  const [priorityButtonEnable, setPriorityButtonEnable] = useState(true);
  const Priority = async () => {
    setPriorityLoader(true);
    const processedData = parentItems.map((item: any) => {
      return {
        PK: item?.PK,
        SK: item?.SK,
        priority: item?.priority,
        entityName: item?.entityName,
        id: item?.id,
      };
    });
    console.log("Processed Data:", processedData);
    await setProductPriority(processedData, "category").then((res) => {
      console.log(res);
      const result = isValidJSON(res.data.updateBulk)
        ? JSON.parse(res.data.updateBulk)
        : res.data.updateBulk;
      if (result?.statusCode === 200) {
        setAlert(true);
        setErrorType("success");
        setMessage("Priority Set Successfully");
        setPriorityLoader(false);
        setPriorityButtonEnable(true);
      } else {
        setAlert(true);
        setErrorType("success");
        setMessage("An error occurred. Please try again.");
        setPriorityLoader(false);
      }
    });
  };

  const handlePriorityData = (data: any) => {
    setPriorityButtonEnable(data);
  };

  return (
    <>
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      <div className="row mb-4 mt-4 justify-content-between align-items-center">
        <div className="col-auto">
          <h3 className="theme_color fw-bolder m-0">Categories</h3>
        </div>
        <div className="col-auto d-flex align-items-center gap-2">
          <button
            className="btn btn-outline-success w-100"
            onClick={() => Priority()}
            disabled={priorityLoader || priorityButtonEnable}
          >
            {priorityLoader ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Priority Set"
            )}
          </button>
          <div className="col-auto ms-auto" onClick={() => setAlert(false)}>
            <Link className="text-decoration-none" to={Routers?.newcategories}>
              <button
                className="btn btn-outline-success w-100"
                type="button"
                style={{ whiteSpace: "nowrap" }}
              >
                New Category
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={columns_name}
          data={parentItems || []}
          paginationShow={true}
          handleDelete={handleDelete}
          handleEdit={categoryEdit}
          isLoading={isLoading}
          filterdata={filterdata}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
          draggable={true}
          handlePriorityData={handlePriorityData}
        />
      </div>
    </>
  );
};

export default Category;
