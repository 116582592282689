import { useEffect, useState } from "react";
import Table from "../../Blocks/Table/Table";
import {
  getLineGraphOptionsSales,
  productStockDetails,
  getVisitor,
} from "./data";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  getCombinedProductData,
  getOrdersData,
  getSalesStatistics,
} from "../../Api/Dashboard/mutations";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import axios from "axios";

interface CombinedProductData {
  SK: string;
  sellQuantity: number;
  itemName: string;
  salesPrice: number;
  costPrice: number;
  netProfit?: number;
  imageURL?: string;
}

const Dashboard = () => {
  const [active, setActive] = useState("daily");
  const { storeId } = useStoreId();
  const [orderChart, setOrderChart] = useState<any>([]);
  const [orderChartDate, setOrderChartDate] = useState<any>([]);
  const [SalesChartDate, setSalesChartDate] = useState<any>([]);
  const [salesChartData, setSalesChartData] = useState<any>([]);
  const [vistor, setVistor] = useState<any>([]);
  const fetchOrders = async () => {
    try {
      const result = await getOrdersData("order", storeId);
      if (result?.dailyData) {
        const totals = result.dailyData.map((item: any) => item.total);
        setOrderChart(totals);
        const dates = result.dailyData.map(
          (item: any) => item.date.split("T")[0],
        );
        setOrderChartDate(dates);
      } else {
        console.warn("No daily data found in the result.");
        setOrderChart([]);
        setOrderChartDate([]);
      }
    } catch (error) {
      console.error("Failed to fetch order stage data:", error);
    }
  };

  const fetchSalesStatistics = async (
    calendarInterval: string,
    orderId: string | null,
  ) => {
    try {
      const result = await getSalesStatistics(
        "order",
        calendarInterval,
        "Asia/Calcutta",
        orderId,
      );
      const totals = result.dailyData.map((item) => item.total);
      const dates = result.dailyData.map((item) => item.date.split("T")[0]);
      setSalesChartData(totals);
      setSalesChartDate(dates);
    } catch (error) {
      console.error("Error fetching sales statistics:", error);
    }
  };

  const [productData, setProductData] = useState<CombinedProductData[]>([]);
  const [takeawayData, setProductTakeAwayData] = useState<
    CombinedProductData[]
  >([]);

  const calculateNetProfit = (data: CombinedProductData[]) => {
    return data.map((item: any) => {
      const salesPrice = parseFloat(item.salesPrice) || 0.0;
      const costPrice = parseFloat(item.costPrice) || 0.0;
      const sellQuantity = item.sellQuantity;
      const profitPerUnit = salesPrice - costPrice;
      const netProfit = profitPerUnit * sellQuantity;

      return {
        ...item,
        netProfit: netProfit.toFixed(2)
          ? "R " + netProfit.toFixed(2)
          : "R 0.00",
      };
    });
  };

  const GetProductStockDetails = async () => {
    try {
      const data: CombinedProductData[] = await getCombinedProductData(
        "STORE",
        storeId,
      );
      const updatedData: any = calculateNetProfit(data);
      setProductData(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const GetProductTakeAwayDetails = async () => {
    try {
      const data: CombinedProductData[] = await getCombinedProductData(
        "TAKEAWAY",
        storeId,
      );
      const updatedData: any = calculateNetProfit(data);
      setProductTakeAwayData(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  useEffect(() => {
    if (storeId) {
      fetchSalesStatistics("1d", storeId);
    }
    setActive("daily");
    GetProductStockDetails();
    GetProductTakeAwayDetails();
    fetchOrders(); // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    VisitorApp();
    // eslint-disable-next-line
  }, [storeId]);

  const VisitorApp = () => {
    axios({
      method: "GET",
      url: `https://9gna5ibla5.execute-api.af-south-1.amazonaws.com/default/visitorPantryApp?storeId=${storeId}`,
    })
      .then((res) => {
        setVistor(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTimeFrameClick = (timeFrame: string) => {
    setActive(timeFrame);
    fetchSalesStatistics(
      timeFrame === "daily" ? "1d" : timeFrame === "weekly" ? "1w" : "1M",
      storeId,
    );
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12 pt-4">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <div className="col-auto">
                  <h4 className="theme_color fw-bolder card-title">
                    {"Sales Statistics"}
                  </h4>
                </div>
                <div className="d-flex col-auto justify-content-end gap-2 headerBtn ms-auto">
                  {["daily", "weekly", "monthly"].map((timeFrame) => (
                    <button
                      key={timeFrame}
                      className={`btn btn-outline-${active === timeFrame ? "success" : "primary"}`}
                      type="button"
                      onClick={() => handleTimeFrameClick(timeFrame)}
                    >
                      {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}
                    </button>
                  ))}
                </div>
              </div>

              <div className="mt-4">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getLineGraphOptionsSales(
                    SalesChartDate,
                    salesChartData,
                  )}
                  containerProps={{ style: { height: "300px" } }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">{"Bestsellers"}</h4>
          <div className="row mt-2">
            <div className="col-md-6 col-lg-6 mb-4">
              <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
                <h4 className="theme_color fw-bolder card-title">
                  {"AIL Products"}
                </h4>
                <Table
                  columns={productStockDetails}
                  data={productData}
                  paginationShow={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
                <h4 className="theme_color fw-bolder card-title">
                  {"Take Away"}
                </h4>
                <Table
                  columns={productStockDetails}
                  data={takeawayData}
                  paginationShow={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-4 d-flex">
          <div className="p-4 pe-2 p-md-4   white_bg flex-grow-1">
            <h5 className="theme_color fw-bold">{"Orders"} </h5>
            <span className="theme_color f-small">Last 7 days</span>
            <div className="mt-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={getLineGraphOptionsSales(orderChartDate, orderChart)}
                containerProps={{ style: { height: "300px" } }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 d-flex">
          <div className="p-4 pe-2 p-md-4   white_bg flex-grow-1">
            <h5 className="theme_color fw-bold">{"Visitor"} </h5>
            <span className="theme_color f-small">&nbsp;</span>
            <span className="theme_color f-small">Last 7 days</span>
            <div className="mt-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={getVisitor(vistor)}
                containerProps={{ style: { height: "300px" } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
