import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";
import { CLOUD_INVALIDATION } from "./query";

const client = generateClient();

export const cloudInvalidation = async (domain: string | undefined) => {
  try {
    const response = await client.graphql({
      query: CLOUD_INVALIDATION,
      variables: { input: domain },
    });
    return response;
  } catch (error) {
    //@ts-expect-error
    toast.error(error?.errors[0].message);
    return error;
  }
};
