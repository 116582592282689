import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  couponCode: Yup.string()
    .matches(/^\S*$/, "No spaces are allowed")
    .required("Coupon Code is required"),
  description: Yup.string().trim().required("Description is required"),
  startDate: Yup.date()
    .required("Start Date is required")
    .typeError("Start Date must be a valid date"),
  endDate: Yup.date()
    .required("End Date is required")
    .typeError("End Date must be a valid date")
    .min(Yup.ref("startDate"), "End Date must be after Start Date"),
  discountType: Yup.object({
    freeFirstDelivery: Yup.boolean(),
    fixDiscount: Yup.boolean(),
    fixAmount: Yup.number()
      .min(0, "Amount must be greater than or equal to 0")
      .typeError("Amount must be a valid number")
      .test(
        "fixAmount-required",
        "Fix Coupon Amount is required",
        function (value: any) {
          const { fixDiscount } = this.parent; // Access the `fixDiscount` field
          if (
            fixDiscount &&
            (value === undefined || value === null || value === "")
          ) {
            return false; // If fixDiscount is true and fixAmount is missing, fail validation
          }
          return true; // If fixDiscount is false or fixAmount is provided, pass validation
        },
      )
      .test(
        "fixAmount-valid",
        "Amount must be greater than or equal to 0",
        function (value) {
          if (value !== undefined && value !== null && value < 0) {
            return false; // Ensure the amount is not negative if provided
          }
          return true;
        },
      ),
  }),
  // discountType: Yup.object().shape({
  //   free_first_delivery: Yup.boolean(),
  //   free_first_3_deliveries: Yup.boolean(),
  //   free_cart_checkout_zar_discount: Yup.boolean(),
  //   percentage_discount_product_category: Yup.boolean(),
  //   percentage_discount_product: Yup.boolean(),
  // }),
  // targetProducts: Yup.array().of(
  //   Yup.object().shape({
  //     label: Yup.string().trim().required('Label is required'),
  //     Operator: Yup.string().trim().required('Operator is required'),
  //     Value: Yup.string().trim().required('Value is required'),
  //   })
  // ),
  // orderConditions: Yup.object().shape({
  //   minimum_purchase_amount: Yup.string().trim().required('Minimum Purchase Amount is required'),
  //   minimum_purchase_qty: Yup.string().trim().required('Minimum Purchase Quantity is required'),
  //   discount_amount: Yup.string().trim().required('Discount Amount is required'),
  // }),
  // categories: Yup.array().of(
  //   Yup.object().shape({
  //     categoryId: Yup.string().trim().required('Category ID is required'),
  //     name: Yup.string().trim().required('Category Name is required'),
  //   })
  // ),
  // products: Yup.array().of(
  //   Yup.object().shape({
  //     productCode: Yup.string().trim().required('Product Code is required'),
  //     name: Yup.string().trim().required('Product Name is required'),
  //   })
  // ),
});

export { validationSchema };
