export const categoryResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      ...val._source,
      value: val._source.id,
      label: val._source.name,
    };
  });

  const sortedData = modifiedData.sort((a: any, b: any) => {
    if (a.priority !== undefined && b.priority !== undefined) {
      return a.priority - b.priority; // Sort by 'priority' in ascending order
    }
    if (a.createdDate && b.createdDate) {
      return (
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
    }
    return 0;
  });

  let finalData = {
    modifiedData: sortedData,
    total: data?.total?.value,
  };
  return finalData;
};
