import { useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { columns_name, selectedKeys } from "./data";
import "react-toastify/dist/ReactToastify.css";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import swal from "sweetalert";
import {
  ProductStockSynch,
  ProductSynch,
  updateProduct,
} from "../../Api/Product/mutations";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ProductResponse } from "./utils";
import { searchProduct } from "../../Api/Search/Product/Product";
import debounce from "lodash.debounce";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import moment from "moment";
import { Routers } from "../../Routes/Routes";

const AllProducts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState();
  const [productType, setProductType] = useState("All");
  const [itemImported, setItemImported] = useState("All");

  const navigate = useNavigate();
  const { storeId } = useStoreId();
  type SearchData = {
    fields: string;
    value: string;
  };
  const { setAlert, setMessage, setErrorType, errorType } = useAlert();
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });
  const [synchLoading, setsynchLoading] = useState(false);
  const [syncStockhLoading, setsyncStockhLoading] = useState(false);

  //// //eslint-disable-next-line
  const GetProductlist = useCallback(
    debounce(
      async (
        filtervalue: SearchData[],
        Pagination: any,
        itemImported: string,
      ) => {
        setIsLoading(true);
        await searchProduct(
          "product",
          filtervalue,
          Pagination,
          storeId,
          "NA",
          itemImported,
        )
          .then(async (res) => {
            const result = await ProductResponse(res);
            setProduct(result?.modifiedData);
            setTotal(result?.total);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      },
      3000,
    ),
    [storeId],
  );

  useEffect(() => {
    GetProductlist(filtervalue, Pagination, itemImported);
  }, [GetProductlist, filtervalue, Pagination, itemImported]);

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Product ${data?.itemName}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      const newData = {
        ...data,
        isDeleted: getStatus(data?.isDeleted) === "Disable" ? true : false,
        extraFieldsProduct: JSON.stringify(data?.extraFieldsProduct),
        createdDate: moment(data?.createdDate).toISOString(),
        updatedDate: moment(data?.updatedDate).toISOString(),
      };
      selectedKeys.forEach((key: any) => {
        delete newData[key];
      });
      delete newData?.value;
      delete newData?.label;
      delete newData?.salesPrice;

      updateProduct(newData)
        .then((response) => {
          const result = JSON.parse(response.data.updateProduct);
          const errorMessage = JSON.parse(result.body).error;
          if (result?.statusCode === 200) {
            swal(
              getStatus(data?.isDeleted),
              `Product has been ${getStatus(data?.isDeleted)} successfully`,
              "success",
            );
            GetProductlist(filtervalue, Pagination, itemImported);
          } else {
            swal(errorMessage);
          }
        })
        .catch((err) =>
          swal("Failed to disable product. Please try again later."),
        );
    }
  };

  const { alert, message } = useAlert();

  const handleEdit = (data: any) => {
    setProductType("");
    navigate(
      // route//
      Routers?.updateProductsManipulation
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data.SK))
        .replace(":productType", encodeURIComponent(data.productType)),
    );
    // const modalElement = document.getElementById("assignProductTypeModal");
    // //@ts-expect-error
    // const modalInstance = new bootstrap.Modal(modalElement);
    // modalInstance.show(); // Opens the modal
  };

  // const UpdateProductType = () => {
  //   if (!selectedProduct) return;

  //   if (productType === "") {
  //     setAlert(true);
  //     setErrorType("error");
  //     setMessage("First select a Product Type");
  //     return;
  //   }
  //   setproductTypeLoader(true);
  //   const modalElement = document.getElementById("assignProductTypeModal");
  //   const modalInstance = //@ts-expect-error
  //     bootstrap.Modal.getInstance(modalElement) || //@ts-expect-error
  //     new bootstrap.Modal(modalElement);
  //   modalInstance.hide();
  //   const productTypeRoutes = {
  //     STORE: Routers.updateproduct,
  //     MEAL: Routers.takeawayupdateproduct,
  //     EXTRATAKEAWAY: Routers.extratakeawayupdateproduct,
  //   };
  //   //@ts-expect-error
  //   const route = productTypeRoutes[productType];
  //   if (route) {
  //     navigate(
  //       // route
  //       Routers?.updateProductsManipulation
  //         .replace(":PK", encodeURIComponent(selectedProduct.PK))
  //         .replace(":SK", encodeURIComponent(selectedProduct.SK))
  //         .replace(
  //           ":productType",
  //           encodeURIComponent(selectedProduct.productType)
  //         )
  //     );
  //   } else {
  //     setAlert(true);
  //     setErrorType("error");
  //     setMessage("Invalid Product Type selected");
  //   }
  //   setProductType("");
  //   setproductTypeLoader(false);
  // };

  //----------

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  let index = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product";

  const FetchProductSynch = async () => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    setsynchLoading(true);
    await ProductSynch(index, setAlert, setMessage, storeId, productType)
      .then((res: any) => {
        if (res?.errors) {
          setAlert(true);
          setErrorType("error");
          setMessage(res?.errors ? res.errors[0]?.message : "Unknown error");
          // setMessage("somthing went wrong!");
          setsynchLoading(false);
        } else {
          GetProductlist(filtervalue, Pagination, itemImported);
          setsynchLoading(false);
        }
      })
      .catch(() => {
        setAlert(true);
        setErrorType("error");
        setMessage("somthing went wrong!");
        setsynchLoading(false);
        console.log("error");
      });
  };

  const CreateStockQuantity = async () => {
    if (storeId === "") {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    setsyncStockhLoading(true);
    await ProductStockSynch(storeId, setAlert, setMessage)
      .then((res: any) => {
        if (res?.errors) {
          setAlert(true);
          setErrorType("error");
          setMessage(res?.errors ? res.errors[0]?.message : "Unknown error");
          // setMessage("somthing went wrong!");
          setsyncStockhLoading(false);
        } else {
          GetProductlist(filtervalue, Pagination, itemImported);
          setsyncStockhLoading(false);
        }
      })
      .catch(() => {
        setAlert(true);
        setErrorType("error");
        setMessage("somthing went wrong!");
        setsyncStockhLoading(false);
        console.log("error");
      });
  };

  const TypeFilter = (e: any) => {
    const { value } = e.target;
    setItemImported(value);
  };

  return (
    <>
      {alert && message && (
        <Toast type={errorType ? errorType : "success"} message={message} />
      )}
      <div>
        <div className="row mb-4 mt-4 justify-content-between">
          <div className="col-auto">
            <h3 className="theme_color fw-bolder mb-2 mb-sm-0">
              {"All Products"}
            </h3>
          </div>
          {storeId === "" ? null : (
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-12 col-md-6 col-lg-2 mb-2 mb-lg-0">
                  <select
                    className="form-select product-filter-select p-2"
                    aria-label="Default select example"
                    name="store"
                    id="id"
                    onChange={(e) => TypeFilter(e)}
                  >
                    <option value="All">All</option>
                    <option value={"true"}>ARCH</option>
                    <option value={"false"}>Create</option>
                  </select>
                </div>
                <div className="col-12 col-md-auto mb-2 mb-md-0">
                  <button
                    className="btn btn-outline-success w-100"
                    disabled={synchLoading}
                    onClick={() => FetchProductSynch()}
                    type="button"
                  >
                    {synchLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Sync Arch"
                    )}
                  </button>
                </div>

                <div className="col-12 col-md-auto mb-2 mb-md-0">
                  <button
                    className="btn btn-outline-success w-100"
                    disabled={syncStockhLoading}
                    onClick={() => CreateStockQuantity()}
                    type="button"
                  >
                    {syncStockhLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Sync Stock"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="border p-4  border-grey  rounded  white_bg">
          <Table
            columns={columns_name}
            data={product}
            paginationShow={true}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            total={total}
            isLoading={isLoading}
            filterdata={filterdata}
            handlePagination={handlePagination}
            Pagination={Pagination}
            handlePageSize={handlePageSize}
          ></Table>
          {/* <div
            className="modal fade"
            id="assignProductTypeModal"
            tabIndex={-1}
            aria-labelledby="assignProductTypeModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="assignProductTypeModalLabel">
                    Assign Product Type
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="productTypeSelect">Product Type:</label>
                    <select
                      className="form-control"
                      id="productTypeSelect"
                      onChange={(e) => {
                        setProductType(e.target.value);
                      }}
                      required
                      value={productType}
                    >
                      <option value="">Select Product Type</option>
                      <option value="STORE">STORE</option>
                      <option value="MEAL">MEAL</option>
                      <option value="EXTRATAKEAWAY">EXTRATAKEAWAY</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={(e) => {
                      const modalElement = document.getElementById(
                        "assignProductTypeModalLabel"
                      );
                      const modalInstance = //@ts-expect-error
                        bootstrap.Modal.getInstance(modalElement);
                      modalInstance?.hide();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="confirmAssignProductType"
                    onClick={() => UpdateProductType()}
                    disabled={productTypeLoader}
                  >
                    {productTypeLoader ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Assign Product Type"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AllProducts;
