var options = {
  chart: {
    height: 350,
    type: "heatmap",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: -Infinity,
            to: Infinity,
            color: "#87A376",
            name: "low",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: "",
  },
  legend: {
    show: false,
  },
};

var series = [
  {
    name: "Sun",
    data: formatData([0, 0, 0, 0, 20, 40, 4, 5, 5]),
  },
  {
    name: "Sat",
    data: formatData([0, 0, 0, 0, 20, 40, 4, 5, 5]),
  },
  {
    name: "Fri",
    data: formatData([0, 0, 0, 0, 20, 40, 4, 5, 5]),
  },
  {
    name: "Thu",
    data: formatData([0, 0, 15, 10, 40, 25, 35, 5, 5]),
  },
  {
    name: "Wed",
    data: formatData([0, 0, 5, 10, 35, 25, 30, 5, 5]),
  },
  {
    name: "Tue",
    data: formatData([0, 0, 4, 0, 28, 28, 12, 5, 5]),
  },
  {
    name: "Mon",
    data: formatData([0, 4, 0, 4, 4, 0, 4, 5, 5]),
  },
];

export { options, series };

function formatData(data: any[]) {
  let newData = [];
  let categories = [
    "12 AM",
    "03 AM",
    "06 AM",
    "09 AM",
    "12 PM",
    "03 PM",
    "06 PM",
    "09 PM",
    "12 PM",
  ];

  for (var i = 0; i < categories.length; i++) {
    newData.push({
      x: categories[i],
      y: data[i],
    });
  }
  return newData;
}

const MenuShow = {
  MENU: "Menu",
  MENU_NEW: "NewMenu",
};

export { MenuShow };
