export const CREATE_PRODUCT = `
  mutation MyMutation($input: ProductInput!) {
    createProduct(input: $input) 
  }
`;

export const DELETE_PRODUCT = `
mutation MyMutation($PK: String!, $SK: String!) {
  deleteProduct(PK: $PK, SK: $SK) 
}
`;

export const UPDATE_PRODUCT = `
  mutation UpdateProduct($input: ProductInput!) {
    updateProduct(input: $input)
  }
`;

export const IMAGE_UPLOAD = `mutation MyMutation ($input: S3Object!){
  createPreSignedUrl(input: $input)
}`;

export const PRODUCT_SYNCH = `
  mutation MyMutation($entityName: String!, $storeId: String!, $productType: String!) {
    importProducts(entityName: $entityName, storeId: $storeId, productType: $productType)
  }
`;

export const PRODUCT_SK_MATCH = `query MyQuery ($SKU: String! ){
  getProductStockBySku(SKU: $SKU )
}`;

export const SYNCH_STOCK_QUANTITY = `
  mutation MyMutation($storeId: String!) {
    createSyncStockQuantity(input: {storeId: $storeId}) 
  }
`;

export const PRODUCT_PRIORITY_SET = `
  mutation MyMutation($input: [UpdateInput!]!, $tableName: String!) {
    updateBulk(items: $input, tableName: $tableName)
  }
`;
