import * as Yup from "yup";
import countryData from "../../Data/CountryCode.json";

const getPhoneLength = (countryCode: string) => {
  const countryCodeWithoutPlus = countryCode?.replace(/^\+/, ""); // Remove the first '+' sign
  const country = countryData.find(
    (country) => country.phone === countryCodeWithoutPlus,
  );
  return country
    ? Array.isArray(country.phoneLength)
      ? country.phoneLength[0]
      : country.phoneLength
    : 0;
};

export const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(50, "Name must not exceed 50 characters")
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed"),
  email: Yup.string()
    .trim()
    .email("Invalid email")
    .required("Email is required"),
  phone_number: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Only numbers are allowed") // Ensure only numbers are allowed
    .matches(/^(?!.* {1,})/, "Spaces are not allowed") // Ensure no spaces are allowed
    .test("phone-length", "Invalid phone number length", function (value) {
      const countryCode = this.parent.country_code;
      const phoneLength = getPhoneLength(countryCode);
      if (phoneLength && phoneLength > 0) {
        return value ? value.toString().trim().length === phoneLength : true;
      }
      return true;
    })
    .required("Contact is required"),
});

export const PasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed")
    .test("length-check", "Password must be at least 8 characters", (value) => {
      if (value === undefined || value.trim().length === 0) {
        return false; // or you can return a specific validation error message
      }
      return value.trim().length >= 8;
    })
    .required("Old password is required"),

  newPassword: Yup.string()
    .trim()
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed")
    .test("length-check", "Password must be at least 8 characters", (value) => {
      if (value === undefined || value.trim().length === 0) {
        return false;
      }
      return value.trim().length >= 8;
    })
    .required("New password is required"),

  confirmPassword: Yup.string()
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed")
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
