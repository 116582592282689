const columns_name = [
  {
    field: "name",
    label: "Name",
    type: "text",
  },
  {
    field: "menus",
    label: "Menus",
    type: "text",
  },
  {
    field: "items",
    label: "Items",
    type: "text",
  },
];

const data = [
  {
    id: "1",
    name: "Breads",
    menus: "Menu",
    items: "5 delivered items, 5 pickup items",
  },
  {
    id: "2",
    name: "Breads",
    menus: "Menu",
    items: "5 delivered items, 5 pickup items",
  },
  {
    id: "3",
    name: "Breads",
    menus: "Menu",
    items: "5 delivered items, 5 pickup items",
  },
];

const Categories = {
  MENU_CATEGORIES: "MenuCategories",
  MENU_NEW_CATEGORIES: "MenuNewCategories",
};

export { columns_name, data, Categories };
