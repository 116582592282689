import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { WHITELIST } from "../../Api/WhiteList/query";
import { generateUUID } from "../../Common Functions/Function";

const client = generateClient();

interface WhiteListItem {
  PK: string;
  SK: string;
  email: string;
  id: string;
  isDeleted: boolean;
}

const WhiteList: React.FC = () => {
  const [whitelist, setWhitelist] = useState<WhiteListItem[]>([]);
  const [currentItem, setCurrentItem] = useState<WhiteListItem | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Fetch the whitelist data
  const fetchWhitelist = async () => {
    setLoading(true);
    try {
      const response: any = await client.graphql({
        query: WHITELIST,
        variables: {
          input: {
            PK: "EMAIL",
            SK: "EMAIL",
            id: "NA",
            isDeleted: false,
            operation: "GET",
          },
        },
      });
      const result = JSON.parse(response?.data?.whiteList)?.body;
      const data = result ? JSON.parse(result)?.data : [];
      setWhitelist(data); // Set the data only if it's not empty
      setTimeout(() => setAlertMessage(null), 1000);
    } catch (error) {
      console.error("Error fetching whitelist:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission (Add/Update)
  const handleSubmit = async () => {
    console.log("currentItem", currentItem);
    const operation = currentItem?.id ? "UPDATE" : "CREATE"; // Determine operation based on `id`
    const uuid = generateUUID();
    // Recalculate SK and ID based on email
    const SK = `EMAIL#${uuid}`;
    const id = `EMAIL#${uuid}`;
    const variables = {
      input: {
        PK: "EMAIL", // Always set PK to EMAIL
        SK: currentItem?.id ? currentItem?.SK : SK, // Dynamically generated SK based on email
        email: currentItem?.email || "", // Capture email
        id: currentItem?.id ? currentItem?.id : id, // Dynamically generated ID based on email
        isDeleted: currentItem?.isDeleted || false, // Default to false for deletion status
        operation,
      },
    };

    try {
      const response: any = await client.graphql({
        query: WHITELIST,
        variables: variables,
      });

      setAlertMessage(
        currentItem?.id
          ? "Whitelist item updated!"
          : "Item added to whitelist!",
      );
      fetchWhitelist(); // Refresh whitelist after submission
      setCurrentItem(null); // Reset form after submission
    } catch (error) {
      console.error("Error saving whitelist item:", error);
    }
  };

  // Handle item deletion
  const handleDelete = async (PK: string, SK: string) => {
    try {
      const response = await client.graphql({
        query: WHITELIST,
        variables: { input: { PK, SK, operation: "DELETE" } },
      });
      setAlertMessage("Whitelist item deleted!");
      fetchWhitelist(); // Refresh the whitelist data
    } catch (error) {
      console.error("Error deleting whitelist item:", error);
    }
  };

  // Handle item edit
  const handleEdit = (item: WhiteListItem) => {
    setCurrentItem(item);
  };

  // Handle add new item
  const handleAdd = () => {
    setCurrentItem({
      PK: "EMAIL",
      SK: "",
      email: "",
      id: "",
      isDeleted: false,
    });
  };

  // Fetch whitelist data when component mounts
  useEffect(() => {
    fetchWhitelist();
  }, []);

  return (
    <div className="container mt-4">
      <h2>Whitelist</h2>
      {alertMessage && (
        <div className="alert alert-success">{alertMessage}</div>
      )}
      <button
        className="btn btn-outline-success mb-3 me-2 float-end offset-1"
        onClick={handleAdd}
        data-bs-toggle="modal"
        data-bs-target="#whitelistModal"
      >
        Add Item
      </button>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {whitelist?.length > 0 ? (
            whitelist.map((item, index) => (
              <tr key={item?.SK}>
                <td>{index + 1}</td>
                <td>{item?.email}</td>
                <td>
                  <button
                    className="btn btn-outline-info btn-sm me-2"
                    onClick={() => handleEdit(item)}
                    data-bs-toggle="modal"
                    data-bs-target="#whitelistModal"
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-outline-warning btn-sm"
                    onClick={() => handleDelete(item?.PK, item?.SK)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>
                {" "}
                {loading ? "Loading..." : "No whitelist items found"}{" "}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div
        className="modal fade"
        id="whitelistModal"
        tabIndex={-1}
        aria-labelledby="whitelistModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="whitelistModalLabel">
                {currentItem?.id ? "Edit Whitelist Item" : "Add Whitelist Item"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={currentItem?.email || ""}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem!, email: e.target.value })
                    }
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteList;
