import { Routers } from "../../Routes/Routes";

const restrictedUrls = [
  Routers?.newcategories,
  Routers?.newSubcategories,
  Routers?.updatecategories,
  Routers?.updateSubCategories,
  Routers?.newproduct,
  Routers?.updateproduct,
  Routers?.takeawaynewproduct,
  Routers?.takeawayupdateproduct,
  Routers?.newrecipes,
  Routers?.updaterecipes,
  Routers?.drivers,
  Routers?.chefsignup,
  Routers?.shopersignup,
  Routers?.newcoupon,
  Routers?.updateCoupons,
  Routers?.ordersDetails,
  Routers?.updateChef,
  Routers?.updatedrivers,
  Routers?.updateShopper,
  Routers?.driversignup,
  Routers?.takeawaynewproduct,
  Routers?.takeawayupdateproduct,
  Routers?.createmanager,
  Routers?.updatemanager,
  Routers?.driverdetails,
  Routers?.NewMemberShip,
  Routers?.updateMemberships,
  Routers?.updateProductsManipulation,
];

const closeModal = () => {
  const modalElement = document.getElementById("exampleModal");
  if (modalElement) {
    //@ts-expect-error
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }
};

const matchesRestrictedUrl = (url: string) => {
  return restrictedUrls.some((restrictedUrl) =>
    new RegExp(`^${restrictedUrl.replace(/\/:[^/]+/g, "/[^/]+")}$`).test(url),
  );
};

export { matchesRestrictedUrl, closeModal };
export { restrictedUrls };
