import SubCategories from "./SubCategory/SubCategories";
import Category from "./ParentCategory/Categories";

const Categories = () => {
  return (
    <>
      <Category />
      <SubCategories />
    </>
  );
};

export default Categories;
