const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "couponCode",
    label: "Coupon Code",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Coupon Code",
    },
  },
  {
    field: "startDate",
    label: "Start Date",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "Start Date",
    },
  },
  {
    field: "endDate",
    label: "End Date",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "End Date",
    },
  },
  {
    field: "status",
    label: "Status",
    type: "icon",
    sub_header: {
      inputtype: "select",
    },
  },
  // {
  //   field: "used_time",
  //   label: "Used Times",
  //   type: "text",
  //   sub_header: {
  //     inputtype: "fromTo",
  //     from: {
  //       placeholder: "From",
  //     },
  //     to: {
  //       placeholder: "To",
  //     },
  //   },
  // },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const data = [
  {
    id: "1",
    code: "DISCOUNT20",
    state_date: "2024-03-29",
    end_date: "2024-03-29",
    status: "true",
    used_time: "11",
  },
  {
    id: "2",
    code: "DISCOUNT20",
    state_date: "2024-03-30",
    end_date: "2024-03-30",
    status: "true",
    used_time: "11",
  },
  {
    id: "3",
    code: "DISCOUNT20",
    state_date: "2024-03-28",
    end_date: "2024-03-28",
    status: "true",
    used_time: "11",
  },
];

export { columns_name, data };
