import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Routers } from "../../Routes/Routes";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "../../Atoms/Select";
import {
  formattedDate,
  handleFileUpload,
} from "../../Common Functions/Function";
import { useFormik } from "formik";
import { useAlert } from "../ContextAPI/ContextApi";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import {
  getAllCategory,
  getAllCategoryByMultiId,
} from "../../Api/Search/Category/Category";
import { categoryResponse } from "../Categories/utils";
import ReactQuill from "react-quill";
import { TagsInput } from "react-tag-input-component";
import { List, ProductResponse } from "./utils";
import { updateProduct } from "../../Api/Product/mutations";
import ImageLoader from "../../Effects/ImageLoader";
import Toast from "../Toast/Toast";
import { searchWithPKSK } from "../../Api/Search/mutation";
import FormEffect from "../../Effects/FormEffect";
import validationSchema from "./validation";
import { getAllProductsByProductType } from "../../Api/Search/Product/Product";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import { selectedKeys } from "./data";

function TakeAwayUpdateProducts() {
  let index = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { alert, message, errorType, setErrorType, setAlert, setMessage } =
    useAlert();
  const { storeId } = useStoreId();
  const [loader, setLoader] = useState(false);
  const [parentItems, setParentItems] = useState<any>([]);
  const [subItems, setSubItems] = useState<any>([]);
  const [productlist, setproductlist] = useState<any>([]);
  const [saveLoader, setsaveLoader] = useState(false);
  const { PK, SK } = useParams();
  const [dataLoader, setdataLoader] = useState(false);
  const [inventryProductDetails, setnventryProductDetails] = useState({
    architemName: "",
    archSku: "",
    archProductBrand: "",
    archProductSize: "",
    archCostPrice: "",
    archSalesPrice: "",
  });

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      itemName: "",
      sku: "",
      subcategoryId: "",
      categoryId: "",
      categorySK: "",
      subcategorySK: "",
      description: "",
      itemStatus: true,
      storeId: storeId,
      tags: [],
      entityName: index,
      isDeleted: `false`,
      createdDate: formattedDate(),
      updatedDate: formattedDate(),
      productType: "MEAL",
      salesPrice: "",
      mealCategories: [],
      mealSubCategories: [],
      storeCategories: [],
      storeSubCategories: [],

      productBrand: "",
      productSize: "",

      packWeightGrams: "",
      packSizeInmm: "",
      localInternational: "",
      halaalKosher: "",
      vegetarianVegan: "",
      onlinePicture: false,
      glutenFree: false,
      alcoholic: false,
      imported: false,
      halaal: false,
      kosher: false,
      vegetarian: false,
      vegan: false,
      sugarFree: false,
      imageURL: "",
      costPrice: "",
      packSizeBreadth: "",
      packSizeHeight: "",
      packSizeLength: "",

      productCode: "",
      PK: "",
      SK: "",
      id: "",

      Takeaway: [
        {
          title: "",
          isDefault: false,
          substituteDetails: [
            {
              subtitle: "",
              products: [
                {
                  type: "checkbox",
                  productCode: "",
                },
              ],
            },
          ],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setsaveLoader(true);
      // const newData: FormData = {
      //   extraFieldsProduct: JSON.stringify(formik?.values?.Takeaway),
      //   ...values,
      // };

      interface FormData {
        extraFieldsProduct: Record<string, any>;
        [key: string]: any;
      }

      const newData: FormData = {
        extraFieldsProduct: {},
      };

      // Assuming `values` is an object with key-value pairs and `selectedKeys` is an array of strings
      for (const key in values) {
        if (selectedKeys.includes(key)) {
          //@ts-expect-error
          const value = values[key];
          newData.extraFieldsProduct[key] = value;
        } else {
          //@ts-expect-error
          newData[key] = values[key];
        }
      }

      //@ts-expect-error
      newData.extraFieldsProduct = JSON.stringify({
        ...newData.extraFieldsProduct,
        Takeaway: formik?.values?.Takeaway || {},
        ...(newData.extraFieldsProduct = inventryProductDetails),
      });
      newData.productType = "MEAL";
      newData.storeCategories = [];
      newData.storeSubCategories = [];
      delete newData?.Takeaway;
      delete newData?.categoryId;
      delete newData?.categorySK;
      delete newData?.subcategorySK;
      delete newData?.subcategoryId;
      delete newData?.salesPrice;
      delete newData?.label;
      delete newData?.value;

      await updateProduct(newData)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.updateProduct);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.takeawayproducts);
            setAlert(true);
            setErrorType("success");
            setMessage("Product Update Successfully");
            setsaveLoader(false);
            return;
          } else {
            setErrorType("error");
            setAlert(true);
            setMessage(errorMessage);
            setsaveLoader(false);
          }
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const getCategorylist = useCallback(async () => {
    await getAllCategory("", "category", "Takeaway", storeId)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setParentItems(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getCategorylist();
  }, [getCategorylist]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (formik?.values?.categoryId) {
  //       await getAllCategoryById(
  //         "",
  //         "subcategory",
  //         "",
  //         formik?.values?.categoryId,
  //         storeId
  //       )
  //         .then((res) => {
  //           const result = categoryResponse(res);
  //           setSubItems(result?.modifiedData);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching categories:", error);
  //         });
  //     }
  //   };

  //   fetchData();
  // }, [formik?.values?.categoryId, storeId]);

  useEffect(() => {
    const fetchData = async () => {
      if (formik?.values?.mealCategories) {
        await getAllCategoryByMultiId(
          "",
          "subcategory",
          "",
          formik?.values?.mealCategories,
          storeId,
        )
          .then((res) => {
            const result = categoryResponse(res);
            setSubItems(result?.modifiedData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    fetchData();
  }, [formik?.values?.mealCategories, storeId]);

  const getProductlist = useCallback(async () => {
    await getAllProductsByProductType("", "product", storeId, "EXTRATAKEAWAY")
      .then((res) => {
        const result = ProductResponse(res);
        setproductlist(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getProductlist();
  }, [getProductlist]);

  // const handleCategory = (data: any) => {
  //   let val = isValidJSON(data) ? JSON.parse(data) : data;
  //   formik.setFieldValue("categorySK", val?.SK);
  //   formik.setFieldValue("categoryId", val?.id);
  //   formik.setFieldValue("subcategorySK", "");
  //   formik.setFieldValue("subcategoryId", "");
  // };

  // const handleSubCategory = (data: any) => {
  //   let val = isValidJSON(data) ? JSON.parse(data) : data;
  //   formik.setFieldValue("subcategorySK", val?.SK);
  //   formik.setFieldValue("subcategoryId", val?.id);
  // };

  const handleMultipleCategory = (selectedOptions: any) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      formik.setFieldValue("mealCategories", []);
      formik.setFieldValue("mealSubCategories", []);
      return;
    }
    const ids = selectedOptions.map((item: { id: string }) => item.id);
    formik.setFieldValue("mealCategories", ids);
  };

  const memoizedValue = useMemo(() => {
    const selectedCategories: string[] = formik?.values?.mealCategories || [];
    return parentItems.filter((item: any) =>
      selectedCategories?.includes(String(item.id)),
    );
  }, [formik?.values?.mealCategories, parentItems]);

  const handleMultipleSubCategory = (selectedOptions: any) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      formik.setFieldValue("mealSubCategories", []);
      return;
    }
    const ids = selectedOptions.map((item: { id: string }) => item.id);
    formik.setFieldValue("mealSubCategories", ids);
  };

  const memoizedValueSubCategory = useMemo(() => {
    const selectedCategories: string[] = formik?.values?.mealSubCategories;
    if (subItems?.length > 0 && dataLoader === false) {
      return subItems?.filter((item: any) =>
        selectedCategories?.includes(String(item.id)),
      );
    }
    return [];
  }, [formik?.values?.mealSubCategories, subItems, dataLoader]);

  const prevSubCategoriesRef = useRef<string[]>(
    formik.values.mealSubCategories || [],
  );

  useEffect(() => {
    const currentIds =
      memoizedValueSubCategory?.map((item: { id: string }) => item.id) || [];
    const previousIds = prevSubCategoriesRef.current;
    const hasChanged =
      JSON.stringify(previousIds) !== JSON.stringify(currentIds);

    if (hasChanged) {
      formik.setFieldValue("mealSubCategories", currentIds);
      prevSubCategoriesRef.current = currentIds;
    }
  }, [memoizedValueSubCategory, formik]);

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    await handleFileUpload(
      event,
      setLoader,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setMessage("First select a store");
      setErrorType("error");
      return;
    }

    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const addTakeaway = () => {
    const newTakeaway = {
      title: "",
      isDefault: false,
      substituteDetails: [
        {
          subtitle: "",
          products: [
            {
              type: "checkbox",
              productCode: "",
            },
          ],
        },
      ],
    };

    formik.setFieldValue("Takeaway", [...formik.values.Takeaway, newTakeaway]);
  };

  // Add a new Substitute Detail to a specific Takeaway item
  const addSubstituteDetail = (takeawayIndex: number) => {
    const newSubstituteDetail = {
      subtitle: "",
      products: [
        {
          type: "checkbox",
          productCode: "",
        },
      ],
    };

    const updatedTakeaway = [...formik.values.Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails.push(newSubstituteDetail);
    formik.setFieldValue("Takeaway", updatedTakeaway);
  };

  // Remove a Substitute Detail from a specific Takeaway item
  const removeSubstituteDetail = (
    takeawayIndex: number,
    detailIndex: number,
  ) => {
    const updatedTakeaway = [...formik.values.Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails = updatedTakeaway[
      takeawayIndex
    ].substituteDetails.filter((_, i) => i !== detailIndex);
    formik.setFieldValue("Takeaway", updatedTakeaway);
  };

  // Add a new Product to a specific Substitute Detail
  const addProduct = (takeawayIndex: number, detailIndex: number) => {
    const newProduct = {
      type: "checkbox", // Default type or adjust as needed
      productCode: "",
    };

    const updatedTakeaway = [...formik.values.Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails[detailIndex].products.push(
      newProduct,
    );
    formik.setFieldValue("Takeaway", updatedTakeaway);
  };

  // Remove a Product from a specific Substitute Detail
  const removeProduct = (
    takeawayIndex: number,
    detailIndex: number,
    productIndex: number,
  ) => {
    const updatedTakeaway = [...formik.values.Takeaway];
    updatedTakeaway[takeawayIndex].substituteDetails[detailIndex].products =
      updatedTakeaway[takeawayIndex].substituteDetails[
        detailIndex
      ].products.filter((_, i) => i !== productIndex);
    formik.setFieldValue("Takeaway", updatedTakeaway);
  };

  // Remove a Takeaway item
  const removeTakeaway = (index: number) => {
    const updatedTakeaway = [...formik.values.Takeaway];
    updatedTakeaway.splice(index, 1);
    formik.setFieldValue("Takeaway", updatedTakeaway);
  };

  const getProduct = useCallback(async () => {
    setdataLoader(true);
    await searchWithPKSK("product", PK, SK).then(async (res) => {
      const result = ProductResponse(res);
      if (result?.modifiedData) {
        const formattedData: any = result?.modifiedData?.flatMap((item: any) =>
          (Array.isArray(item.extraFieldsProduct?.Takeaway)
            ? item.extraFieldsProduct?.Takeaway
            : []
          )?.map((field: any) => ({
            title: field.title || "",
            substituteDetails:
              (Array.isArray(field.substituteDetails)
                ? field.substituteDetails
                : []
              )?.map((detail: any) => ({
                subtitle: detail.subtitle || "",
                products: (Array.isArray(detail.products)
                  ? detail.products
                  : []
                )?.map((product: any) => ({
                  type: product.type || "",
                  productCode: product.productCode || "",
                })),
              })) || [],
          })),
        );

        const modifiedData = result?.modifiedData?.[0] || {}; // Ensure modifiedData exists

        // Loop through the selected keys
        for (const key of selectedKeys) {
          if (key === "productType") continue;

          // Check if the key exists in extraFieldsProduct
          if (modifiedData?.extraFieldsProduct?.hasOwnProperty(key)) {
            const value = modifiedData.extraFieldsProduct[key];

            // Attempt to parse the value first
            let parsedValue;
            try {
              parsedValue = JSON.parse(value);
            } catch (e) {
              parsedValue = value; // Keep original value if parsing fails
            }

            // Check if the parsed value is a boolean
            await formik.setFieldValue(
              `${key}`,
              typeof parsedValue === "boolean" ? parsedValue : value, // Use original value if boolean
            );
          }

          // Check if the key exists at the root level
          if (modifiedData.hasOwnProperty(key)) {
            const value = modifiedData[key];

            // Attempt to parse the value first
            let parsedValue;
            try {
              parsedValue = JSON.parse(value);
            } catch (e) {
              parsedValue = value; // Keep original value if parsing fails
            }

            // Check if the parsed value is a boolean
            await formik.setFieldValue(
              key,
              typeof parsedValue === "boolean" ? parsedValue : value, // Use original value if boolean
            );
          }
        }
        // Update any other fields present at the root level (outside selectedKeys)
        for (const rootKey in modifiedData) {
          // Ensure the root key is not in extraFieldsProduct or already set by the selectedKeys loop
          if (!selectedKeys.includes(rootKey)) {
            const value = modifiedData[rootKey];

            // Attempt to parse the value first
            let parsedValue;
            try {
              parsedValue = JSON.parse(value);
            } catch (e) {
              parsedValue = value; // Keep original value if parsing fails
            }

            // Check if the parsed value is a boolean
            await formik.setFieldValue(
              rootKey,
              typeof parsedValue === "boolean" ? parsedValue : value, // Use original value if boolean
            );
          }
        }

        formik.setFieldValue(
          "Takeaway",
          formattedData.length > 0 ? formattedData : formik.values.Takeaway,
        );
        formik.setFieldValue("storeId", storeId);

        // formik.setValues((prevValues) => ({
        //   ...prevValues,
        //   ...formik.values,
        //   Takeaway:
        //     formattedData.length > 0 ? formattedData : prevValues.Takeaway,
        //   itemName: result?.modifiedData?.[0]?.itemName || "",
        //   sku: result?.modifiedData?.[0]?.sku || "",
        //   subcategoryId: result?.modifiedData?.[0]?.subcategoryId || "",
        //   categoryId: result?.modifiedData?.[0]?.categoryId || "",
        //   categorySK: result?.modifiedData?.[0]?.categorySK || "",
        //   subcategorySK: result?.modifiedData?.[0]?.subcategorySK || "",
        //   description: result?.modifiedData?.[0]?.description || "",
        //   itemStatus: isValidJSON(result?.modifiedData?.[0]?.itemStatus)
        //     ? JSON.parse(result?.modifiedData?.[0]?.itemStatus)
        //     : result?.modifiedData?.[0]?.itemStatus,
        //   imageURL: result?.modifiedData?.[0]?.imageURL || "",
        //   storeId: storeId,
        //   tags: result?.modifiedData?.[0]?.tags || [],
        //   entityName: result?.modifiedData?.[0]?.entityName || "",
        //   isDeleted: result?.modifiedData?.[0]?.isDeleted || "",
        //   PK: result?.modifiedData?.[0]?.PK || "",
        //   SK: result?.modifiedData?.[0]?.SK || "",
        //   productCode: result?.modifiedData?.[0]?.productCode || "",
        //   id: result?.modifiedData?.[0]?.id || "",
        //   salesPrice: result?.modifiedData?.[0]?.extraFieldsProduct?.salesPrice,
        //   mealCategories: result?.modifiedData?.[0]?.mealCategories,
        //   mealSubCategories: result?.modifiedData?.[0]?.mealSubCategories,
        //   storeCategories: result?.modifiedData?.[0]?.storeCategories,
        //   storeSubCategories: result?.modifiedData?.[0]?.storeSubCategories,
        // }));

        setnventryProductDetails({
          architemName: result?.modifiedData?.[0]?.archItemName,
          archSku: result?.modifiedData?.[0]?.productCode,
          archProductBrand:
            result?.modifiedData?.[0]?.extraFieldsProduct?.archProductBrand,
          archProductSize:
            result?.modifiedData?.[0]?.extraFieldsProduct?.archProductSize,
          archCostPrice:
            result?.modifiedData?.[0]?.extraFieldsProduct?.archCostPrice,
          archSalesPrice:
            result?.modifiedData?.[0]?.extraFieldsProduct?.archSalesPrice,
        });
      }
    });
    setdataLoader(false); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PK, SK]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const handleBlur = (event: any, field: string) => {
    let value = event.target.value;
    const cleanedValue = value.replace(/[^\d.]/g, "");
    // const [integerPart, decimalPart] = cleanedValue.split(".");
    // if (decimalPart && decimalPart.length > 2) {
    //   value = `${integerPart}.${decimalPart.substring(0, 2)}`;
    // } else {
    //   value = cleanedValue;
    // }
    formik.setFieldValue(field, cleanedValue);
  };

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  return (
    <div>
      {loader && <ImageLoader />}
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}

      <LoadingOverlay isVisible={saveLoader} message="Please wait..." />

      <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title mt-2 mt-md-0">
          <h4 className="theme_color fw-bolder">
            <Link className="text-decoration-none theme_color" to={`/${path}`}>
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            Update A Take-Away Product
          </h4>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Link className="text-decoration-none" to={`/${path}`}>
            <button className="btn btn-outline-danger" type="button">
              Cancel
            </button>
          </Link>
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={handleSubmit}
            disabled={loader || saveLoader}
          >
            {saveLoader ? (
              <div>
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      <div className="container">
        {dataLoader ? (
          <FormEffect />
        ) : (
          <div className="row">
            <div className="col-md-8">
              <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                <h4 className="theme_color fw-bold align-self-center mb-3">
                  {"Inventory Product Properties"}
                </h4>
                <div className="row">
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label`}
                    >
                      Product Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={inventryProductDetails?.architemName}
                    />
                  </div>
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label`}
                    >
                      Product Code<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={inventryProductDetails?.archSku}
                    />
                  </div>

                  <div className={`form-group row mb-3`}>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="costPrice" className={`form-label`}>
                        Cost Price<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        readOnly
                        value={inventryProductDetails?.archCostPrice || 0}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="costPrice" className={`form-label`}>
                        Sales Price<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        readOnly
                        value={inventryProductDetails?.archSalesPrice || 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                <h4 className="theme_color fw-bold align-self-center mb-3">
                  {"General"}
                </h4>
                {/* <div className={`form-group row mb-3`}>
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className={`form-label`}
                    >
                      Product Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sku"
                      readOnly
                      onChange={formik.handleChange}
                      value={formik.values?.sku}
                    />
                    {formik.touched.sku && formik.errors.sku ? (
                      <div className="text-danger">{formik.errors.sku}</div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description1" className={`form-label`}>
                      Category<span className="text-danger">*</span>
                    </label>

                    <Select
                      cacheOptions={true}
                      onChange={handleCategory}
                      defaultOptions={parentItems}
                      data={parentItems}
                      value={parentItems.find(
                        (item: { id: string }) =>
                          item?.id === formik?.values.categoryId
                      )}
                    />
                    {formik.touched.categoryId && formik.errors.categoryId && (
                      <div className="text-danger">
                        {formik.errors.categoryId}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description2" className={`form-label`}>
                      Sub-Category<span className="text-danger">*</span>
                    </label>

                    <Select
                      onChange={handleSubCategory}
                      defaultOptions={subItems}
                      data={subItems}
                      value={
                        formik?.values?.subcategoryId === ""
                          ? null
                          : subItems.find(
                              (item: { id: string }) =>
                                item?.id === formik?.values?.subcategoryId
                            )
                      }
                    />
                    {formik.touched.subcategoryId &&
                      formik.errors.subcategoryId && (
                        <div className="text-danger">
                          {formik.errors.subcategoryId}
                        </div>
                      )}
                  </div>
                </div> */}

                <div className={`form-group row mb-3`}>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description1" className={`form-label`}>
                      Category
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      cacheOptions={true}
                      onChange={handleMultipleCategory}
                      defaultOptions={parentItems}
                      data={parentItems}
                      value={memoizedValue}
                      isMulti={true}
                    />
                    {formik.touched.mealCategories &&
                    formik?.errors?.mealCategories ? (
                      <div className="text-danger">
                        {formik?.errors?.mealCategories}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description2" className={`form-label`}>
                      Sub-Category
                      <span className="text-danger">*</span>
                    </label>

                    <Select
                      cacheOptions={true}
                      onChange={handleMultipleSubCategory}
                      defaultOptions={subItems}
                      data={subItems}
                      value={memoizedValueSubCategory}
                      isMulti={true}
                    />
                    {formik.touched.mealSubCategories &&
                    formik?.errors?.mealSubCategories ? (
                      <div className="text-danger">
                        {formik?.errors?.mealSubCategories}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* 
                <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                  <h4 className="theme_color fw-bold align-self-center mb-3">
                    {"App Product Properties"}
                  </h4>
                  <div className={`form-group col-12 mb-3`}>
                    <Select
                      cacheOptions={true}
                      defaultOptions={List}
                      onChange={(val: any) =>
                        formik.setFieldValue("productType", val.id)
                      }
                      onBlur={formik.handleBlur}
                      data={List}
                      value={List.find(
                        (item: { id: string }) =>
                          item?.id === formik?.values.productType
                      )}
                    />
                  </div>
                </div> */}
                <div className={`form-group col-12 mb-3`}>
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className={`form-label`}
                  >
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="itemName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.itemName}
                  />
                  {formik.touched.itemName && formik.errors.itemName ? (
                    <div className="text-danger">{formik.errors.itemName}</div>
                  ) : null}
                </div>

                <div className="d-flex gap-2">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="salesPrice" className={`form-label`}>
                      Sales Price<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="salesPrice"
                      name="salesPrice"
                      onChange={formik.handleChange}
                      onKeyUp={(e) => handleBlur(e, "salesPrice")}
                      required
                      min={0}
                      value={formik?.values?.salesPrice}
                    />
                    {formik.touched.salesPrice && formik?.errors?.salesPrice ? (
                      <div className="text-danger">
                        {formik?.errors?.salesPrice}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="costPrice" className={`form-label`}>
                      Cost Price<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="costPrice"
                      name="costPrice"
                      onChange={formik.handleChange}
                      onKeyUp={(e) => handleBlur(e, "costPrice")}
                      required
                      min={0}
                      value={formik?.values?.costPrice}
                    />
                    {formik.touched.costPrice && formik?.errors?.costPrice ? (
                      <div className="text-danger">
                        {formik?.errors?.costPrice}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className={`form-group col-12 mb-3`}>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Tags
                    </label>
                    <div className="form-group col-12">
                      <TagsInput
                        name="tags"
                        onChange={(tags) =>
                          formik.handleChange({
                            target: { name: "tags", value: tags },
                          })
                        }
                        value={formik.values.tags}
                      />
                      <small className="form-text text-muted">
                        <i className="bi bi-info-circle"></i> Press Enter after
                        typing your tag to add it.
                      </small>
                    </div>
                  </div>
                </div>

                <div className={`form-group col-12 mb-3`}>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Description
                    </label>
                    <ReactQuill
                      theme="snow"
                      onChange={(content, delta, source, editor) => {
                        formik.setFieldValue("description", editor.getHTML());
                      }}
                      value={formik.values.description}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6] }],
                          ["bold", "italic", "link"],
                          [{ list: "ordered" }],
                          ["blockquote"],
                        ],
                      }}
                    />
                  </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="dynamic-fields col-12">
                      <h4 className="theme_color fw-bold mb-3">
                        Takeaway Items
                      </h4>
                      {formik.values.Takeaway.map((takeaway, takeawayIndex) => (
                        <div key={takeawayIndex} className="card mb-3">
                          <div className="card-body">
                            <div className="takeaway-item">
                              <div className="mb-3">
                                <input
                                  className="form-control"
                                  type="text"
                                  name={`Takeaway[${takeawayIndex}].title`}
                                  value={takeaway.title}
                                  onChange={formik.handleChange}
                                  placeholder="Title for Extras"
                                />
                                {formik.errors.Takeaway?.[takeawayIndex] //@ts-expect-error
                                  ?.title &&
                                  formik.touched.Takeaway?.[takeawayIndex]
                                    ?.title && (
                                    <div className="text-danger">
                                      {
                                        formik.errors.Takeaway[takeawayIndex] //@ts-expect-error
                                          .title
                                      }
                                    </div>
                                  )}
                              </div>

                              {takeaway.substituteDetails.map(
                                (detail, detailIndex) => (
                                  <div
                                    key={detailIndex}
                                    className="substitute-detail mb-3"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-8 col-md-10 mb-2">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`Takeaway[${takeawayIndex}].substituteDetails[${detailIndex}].subtitle`}
                                          value={detail.subtitle}
                                          onChange={formik.handleChange}
                                          placeholder="Subtitle for Extras (e.g. Meat, Cheese)"
                                        />
                                      </div>
                                      <div className="col-4 col-md-2 d-flex justify-content-end">
                                        {takeaway.substituteDetails.length >
                                          1 && (
                                          <i
                                            onClick={() =>
                                              removeSubstituteDetail(
                                                takeawayIndex,
                                                detailIndex,
                                              )
                                            }
                                            className="bi bi-dash-circle remove_icon"
                                          ></i>
                                        )}
                                      </div>
                                    </div>

                                    {detail.products.map(
                                      (product, productIndex) => {
                                        const selectedProductCodes =
                                          detail.products
                                            .map((p) => p.productCode)
                                            .filter((code) => code);
                                        const availableOptions =
                                          productlist.filter(
                                            (item: { productCode: string }) =>
                                              !selectedProductCodes.includes(
                                                item.productCode,
                                              ),
                                          );

                                        return (
                                          <div
                                            key={productIndex}
                                            className="product-item row mb-2 align-items-center"
                                          >
                                            <div className="col-12 col-sm-8 col-md-10 mb-2">
                                              <Select
                                                name={`Takeaway[${takeawayIndex}].substituteDetails[${detailIndex}].products[${productIndex}].productCode`}
                                                value={
                                                  product.productCode === ""
                                                    ? null
                                                    : productlist.find(
                                                        (item: {
                                                          productCode: string;
                                                        }) =>
                                                          item.productCode ===
                                                          product.productCode,
                                                      )
                                                }
                                                onChange={(option) => {
                                                  formik.setFieldValue(
                                                    `Takeaway[${takeawayIndex}].substituteDetails[${detailIndex}].products[${productIndex}].productCode`,
                                                    option?.value || "",
                                                  );
                                                }}
                                                defaultOptions={
                                                  availableOptions
                                                }
                                                placeholder="Product Code"
                                                options={availableOptions}
                                                data={availableOptions}
                                              />
                                              {formik.errors.Takeaway?.[
                                                takeawayIndex //@ts-expect-error
                                              ]?.substituteDetails?.[
                                                detailIndex
                                              ]?.products?.[productIndex]
                                                ?.productCode &&
                                                formik.touched.Takeaway?.[
                                                  takeawayIndex
                                                ]?.substituteDetails?.[
                                                  detailIndex
                                                ]?.products?.[productIndex]
                                                  ?.productCode && (
                                                  <div className="text-danger">
                                                    {
                                                      formik.errors.Takeaway[
                                                        takeawayIndex //@ts-expect-error
                                                      ]?.substituteDetails[
                                                        detailIndex
                                                      ]?.products[productIndex]
                                                        ?.productCode
                                                    }
                                                  </div>
                                                )}
                                            </div>{" "}
                                            <div className="col-12 col-sm-6 col-md-2 d-flex justify-content-end">
                                              {detail.products.length > 1 && (
                                                <i
                                                  onClick={() =>
                                                    removeProduct(
                                                      takeawayIndex,
                                                      detailIndex,
                                                      productIndex,
                                                    )
                                                  }
                                                  className="bi bi-dash-circle remove_icon"
                                                ></i>
                                              )}
                                            </div>
                                            <div className="col-12 col-sm-8 col-md-10 mb-2">
                                              <Select
                                                name={`Takeaway[${takeawayIndex}].substituteDetails[${detailIndex}].products[${productIndex}].type`}
                                                value={
                                                  product.type === ""
                                                    ? null
                                                    : List.find(
                                                        (item: {
                                                          value: string;
                                                        }) =>
                                                          item.value ===
                                                          product.type,
                                                      )
                                                }
                                                onChange={(option) => {
                                                  formik.setFieldValue(
                                                    `Takeaway[${takeawayIndex}].substituteDetails[${detailIndex}].products[${productIndex}].type`,
                                                    option?.value || "",
                                                  );
                                                }}
                                                defaultOptions={List}
                                                placeholder="Product Type"
                                                options={List}
                                                data={List}
                                              />
                                              {formik.errors.Takeaway?.[
                                                takeawayIndex //@ts-expect-error
                                              ]?.substituteDetails?.[
                                                detailIndex
                                              ]?.products?.[productIndex]
                                                ?.type &&
                                                formik.touched.Takeaway?.[
                                                  takeawayIndex
                                                ]?.substituteDetails?.[
                                                  detailIndex
                                                ]?.products?.[productIndex]
                                                  ?.type && (
                                                  <div className="text-danger">
                                                    {
                                                      formik.errors.Takeaway[
                                                        takeawayIndex //@ts-expect-error
                                                      ]?.substituteDetails[
                                                        detailIndex
                                                      ]?.products[productIndex]
                                                        ?.type
                                                    }
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}

                                    {takeaway.title &&
                                      detail.products.every(
                                        (p) => p.productCode,
                                      ) && (
                                        <div className="d-flex gap-3 justify-content-end">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              addProduct(
                                                takeawayIndex,
                                                detailIndex,
                                              )
                                            }
                                            className="btn btn-outline-success"
                                          >
                                            Add Product
                                          </button>

                                          {takeaway.substituteDetails.length -
                                            1 ===
                                            detailIndex && (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                addSubstituteDetail(
                                                  takeawayIndex,
                                                )
                                              }
                                              className="btn btn-outline-success"
                                            >
                                              Add Sub-Title
                                            </button>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end gap-2 mb-3">
                            {takeawayIndex > 0 && (
                              <div className="d-flex">
                                <button
                                  type="button"
                                  onClick={() => removeTakeaway(takeawayIndex)}
                                  className="btn btn-outline-danger"
                                >
                                  Remove Takeaway
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      {formik.values.Takeaway.every(
                        (takeaway) =>
                          takeaway.title && // Check if title has a value
                          takeaway.substituteDetails.every(
                            (detail) =>
                              detail.products.every(
                                (product) => product.productCode,
                              ), // Check if productCode has a value
                          ),
                      ) && (
                        <div className="mb-2 d-flex justify-content-end">
                          <button
                            onClick={addTakeaway}
                            className="btn btn-outline-success"
                          >
                            Add Takeaway
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  {"Product Banner"}
                </h4>
                <div className="new_category_banner_inner">
                  <div className="new_category_banner_content">
                    <div>
                      <i className="bi bi-arrow-up-circle upload_icon"></i>
                    </div>
                    <div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*,audio/*,video/*"
                        style={{ display: "none" }}
                        onChange={handleFileInputChangeAndUpload}
                        name="imageURL"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={handleButtonClick}
                      >
                        Add Image
                      </button>
                    </div>
                    <div>
                      <span className="upload_img_text">
                        {"Click to upload an image"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {typeof formik?.values?.imageURL === "string" &&
                formik?.values?.imageURL && (
                  <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                    <h4 className="theme_color fw-bold mb-3 align-self-center">
                      {"Preview Image"}
                    </h4>
                    <div className="new_category_banner_inner d-block">
                      <div className="new_category_banner_content h-100 p-2">
                        <div className="image-container-preview">
                          <img
                            src={formik?.values?.imageURL}
                            alt="img"
                            className="image-preview"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              <div className="border border-grey p-3 rounded mt-4 white_bg">
                <h4 className="theme_color fw-bold mb-3 align-self-center">
                  ARCH Status
                </h4>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="itemStatus"
                    id="flexRadioDefault1"
                    value="disabled"
                    checked={!formik.values.itemStatus}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "itemStatus",
                        !formik.values.itemStatus,
                      );
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Disabled
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="itemStatus"
                    id="flexRadioDefault2"
                    value="enabled"
                    checked={formik.values.itemStatus}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "itemStatus",
                        !formik.values.itemStatus,
                      );
                    }}
                  />
                  <label
                    className="form-check-label theme_color"
                    htmlFor="flexRadioDefault2"
                  >
                    Enabled
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TakeAwayUpdateProducts;
