import { StylesConfig } from "react-select";

interface FormValues {
  instruction: string;
  [key: string]: string;
}

const customStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    zIndex: 99,
    backgroundColor: state.isFocused ? "#54634B" : "rgba(242, 225, 206, 0.2)",
    color: state.isFocused ? "#FCF9F5" : "#54634B",
    "&:hover": {
      backgroundColor: "#54634B",
      color: "#FCF9F5",
    },
  }),
};

const options = [
  { value: "Vegan", label: "Vegan" },
  { value: "Roast", label: "Roast" },
];

const inputfield = {
  description: "",
  recpies_code: "",
  difficulty_level: "",
  recipe_title: "",
  author_chef: "",
  total_time: "",
  prep_time: "",
  cooking_time: "",
  recipeName: "",
  imageURL: "",
};

const steps = [
  "recpies_code",
  "difficulty_level",
  "recipe_title",
  "author_chef",
  "total_time",
  "prep_time",
  "cooking_time",
];

export { customStyles, options, inputfield, steps };
export type { FormValues };

export const RecipiesResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      ...val._source,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};
