import { Link, useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { columns_name } from "./data";
import { Routers } from "../../Routes/Routes";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { updateStore } from "../../Api/Store/mutation";
import swal from "sweetalert";
import debounce from "lodash.debounce";
import { searchStore } from "../../Api/Search/Store/Store";
import { StoreResponse } from "./utils";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import {} from "react-toastify";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import moment from "moment";
type SearchData = {
  fields: string;
  value: string;
};
const Store = () => {
  const { alert, message } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [storelist, setStore] = useState<any>([]);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const { updateRefetchStoreList, updateStoreId, storeId, refetchStoreList } =
    useStoreId();

  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });
  // eslint-disable-next-line
  const getStorelist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchStore("store", filtervalue, Pagination)
        .then(async (res) => {
          const result = await StoreResponse(res);
          setStore(result?.modifiedData);
          setTotal(result?.total);
          updateRefetchStoreList(!refetchStoreList);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }, 3000),
    [],
  );

  useEffect(() => {
    getStorelist(filtervalue, Pagination);
  }, [getStorelist, filtervalue, Pagination]);

  const navigate = useNavigate();

  const handleEdit = (data: { SK: string; PK: string }) => {
    navigate(
      `${Routers.updateStore
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))}`,
    );
  };

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Store ${data?.name}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      const newData = {
        ...data,
        storePolygon: JSON.stringify(data?.storePolygon),
        isDeleted: getStatus(data?.isDeleted) === "Disable" ? true : false,
        updatedDate: moment(data?.updatedDate).toISOString(),
      };
      updateStore(newData, data?.PK, data?.SK)
        .then((response) => {
          const result = JSON.parse(response?.data?.updateStore);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            if (storeId === data?.storeCode) {
              updateStoreId("");
            }
            updateRefetchStoreList(!refetchStoreList);
            swal(
              getStatus(data?.isDeleted),
              `Store has been ${getStatus(data?.isDeleted)} successfully`,
              "success",
            );
            getStorelist(filtervalue, Pagination);
          } else {
            swal(errorMessage);
          }
        })
        .catch((err) =>
          swal("Failed to disable Store. Please try again later."),
        );
    }
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  return (
    <div>
      {alert && message && <Toast type="success" message={message} />}
      <div className="row my-4">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Store"}</h3>
        </div>
        <div className="col-auto ms-auto">
          <Link className="text-decoration-none" to={Routers?.newstore}>
            <button className="btn btn-outline-success " type="button">
              {"New Store"}
            </button>
          </Link>
        </div>
      </div>

      <div className="border p-4  border-grey  rounded  white_bg">
        <Table
          columns={columns_name}
          data={storelist || []}
          paginationShow={true}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isLoading={isLoading}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
          filterdata={filterdata}
        ></Table>
      </div>
    </div>
  );
};

export default Store;
