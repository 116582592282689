import { useLocation } from "react-router-dom";
import Layout from "./Layout";
import { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
}
const LayoutHandle: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  const handle = () => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/forgotpassword"
    ) {
      return null;
    } else {
      return <Layout children={children} />;
    }
  };

  return <div>{handle()}</div>;
};

export default LayoutHandle;
