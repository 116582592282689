import React, { useEffect, useState } from "react";
import Select from "../../Atoms/Select"; // Assuming this is your dropdown component
import { List } from "./utils";
import UpdateProducts from "../Products/UpdateProducts";
import TakeAwayUpdateProducts from "../TakeAwayProducts/UpdateProducts";
import UpdateExtraTakeAwayProducts from "../ExtraTakeAwayProducts/UpdateProducts";
import UpdateBothProducts from "./UpdateBothProducts";
import { useParams } from "react-router-dom";

const UpdateProductManipulation: React.FC = () => {
  const [productType, setProductType] = useState<string>("");

  const { productType: type } = useParams<{ productType: string }>();

  useEffect(() => {
    if (type) {
      setProductType(type);
    }
  }, [type]);

  const handleSelectChange = (val: { id: string } | null) => {
    setProductType(val ? val.id : "");
  };

  const renderProductUpdateComponent = () => {
    switch (productType) {
      case "STORE":
        return <UpdateProducts />;
      case "MEAL":
        return <TakeAwayUpdateProducts />;
      case "EXTRATAKEAWAY":
        return <UpdateExtraTakeAwayProducts />;
      case "BOTH":
        return <UpdateBothProducts />;
      default:
        return (
          <p className="text-muted">
            Please select a product type to view options.
          </p>
        );
    }
  };

  return (
    <div
      className="border border-grey rounded white_bg"
      style={{ padding: "1rem", marginTop: "1rem" }}
    >
      <h4 className="theme_color fw-bold align-self-center mb-3">
        Select Product Type
      </h4>
      <div
        className="form-group col-12"
        style={{ marginBottom: "2rem", position: "relative" }}
      >
        <Select
          cacheOptions={true}
          defaultOptions={List}
          onChange={handleSelectChange}
          data={List}
          value={List.find((item) => item.id === productType) || null}
          customStyleApply={true}
        />
      </div>
      <div style={{ marginTop: "2rem" }}>{renderProductUpdateComponent()}</div>
    </div>
  );
};

export default UpdateProductManipulation;
