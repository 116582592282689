import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { selectedKeys } from "./data";
import ReactQuill from "react-quill";
import { useAlert } from "../ContextAPI/ContextApi";
import "react-toastify/dist/ReactToastify.css";
import { addProduct } from "../../Api/Product/mutations";
import {
  formattedDate,
  generateUUID,
  handleFileUpload,
  isValidJSON,
} from "../../Common Functions/Function";
import { useFormik } from "formik";
import validationSchema from "./validation";
import ImageLoader from "../../Effects/ImageLoader";
import { TagsInput } from "react-tag-input-component";
import {
  getAllCategory,
  getAllCategoryById,
} from "../../Api/Search/Category/Category";
import { categoryResponse } from "../Categories/utils";
import { ProductResponse, ProductResponseMATCHSKU } from "./utils";
import { getAllProducts } from "../../Api/Search/Product/Product";
import { searchWithPKSK } from "../../Api/Search/mutation";
import Select from "../../Atoms/Select";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { generateClient } from "aws-amplify/api";
import { PRODUCT_SK_MATCH } from "../../Api/Product/query";
import DataLoader from "../../Effects/DataLoader";
import Toast from "../Toast/Toast";
import { toast } from "react-toastify";
import LoadingOverlay from "../../Effects/LoadingOverlay";

const NewProduct = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loader, setLoader] = useState(false);
  const [parentItems, setParentItems] = useState<any>([]);
  const [subItems, setSubItems] = useState<any>([]);
  const navigate = useNavigate();
  const { setAlert, setMessage } = useAlert();
  const [saveLoader, setsaveLoader] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [sortKey, setsortKey] = useState("");
  const [product, setProduct] = useState([]);
  const [productData, setproductData] = useState<any>();
  const [skuMatchProductData, setSkuMatchProductData] = useState<any>();
  const { storeId } = useStoreId();
  const [skuLoader, setskuLoader] = useState(false);
  const [formData, setFormData] = useState<any>({});
  let index = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product";
  const { alert, message, errorType, setErrorType } = useAlert();

  const formik = useFormik({
    initialValues: {
      itemName: "",
      sku: "",
      subcategoryId: "",
      categoryId: "",
      categorySK: "",
      subcategorySK: "",
      description: "",
      productBrand: "",
      productSize: "",
      itemStatus: true,
      salesPrice: "",
      packWeightGrams: "",
      packSizeInmm: "",
      localInternational: "",
      halaalKosher: "",
      vegetarianVegan: "",
      onlinePicture: false,
      glutenFree: false,
      alcoholic: false,
      imported: false,
      imageURL: "",
      costPrice: "",
      packSizeBreadth: "",
      packSizeHeight: "",
      packSizeLength: "",
      storeId: storeId,
      tags: [],
      entityName: index,
      isDeleted: `false`,
      createdDate: formattedDate(),
      updatedDate: formattedDate(),
      productType: "STORE",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setsaveLoader(true);
      const UUID = generateUUID();
      let val = previous ? sortKey : `ACTIVE#${storeId}#${values.sku}`;

      const newData: FormData = {
        extraFieldsProduct: {},
        PK: "PRODUCT",
        SK: val,
        productCode: UUID,
        id: UUID,
      };

      for (const key in values) {
        if (selectedKeys.includes(key)) {
          //@ts-expect-error
          const value = values[key];
          newData.extraFieldsProduct[key] = value;
        } else {
          //@ts-expect-error
          newData[key] = values[key];
        }
      }
      const mergeObjects = (obj1: object, obj2: object) => {
        return { ...obj1, ...obj2 };
      };
      const mergedProductData = mergeObjects(
        formData,
        newData.extraFieldsProduct,
      );
      const vals = JSON.stringify(mergedProductData);
      newData.extraFieldsProduct = vals;

      await addProduct(newData)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.createProduct);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.products);
            setAlert(true);
            setErrorType("success");
            setMessage("Product Added Successfully");
            return;
          } else {
            setErrorType("error");
            setAlert(true);
            setMessage(errorMessage);
          }
          setsaveLoader(false);
        })
        .catch((err) => {
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
          setsaveLoader(false);
        });
    },
  });

  const getCategorylist = useCallback(async () => {
    await getAllCategory("", "category", "AIL", storeId)
      .then(async (res) => {
        const result = await categoryResponse(res);
        setParentItems(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getCategorylist();
  }, [getCategorylist]);

  useEffect(() => {
    const fetchData = async () => {
      if (formik?.values?.categoryId) {
        await getAllCategoryById(
          "",
          "subcategory",
          "",
          formik?.values?.categoryId,
          storeId,
        )
          .then((res) => {
            const result = categoryResponse(res);
            setSubItems(result?.modifiedData);
          })
          .catch((error) => {
            console.error("Error fetching categories:", error);
          });
      }
    };

    fetchData();
  }, [formik?.values?.categoryId, storeId]);

  interface FormData {
    [key: string]: any;
  }

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    await handleFileUpload(
      event,
      setLoader,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage,
    );
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleSubmit = () => {
    if (storeId === "") {
      setAlert(true);
      setMessage("First select a store");
      setErrorType("error");
      return;
    }

    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const getProductlist = useCallback(async () => {
    await getAllProducts("", "product", storeId)
      .then(async (res) => {
        const result = await ProductResponse(res);
        setProduct(result?.modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [storeId]);

  useEffect(() => {
    getProductlist();
  }, [getProductlist]);

  const getProduct = useCallback(async (data: any) => {
    const selectedItem = isValidJSON(data) ? JSON.parse(data) : data;
    setsortKey(selectedItem?.SK);
    await searchWithPKSK("product", selectedItem?.PK, selectedItem?.SK)
      .then((res) => {
        const result = ProductResponse(res);
        setproductData(result?.modifiedData[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (productData) {
      const parsedVariants = productData.extraFieldsProduct;
      // const parsedVariant = JSON.parse(parsedVariant);
      formik.setValues({
        ...formik.values,
        itemName: productData.itemName || "",
        sku: productData.sku || "",
        tags: productData.tags || [],
        description: productData.description || "",
        productBrand: parsedVariants?.productBrand || "",
        productSize: parsedVariants?.productSize || "",
        itemStatus: isValidJSON(productData?.itemStatus)
          ? JSON.parse(productData?.itemStatus)
          : productData?.itemStatus,
        salesPrice: parsedVariants?.salesPrice || "",
        packWeightGrams: parsedVariants?.packWeightGrams || "",
        packSizeInmm: parsedVariants?.packSizeInmm || "",
        localInternational: parsedVariants?.localInternational || "",
        halaalKosher: parsedVariants?.halaalKosher || "",
        vegetarianVegan: parsedVariants?.vegetarianVegan || "",
        glutenFree: parsedVariants?.glutenFree || "",
        alcoholic: parsedVariants?.alcoholic || "",
        imported: parsedVariants?.imported || "",
        packSizeBreadth: parsedVariants?.packSizeBreadth || "",
        packSizeHeight: parsedVariants?.packSizeHeight || "",
        packSizeLength: parsedVariants?.packSizeLength || "",
        costPrice: parsedVariants?.costPrice || "",
        imageURL: productData?.imageURL || "",
        storeId: productData?.storeId || "",
      });
      setFormData({
        ...formData,
        parsedVariants,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  const handleCategory = (data: any) => {
    let val = isValidJSON(data) ? JSON.parse(data) : data;
    formik.setFieldValue("categorySK", val.SK);
    formik.setFieldValue("categoryId", val.id);
    formik.setFieldValue("subcategorySK", "");
    formik.setFieldValue("subcategoryId", "");
  };

  const handleSubCategory = (data: any) => {
    let val = isValidJSON(data) ? JSON.parse(data) : data;
    formik.setFieldValue("subcategorySK", val.SK);
    formik.setFieldValue("subcategoryId", val.id);
  };

  // Step 2: Create a handler function
  // const handleOptionChange = (event: {
  //   target: { value: SetStateAction<string> };
  // }) => {
  //   setSelectedOption(event.target.value);
  // };

  const client = generateClient();

  const SKUMatchedData = useCallback(async () => {
    setskuLoader(true);
    let SKU = `ACTIVE#${formik?.values?.sku?.trim()}`;
    if (SKU) {
      try {
        const response = await client.graphql({
          query: PRODUCT_SK_MATCH,
          variables: { SKU },
        });

        //@ts-expect-error
        const res = isValidJSON(response?.data?.getProductStockBySku) //@ts-expect-error
          ? JSON.parse(response?.data?.getProductStockBySku) //@ts-expect-error
          : response?.data?.getProductStockBySku;
        if (res?.statusCode !== 404) {
          const data = isValidJSON(res?.body)
            ? JSON.parse(res?.body)
            : res?.body;
          setMessage("Product Match Successfully");
          const result = ProductResponseMATCHSKU(data?.Items);
          setskuLoader(false);

          setSkuMatchProductData(result?.modifiedData[0]);
          if (result?.modifiedData[0]?.extraFieldsProductStock !== undefined) {
            setFormData(result?.modifiedData[0]?.extraFieldsProductStock);
          }
        }
        setskuLoader(false);
      } catch (error) {
        //@ts-expect-error
        toast.error(error?.errors[0].message);
        setskuLoader(false);
        return error;
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.sku]);

  useEffect(() => {
    if (skuMatchProductData) {
      formik.setValues({
        ...formik.values,
        itemName: skuMatchProductData?.description,
        description:
          skuMatchProductData?.extraFieldsProductStock?.fullDescription,
        productBrand: skuMatchProductData?.extraFieldsProductStock?.brandName,
        packSizeBreadth: skuMatchProductData?.extraFieldsProductStock?.width,
        packSizeHeight: skuMatchProductData?.extraFieldsProductStock?.height,
        costPrice:
          skuMatchProductData?.extraFieldsProductStock?.sellingPriceIncl,
        salesPrice:
          skuMatchProductData?.extraFieldsProductStock?.sellingPriceInclPrice1,
        packWeightGrams:
          skuMatchProductData?.extraFieldsProductStock?.packUnitSize,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuMatchProductData]);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const formatLabel = (key: string) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const renderExtraFields = () => {
    return Object.keys(skuMatchProductData?.extraFieldsProductStock).map(
      (key) => (
        <div className="mb-3 col-md-4" key={key}>
          <label htmlFor={key} className="form-label">
            {formatLabel(key)}
          </label>
          <input
            type="text"
            className="form-control"
            id={key}
            name={key}
            placeholder={key}
            onChange={handleChange}
            value={formData[key]}
          />
        </div>
      ),
    );
  };

  // const handleBlur = (event: any, field: string) => {
  //   const value = parseFloat(event.target.value);
  //   formik.setFieldValue(field, value.toFixed(2));
  // };

  const handleBlur = (event: any, field: string) => {
    let value = event.target.value;
    const cleanedValue = value.replace(/[^\d.]/g, "");
    // const [integerPart, decimalPart] = cleanedValue.split(".");
    // if (decimalPart && decimalPart.length > 2) {
    //   value = `${integerPart}.${decimalPart.substring(0, 2)}`;
    // } else {
    //   value = cleanedValue;
    // }
    formik.setFieldValue(field, cleanedValue);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {loader && <ImageLoader />}
        {skuLoader && <DataLoader text="Loading...." />}
        <LoadingOverlay isVisible={saveLoader} message="Please wait..." />
        {alert && message && (
          <Toast type={errorType ? errorType : "success"} message={message} />
        )}
        <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
          <div className="heading-title mt-2 mt-md-0">
            <h4 className="theme_color fw-bolder">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.products}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              Create A New Product
            </h4>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Link className="text-decoration-none" to={Routers?.products}>
              <button className="btn btn-outline-danger" type="button">
                Cancel
              </button>
            </Link>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={handleSubmit}
              disabled={loader}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                  <h4 className="theme_color fw-bold align-self-center mb-3">
                    {"General"}
                  </h4>
                  <div className="row">
                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label`}
                      >
                        Product Code<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="sku"
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          SKUMatchedData();
                        }}
                        value={formik.values?.sku}
                      />
                      {formik.touched.sku && formik.errors.sku ? (
                        <div className="text-danger">{formik.errors.sku}</div>
                      ) : null}
                    </div>
                    <div className={`form-group row mb-3`}>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="description1" className={`form-label`}>
                          Category<span className="text-danger">*</span>
                        </label>

                        <Select
                          cacheOptions={true}
                          onChange={handleCategory}
                          defaultOptions={parentItems}
                          data={parentItems}
                          value={parentItems.find(
                            (item: { SK: string }) =>
                              item?.SK === formik?.values.categorySK,
                          )}
                        />
                        {formik.touched.categoryId &&
                          formik.errors.categoryId && (
                            <div className="text-danger">
                              {formik.errors.categoryId}
                            </div>
                          )}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="description2" className={`form-label`}>
                          Sub-Category<span className="text-danger">*</span>
                        </label>

                        <Select
                          onChange={handleSubCategory}
                          defaultOptions={subItems}
                          data={subItems}
                          value={
                            formik?.values?.subcategorySK === ""
                              ? null
                              : subItems.find(
                                  (item: { SK: string }) =>
                                    item?.SK === formik?.values?.subcategorySK,
                                )
                          }
                        />
                        {formik.touched.subcategoryId &&
                          formik.errors.subcategoryId && (
                            <div className="text-danger">
                              {formik.errors.subcategoryId}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-check d-flex gap-2 mb-3 justify-content-end">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheckbox"
                          onChange={(e) => setPrevious(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheckbox"
                        >
                          Add Existing Product
                        </label>
                      </div>
                      {previous && (
                        <div className="mb-3 col-md-12">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className={`form-label`}
                          >
                            Existing Product
                          </label>
                          {/* <select
                            name="subcategoryId"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(event) => getProduct(event.target.value)}
                          >
                            <option value="">Select...</option>
                            {product?.map(
                              (
                                item: { id: string; name: string },
                                key: number // Assuming item has an id property
                              ) => (
                                <option value={JSON.stringify(item)} key={key}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select> */}

                          <Select
                            cacheOptions={true}
                            onChange={(e) => {
                              getProduct(e);
                            }}
                            defaultOptions={product}
                            data={product}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label`}
                      >
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="itemName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.itemName}
                      />
                      {formik.touched.itemName && formik.errors.itemName ? (
                        <div className="text-danger">
                          {formik.errors.itemName}
                        </div>
                      ) : null}
                    </div>

                    <div className="row">
                      <div className={`form-group col-12 mb-3`}>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Tags
                        </label>
                        <div className="form-group col-12">
                          <TagsInput
                            name="tags"
                            onChange={(tags) =>
                              formik.handleChange({
                                target: { name: "tags", value: tags },
                              })
                            }
                            value={formik.values.tags}
                          />
                          <small className="form-text text-muted">
                            <i className="bi bi-info-circle"></i> Press Enter
                            after typing your tag to add it.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Description
                        </label>
                        <ReactQuill
                          theme="snow"
                          onChange={(content, delta, source, editor) => {
                            formik.setFieldValue(
                              "description",
                              editor.getHTML(),
                            );
                          }}
                          value={formik.values.description}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6] }],
                              ["bold", "italic", "link"],
                              [{ list: "ordered" }],
                              ["blockquote"],
                            ],
                          }}
                        />
                        {/* {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="text-danger">
                            {formik.errors.description}
                          </div>
                        ) : null} */}
                      </div>
                    </div>

                    {/* <div className="d-flex mb-4 gap-2 align-items-center">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          checked={selectedOption === "option1"}
                          onChange={handleOptionChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Take-Away
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          disabled
                          value="option2"
                          checked={selectedOption === "option2"}
                          onChange={handleOptionChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          Meal-kit
                        </label>
                      </div>

                      {selectedOption && (
                        <>
                          <i
                            className="bi bi-x-circle"
                            onClick={() => setSelectedOption("")}
                          ></i>
                        </>
                      )}
                      {selectedOption && (
                        <div className=" col-md-6">
                          <select
                            className="form-select w-100"
                            aria-label="Default select example"
                          >
                            <option>Select...</option>
                            <option value={1}>Pizza</option>
                            <option value={2}>Burger</option>
                          </select>
                        </div>
                      )}
                    </div> */}

                    <div className={`form-group row mb-3`}>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="productBrand" className={`form-label`}>
                          Product Brand
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="productBrand"
                          name="productBrand"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.productBrand}
                        />
                        {formik.touched.productBrand &&
                        formik.errors.productBrand ? (
                          <div className="text-danger">
                            {formik.errors.productBrand}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="productSize" className="form-label">
                          Product Size
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="productSize"
                            name="productSize"
                            min={0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.productSize}
                          />
                          <span className="input-group-text">size</span>
                        </div>
                        {formik.touched.productSize &&
                        formik.errors.productSize ? (
                          <div className="text-danger">
                            {formik.errors.productSize}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={`form-group row mb-3`}>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="costPrice" className={`form-label `}>
                          Cost Price<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="costPrice"
                            name="costPrice"
                            min={0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyUp={(e) => handleBlur(e, "costPrice")}
                            pattern="[0-9]*[.,]?[0-9]*"
                            value={formik.values?.costPrice}
                          />
                          <span className="input-group-text">ZAR</span>
                        </div>
                        {formik.touched.costPrice && formik.errors.costPrice ? (
                          <div className="text-danger">
                            {formik.errors.costPrice}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="salesPrice" className={`form-label`}>
                          Sales Price<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="salesPrice"
                            name="salesPrice"
                            min={0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyUp={(e) => handleBlur(e, "salesPrice")}
                            pattern="[0-9]*[.,]?[0-9]*"
                            value={formik.values?.salesPrice}
                          />

                          <span className="input-group-text">ZAR</span>
                        </div>
                        {formik.touched.salesPrice &&
                        formik.errors.salesPrice ? (
                          <div className="text-danger">
                            {formik.errors.salesPrice}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <div className="mb-3 col-md-12">
                        <label
                          htmlFor="packWeightGrams"
                          className={`form-label`}
                        >
                          Pack Weight Grams
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="packWeightGrams"
                            name="packWeightGrams"
                            min={0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.packWeightGrams}
                          />
                          {/* <span className="input-group-text">grams</span> */}
                        </div>
                        {formik.touched.packWeightGrams &&
                        formik.errors.packWeightGrams ? (
                          <div className="text-danger">
                            {formik.errors.packWeightGrams}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <div className="mb-3 col-md-12">
                        <label htmlFor="packSizeInmm" className={`form-label `}>
                          Pack Size in mm
                        </label>
                        <div className="row">
                          <div className="mb-3 col-md-4">
                            <label
                              htmlFor="packSizeLength"
                              className={`form-label `}
                            >
                              Length
                              {/* <span className="text-danger">*</span> */}
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="packSizeLength"
                              name="packSizeLength"
                              placeholder="Breadth"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onKeyUp={(e) => handleBlur(e, "packSizeLength")}
                              min={0}
                              value={formik.values?.packSizeLength}
                            />
                            {formik.touched.packSizeLength &&
                            formik.errors.packSizeLength ? (
                              <div className="text-danger">
                                {formik.errors.packSizeLength}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label
                              htmlFor="packSizeBreadth"
                              className={`form-label`}
                            >
                              Breadth
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="packSizeBreadth"
                              name="packSizeBreadth"
                              placeholder="Breadth"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onKeyUp={(e) => handleBlur(e, "packSizeBreadth")}
                              min={0}
                              value={formik.values?.packSizeBreadth}
                            />
                            {formik.touched.packSizeBreadth &&
                            formik.errors.packSizeBreadth ? (
                              <div className="text-danger">
                                {formik.errors.packSizeBreadth}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label
                              htmlFor="packSizeHeight"
                              className={`form-label`}
                            >
                              Height
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="packSizeHeight"
                              name="packSizeHeight"
                              placeholder="Height"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onKeyUp={(e) => handleBlur(e, "packSizeHeight")}
                              min={0}
                              value={formik.values?.packSizeHeight}
                            />
                            {formik.touched.packSizeHeight &&
                            formik.errors.packSizeHeight ? (
                              <div className="text-danger">
                                {formik.errors.packSizeHeight}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`form-group row mb-3`}>
                      <div className="mb-3 col-md-6">
                        <label
                          htmlFor="localInternational"
                          className={`form-label`}
                        >
                          Local/International
                        </label>
                        <select
                          name="localInternational"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.localInternational}
                        >
                          <option>Select...</option>
                          <option value={1}>Local</option>
                          <option value={2}>International</option>
                        </select>
                        {formik.touched.localInternational &&
                        formik.errors.localInternational ? (
                          <div className="text-danger">
                            {formik.errors.localInternational}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="halaalKosher" className={`form-label`}>
                          Halaal/Kosher
                        </label>
                        <select
                          name="halaalKosher"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.halaalKosher}
                        >
                          <option>Select...</option>
                          <option value={1}>Halaal</option>
                          <option value={2}>Kosher</option>
                        </select>
                        {formik.touched.halaalKosher &&
                        formik.errors.halaalKosher ? (
                          <div className="text-danger">
                            {formik.errors.halaalKosher}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label`}
                      >
                        Vegetarian/Vegan
                      </label>
                      <select
                        name="vegetarianVegan"
                        className="form-select"
                        aria-label="Default select example"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.vegetarianVegan}
                      >
                        <option>Select...</option>
                        <option value={1}>Vegetarian</option>
                        <option value={2}>Vegan</option>
                      </select>
                      {formik.touched.vegetarianVegan &&
                      formik.errors.vegetarianVegan ? (
                        <div className="text-danger">
                          {formik.errors.vegetarianVegan}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {skuMatchProductData && formData && (
                  <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                    <h4 className="theme_color fw-bold align-self-center mb-3">
                      {"Additional Fields"}
                    </h4>
                    <div className="row">{renderExtraFields()}</div>
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    {"Product Banner"}
                  </h4>
                  <div className="new_category_banner_inner">
                    <div className="new_category_banner_content">
                      <div>
                        <i className="bi bi-arrow-up-circle upload_icon"></i>
                      </div>
                      <div>
                        <input
                          ref={fileInputRef}
                          type="file"
                          accept="image/*,audio/*,video/*"
                          style={{ display: "none" }}
                          onChange={handleFileInputChangeAndUpload}
                          name="imageURL"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-success"
                          onClick={handleButtonClick}
                        >
                          Add Image
                        </button>
                      </div>
                      <div>
                        <span className="upload_img_text">
                          {"Click to upload an image"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {typeof formik?.values?.imageURL === "string" &&
                  formik?.values?.imageURL && (
                    <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                      <h4 className="theme_color fw-bold mb-3 align-self-center">
                        {"Preview Image"}
                      </h4>
                      <div className="new_category_banner_inner d-block">
                        <div className="new_category_banner_content h-100 p-2">
                          <div className="image-container-preview">
                            <img
                              src={formik?.values?.imageURL}
                              alt="img"
                              className="image-preview"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="border border-grey p-3 rounded mt-4 white_bg">
                  <h4 className="theme_color fw-bold mb-3 align-self-center">
                    STATUS
                  </h4>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="itemStatus"
                      id="flexRadioDefault1"
                      value="disabled"
                      checked={!formik.values.itemStatus}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "itemStatus",
                          !formik.values.itemStatus,
                        );
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Disabled
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="itemStatus"
                      id="flexRadioDefault2"
                      value="enabled"
                      checked={formik.values.itemStatus}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "itemStatus",
                          !formik.values.itemStatus,
                        );
                      }}
                    />
                    <label
                      className="form-check-label theme_color"
                      htmlFor="flexRadioDefault2"
                    >
                      Enabled
                    </label>
                  </div>
                </div>

                <div className="border border-grey p-3 rounded mt-4 white_bg">
                  <div className={`form-group col-md-12 mb-2`}>
                    <div className="d-flex ">
                      <input
                        type={"checkbox"}
                        name={"glutenFree"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-check-input p-0 m-0 me-2"
                      />
                      <label className="form-label p-0 m-0">
                        {"Gluten Free"}
                      </label>
                    </div>
                  </div>
                  <div className={`form-group col-md-12 mb-2`}>
                    <div className="d-flex ">
                      <input
                        type={"checkbox"}
                        name={"alcoholic"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-check-input p-0 m-0 me-2"
                      />
                      <label className="form-label p-0 m-0">
                        {"Alcoholic"}
                      </label>
                    </div>
                  </div>
                  <div className={`form-group col-md-12`}>
                    <div className="d-flex ">
                      <input
                        type={"checkbox"}
                        name={"imported"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-check-input p-0 m-0 me-2"
                      />
                      <label className="form-label p-0 m-0">{"Imported"}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewProduct;
