import { generateClient } from "aws-amplify/api";
import { UPDATE_ORDER } from "./query";

const client = generateClient();

export const updateOrder = async (orderInput: any, PK: string, SK: string) => {
  try {
    const response = await client.graphql({
      query: UPDATE_ORDER,
      variables: { input: orderInput, PK, SK },
    });

    return response;
  } catch (error) {
    return error;
  }
};
