const DataLoader = (props: any) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 80,
        right: 20,
        zIndex: 9999,
        width: "250px",
        borderRadius: "5px",
        background: "#54634b", // Set the background color here
      }}
    >
      <div
        className="progress"
        style={{
          borderRadius: "5px",
          overflow: "hidden",
          height: "30px",
        }}
      >
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={{ width: `${100}%`, backgroundColor: "#54634b" }}
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <span className="visually-hidden">{props?.text}</span>
          {props?.text}
        </div>
      </div>
    </div>
  );
};

export default DataLoader;
