export const CREATE_RECIPES = `
mutation MyMutation ($input:recipeInput!) {
  createRecipe(input: $input) 
}
`;

export const UPDATE_RECIPES = ` 
mutation MyMutation ($input: recipeInput!, $PK: String!, $SK: String!){
  updateRecipe(input: $input, PK: $PK, SK: $SK) 
}
`;

export const DELETE_RECIPES = `
mutation MyMutation($PK: String!, $SK: String!) {
  deleteRecipe(PK: $PK, SK: $SK) 
}`;
