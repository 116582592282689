import * as Yup from "yup";
import countryData from "../../Data/CountryCode.json";

const getPhoneLength = (countryCode: string) => {
  const countryCodeWithoutPlus = countryCode?.replace(/^\+/, "");
  const country = countryData.find(
    (country) => country.phone === countryCodeWithoutPlus,
  );
  return country
    ? Array.isArray(country.phoneLength)
      ? country.phoneLength[0]
      : country.phoneLength
    : 0;
};

export const ProfileSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(50, "Name must not exceed 50 characters")
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Only numbers are allowed") // Ensure only numbers are allowed
    .matches(/^(?!.* {1,})/, "Spaces are not allowed") // Ensure no spaces are allowed
    .test("phone-length", "Invalid phone number length", function (value) {
      const countryCode = this.parent.country_code;
      const phoneLength = getPhoneLength(countryCode);
      if (phoneLength && phoneLength > 0) {
        return value ? value.toString().trim().length === phoneLength : true;
      }
      return true;
    })
    .required("Contact is required"),
});
