import { Link, useNavigate, useParams } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { formconfig } from "./data";
import Form from "../../Blocks/Form/Form";
import { useCallback, useEffect, useRef, useState } from "react";
import { updateStore } from "../../Api/Store/mutation";
import { useAlert } from "../ContextAPI/ContextApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormEffect from "../../Effects/FormEffect";
import { searchWithPKSK } from "../../Api/Search/mutation";
import { StoreResponse } from "./utils";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";

function UpdateStore() {
  const formRef = useRef<HTMLFormElement>(null);
  const { alert, message, setAlert, setMessage, setErrorType } = useAlert();
  const { PK, SK } = useParams();
  const [store, setStore] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const navigate = useNavigate();

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  const handleSubmit = async (formData: any) => {
    setUpdateLoader(true);
    try {
      const updatedStore = {
        ...formData,
        storePolygon: JSON.stringify(formData?.storePolygon),
      };
      const res = await updateStore(updatedStore, PK, SK);
      const result = JSON.parse(res?.data?.updateStore);
      const errorMessage = JSON.parse(result.body).message;
      if (result?.statusCode === 200) {
        navigate(Routers?.store);
        setAlert(true);
        setErrorType("success");
        setMessage("Store updated successfully");
      } else {
        setErrorType("error");
        setAlert(true);
        setMessage(errorMessage);
      }
    } catch (err: any) {
      setErrorType("error");
      setAlert(true);
      setMessage(err?.errors?.[0]?.message || "An error occurred");
    } finally {
      setUpdateLoader(false);
    }
  };

  const getStore = useCallback(() => {
    setIsLoading(true);
    searchWithPKSK("store", PK, SK)
      .then((res) => {
        if (res) {
          const result = StoreResponse(res);
          setStore(result?.modifiedData?.[0] || {});
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to fetch store data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [PK, SK]);

  useEffect(() => {
    getStore();
  }, [getStore]);

  return (
    <div>
      {alert && message && (
        <Toast type={alert ? "error" : "success"} message={message} />
      )}
      <LoadingOverlay isVisible={updateLoader} message="Please wait..." />

      <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title">
          <h4 className="theme_color fw-bolder">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.store}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            {"Update Store"}
          </h4>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Link className="text-decoration-none" to={Routers?.store}>
            <button className="btn btn-outline-danger" type="button">
              {"Cancel"}
            </button>
          </Link>
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={handleSave}
            disabled={updateLoader}
          >
            Save
          </button>
        </div>
      </div>
      {isLoading ? (
        <FormEffect />
      ) : (
        <Form
          formconfig={formconfig}
          onSubmit={handleSubmit}
          ref={formRef}
          inputfield={store}
        />
      )}
    </div>
  );
}

export default UpdateStore;
