import { useState } from "react";
import { ReactComponent as Search } from "../../../../Asset/search.svg";
import ReactApexChart from "react-apexcharts";
import { MenuShow, options, series } from "./data";

interface MenuProps {
  handler: (category: string) => void;
}
const Menu: React.FC<MenuProps> = ({ handler }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="menu_manager_menu_wraper">
      <div className="row mb-4 justify-content-between">
        <div className="col-auto ">
          <h3 className="theme_color fw-bolder m-0">{"Menu"}</h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => handler(MenuShow?.MENU_NEW)}
            type="button"
          >
            {"New Menu"}
          </button>
        </div>
      </div>{" "}
      <div className="mb-4 mt-4">
        <form className="d-flex">
          <div className="filter_wrap">
            <Search />
            <input
              type="search"
              className="form-control theme_color"
              aria-label="Sizing example input "
              aria-describedby="inputGroup-sizing-sm"
              placeholder="Search"
            />
          </div>
        </form>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr onClick={() => toggleAccordion()}>
              <td className="gap-2 d-flex">
                {isOpen ? (
                  <i className="bi bi-chevron-down "></i>
                ) : (
                  <i className="bi bi-chevron-right "></i>
                )}
                Name
              </td>
              <td width={270} className="text-nowrap">
                Menus Hours
              </td>
              <td width={270}>Categories</td>
              <td width={270}>Items</td>
            </tr>
          </thead>
          <tbody>
            {isOpen && (
              <tr>
                <td>Menu</td>
                <td>
                  Every day:{" "}
                  <span className="text-nowrap">12:00 AM - 11:59 PM </span>{" "}
                </td>
                <td>Pizzas, Snacks, Breads, Drinks</td>
                <td>31 delivered items, 31 pickup items</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="filter_wrap mt-4">
        <select className="form-select " aria-label="Default select example">
          <option selected>All menus</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
      <div>
        <ReactApexChart
          //@ts-expect-error
          options={options}
          series={series}
          type="heatmap"
          height={350}
        />
      </div>
    </div>
  );
};

export default Menu;
