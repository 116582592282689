const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "imageURL",
    label: "Media File",
    type: "img",
  },
  {
    field: "name",
    label: "Categories Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Categories Name",
    },
  },

  {
    field: "status",
    label: "Status",
    type: "icon",
    sub_header: {
      inputtype: "select",
    },
  },
  {
    field: "status",
    label: "Include In Menu",
    type: "yes",
    sub_header: {
      inputtype: "select",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const sub_columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "imageURL",
    label: "Media File",
    type: "img",
  },
  {
    field: "name",
    label: "Sub-Categories Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Sub-Categories Name",
    },
  },
  // {
  //   field: "categoryId",
  //   label: "Parent Categories Name",
  //   type: "text",
  //   sub_header: {
  //     inputtype: "input",
  //     placeholder: "Parent Categories Name",
  //   },
  // },

  {
    field: "status",
    label: "Status",
    type: "icon",
    sub_header: {
      inputtype: "select",
    },
  },
  {
    field: "status",
    label: "Include In Menu",
    type: "yes",
    sub_header: {
      inputtype: "select",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

interface Category {
  categoryId: string;
  storeType: string;
  tags: [];
  status: string;
  PK: string;
  SK: string;
  id: string;
  name: string;
  meta_title: string | null;
  imageURL: string;
  description: string;
  meta_description: string;
  meta_keywords: string;
  storeId: string;
  itemType: string;
  isDeleted: string;
}

interface SubCategoryInput {
  id: string;
  PK: string;
  SK: string;
  storeId: string;
  categoryId: string;
  categorySK: string;
  description: string;
  tags: [];
  entityName: string;
  storeType: string;
  name: string;
  imageURL: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  itemType: string;
  isDeleted: string;
}
export type { Category };
export { columns_name, sub_columns_name, type SubCategoryInput };
