export const GetRevenue = {
  size: 0,
  aggs: {
    last_week: {
      filter: {
        range: {
          createdDate: {
            gte: "now-1w/w",
            lt: "now",
          },
        },
      },
      aggs: {
        weekly_total: {
          sum: {
            field: "total",
          },
        },
        daily_data: {
          date_histogram: {
            field: "createdDate",
            calendar_interval: "day",
            time_zone: "Asia/Calcutta",
            min_doc_count: 0,
          },
          aggs: {
            daily_total: {
              sum: {
                field: "total",
              },
            },
          },
        },
      },
    },
    previous_week: {
      filter: {
        range: {
          createdDate: {
            gte: "now-2w/w",
            lt: "now-1w/w",
          },
        },
      },
      aggs: {
        weekly_total: {
          sum: {
            field: "total",
          },
        },
      },
    },
  },
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "actionLog.timestamp",
      format: "date_time",
    },
    {
      field: "createdDate",
      format: "date_time",
    },
    {
      field: "driverDetails.deliveryDate",
      format: "date_time",
    },
    {
      field: "driverDetails.expireTime",
      format: "date_time",
    },
    {
      field: "otpCreationTime",
      format: "date_time",
    },
    {
      field: "updatedDate",
      format: "date_time",
    },
  ],
  _source: {
    excludes: [],
  },
  query: {
    bool: {
      must: [],
      filter: [
        {
          match_all: {},
        },
      ],
      should: [],
      must_not: [],
    },
  },
};
export const GetNetProfit = {
  size: 0,
  aggs: {
    last_week: {
      filter: {
        range: {
          createdDate: {
            gte: "now-1w/w",
            lt: "now",
          },
        },
      },
      aggs: {
        weekly_total: {
          sum: {
            field: "orderProfit",
          },
        },
        daily_data: {
          date_histogram: {
            field: "createdDate",
            calendar_interval: "day",
            time_zone: "Asia/Calcutta",
            min_doc_count: 0,
          },
          aggs: {
            daily_total: {
              sum: {
                field: "orderProfit",
              },
            },
          },
        },
      },
    },
    previous_week: {
      filter: {
        range: {
          createdDate: {
            gte: "now-2w/w",
            lt: "now-1w/w",
          },
        },
      },
      aggs: {
        weekly_total: {
          sum: {
            field: "orderProfit",
          },
        },
      },
    },
  },
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "actionLog.timestamp",
      format: "date_time",
    },
    {
      field: "createdDate",
      format: "date_time",
    },
    {
      field: "driverDetails.deliveryDate",
      format: "date_time",
    },
    {
      field: "driverDetails.expireTime",
      format: "date_time",
    },
    {
      field: "otpCreationTime",
      format: "date_time",
    },
    {
      field: "updatedDate",
      format: "date_time",
    },
  ],
  _source: {
    excludes: [],
  },
  query: {
    bool: {
      must: [],
      filter: [
        {
          match_all: {},
        },
      ],
      should: [],
      must_not: [],
    },
  },
};

export const GetTrendingProducts = {
  size: 20,
  query: {
    match_all: {},
  },
  sort: [
    {
      trendingProduct: {
        order: "desc",
      },
    },
  ],
  _source: [
    "itemName",
    "imageURL",
    "extraFieldsProduct.sellingPriceInclPrice2",
    "trendingProduct",
  ],
};

export const GetProductStock = {
  size: 20,
  query: {
    bool: {
      must: [
        {
          range: {
            sellQuantity: {
              gt: 0,
            },
          },
        },
      ],
    },
  },
  sort: [
    {
      sellQuantity: {
        order: "desc",
      },
    },
  ],
  _source: ["productCode", "sellQuantity"],
};
