import React, { useState } from "react";
import MenuCategories from "./MenuCategories";
import MenuNewCategories from "./MenuNewCategories";
import { Categories } from "./data";

const Main = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    Categories.MENU_CATEGORIES,
  );

  const handler = (category: React.SetStateAction<string>) => {
    setSelectedCategory(category);
  };

  const renderCategory = () => {
    switch (selectedCategory) {
      case Categories.MENU_CATEGORIES:
        return <MenuCategories handler={handler} />;
      case Categories.MENU_NEW_CATEGORIES:
        return <MenuNewCategories handler={handler} />;
      default:
        <MenuNewCategories handler={handler} />;
    }
  };

  return <>{renderCategory()}</>;
};

export default Main;
