export const processOrderResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.productCode,
      ...val._source,
    };
  });

  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };

  return finalData;
};

export const processCustomerData = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.userId,
      ...val._source,
    };
  });

  const finalData = {
    modifiedData,
    total: data?.total?.value,
  };

  return finalData;
};

export const processDriverData = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.userId,
      ...val._source,
      value: val._source.userId,
      label: val._source.fullName,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};
