import { FormConfigItem } from "../../Blocks/Form/interface";

const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "messagesTitle",
    label: "Message Title",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Message Title",
    },
  },
  {
    field: "messagesBody",
    label: "Message Body",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Message Body",
    },
  },
];

const data = [
  {
    id: "1",
    code: "Item",
    status: "true",
  },
  {
    id: "2",
    code: "Item",
    status: "true",
  },
  {
    id: "3",
    code: "Item",
    status: "true",
  },
];

const formconfig: FormConfigItem[] = [
  {
    heading: "General",
    sub_header: [
      {
        label: "Message Code",
        fieldtype: "input",
        type: "text",
        size: "col-12",
        name: "message_code",
      },
      {
        label: "Description",
        fieldtype: "textarea",
        type: "text",
        size: "col-12",
        name: "description",
      },
      {
        label: "Date",
        fieldtype: "input",
        type: "date",
        size: "col-md-6",
        name: "date",
      },
      {
        label: "Time",
        fieldtype: "input",
        type: "time",
        size: "col-md-6",
        name: "time",
      },
      {
        label: "Member Only",
        fieldtype: "checkbox",
        type: "checkbox",
        size: "",
        name: "member_only",
      },
    ],
  },
  {
    heading: "Message Details  ",
    sub_header: [
      {
        label: "Message Title",
        fieldtype: "input",
        type: "text",
        size: "col-12",
        name: "message_title",
      },
      {
        label: "Message Body",
        fieldtype: "textarea",
        type: "text",
        size: "col-12",
        name: "message_body",
      },
    ],
  },
];

const inputfield: { [key: string]: string | boolean } = {
  message_code: "",
  description: "",
  date: "",
  time: "",
  member_only: false,
  message_title: "",
  message_body: "",
};

export { columns_name, data, formconfig, inputfield };
