import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Asset/logo.png";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import Spinner from "../../Effects/Spinner";

const ForgotPassword = () => {
  const [username, setusername] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmationCode, setCode] = useState("");
  const [error, seterror] = useState("");
  const [loading, setLoading] = useState(true);
  const [progress, setprogress] = useState(false);
  const [fieldEnable, setfieldEnable] = useState(false);

  const navgate = useNavigate();
  const location = useLocation();

  const forgotPassword = async () => {
    setprogress(true);
    try {
      await resetPassword({ username });
      seterror("");
      setprogress(false);
      setfieldEnable(true);
    } catch (error) {
      //@ts-expect-error
      const { message } = error;
      seterror(message);
      setprogress(false);
      setprogress(false);
    }
  };

  const forgotPasswordSubmit = async () => {
    try {
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      window.location.href = "/login";
    } catch (error) {
      //@ts-expect-error
      const { message } = error;
      seterror(message);
      setprogress(false);
    }
  };

  useEffect(() => {
    const userInfoString = localStorage.getItem("user-info");
    if (userInfoString) {
      const user = JSON.parse(userInfoString);
      if (user?.sub) {
        navgate("/");
      }
    } else {
      setLoading(false);
      console.log("Loged out");
    }
  }, [navgate, location.pathname]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="w-100">
        <div className="login_page_bg w-100">
          <div className=" forgot_page_card">
            <div className="d-flex justify-content-center forgot_page_logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="mb-2">
              <div>
                <label htmlFor="login-textfield" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="login-textfield"
                  onChange={(e) => setusername(e.target.value)}
                />
              </div>
            </div>
            {fieldEnable && (
              <>
                <div className="mb-2">
                  <div>
                    <label htmlFor="login-textfield" className="form-label">
                      Verification Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="login-textfield"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <div>
                    <label htmlFor="login-textfield" className="form-label">
                      New Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="login-textfield"
                      onChange={(e) => setnewPassword(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
            <div id="emailHelp" className="form-text text-danger p-2">
              {error}
            </div>
            <div className="mb-2">
              <button
                type="submit"
                onClick={() =>
                  fieldEnable ? forgotPasswordSubmit() : forgotPassword()
                }
                className="btn login_btn"
              >
                {progress ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : fieldEnable ? (
                  "Submit"
                ) : (
                  "Sent Code"
                )}
              </button>
            </div>
            <div className="p-2 d-flex justify-content-center">
              <Link to="/login" className="text-decoration-none text-black">
                Back to Sign In page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
