import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[^\s]/, "No spaces allowed at the start")
    .matches(/^(?!.* {2,})/, "More than one space is not allowed")
    .matches(/^(?!.* $)/, "Spaces are not allowed at the end")
    .matches(/^(?!All$)/i, '"All" is not allowed')
    .min(3, "Name must be at least 3 characters")
    .max(100, "Name must be at most 50 characters"),
  categorySK: Yup.string().required("Parent category is required"),
  itemType: Yup.string().required("Category  Type is required"),
});

export default validationSchema;
