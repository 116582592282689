import axios from "axios";
import { getAuthToken } from "../utils";

interface SearchData {
  fields: string;
  value: string | { from: string; to: string };
}

interface Pagination {
  from: number;
  size: number;
}

interface Pagination {
  from: number;
  size: number;
}

export const searchRecipies = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object") {
        const rangeQuery: any = {};
        if (item.value.from !== "") {
          rangeQuery["gte"] = item.value.from.trim();
        }
        if (item.value.to !== "") {
          rangeQuery["lte"] = item.value.to.trim();
        }
        return {
          range: {
            [item.fields]: rangeQuery,
          },
        };
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value.trim()}*`,
          },
        };
      }
    });
  let val = { term: { storeId: storeId } };

  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [val],
      },
    },
  };
  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

interface SubstituteDetail {
  qty: string;
  subcategoryId: string;
  productCode: string;
  sku: string;
  categoryId: string;
  productdata?: any; // Define proper type for productdata
}

interface Ingredient {
  qty: string;
  subcategoryId: string;
  substituteDetails: SubstituteDetail[];
  productCode: string;
  sku: string;
  categoryId: string;
  productdata?: any; // Define proper type for productdata
}

interface Recipe {
  categoryId: string;
  subcategoryId: string;
  storeType: string;
  description: string;
  recipeDetail: {
    recipe_title: string;
    author_chef: string;
    recpies_code: string;
    difficulty_level: string;
    prep_time: string;
    cooking_time: string;
    total_time: string;
    steps: string[];
  };
  updatedDate: string;
  storeId: string;
  tags: string[];
  recipeName: string;
  createdDate: string;
  isDeleted: boolean;
  entityName: string;
  imageUrl: string[];
  SK: string;
  ingredients: {
    ingredientsDetail: Ingredient[];
  };
  PK: string;
  id: string;
}

export const searchRecipiesByPKSK = async ({
  PK,
  SK,
  storeId,
}: {
  PK: string | undefined;
  SK: string | undefined;
  storeId: string | null;
}): Promise<Recipe[]> => {
  const recipeIndex = process.env.REACT_APP_STACK
    ? `recipe${process.env.REACT_APP_STACK}`
    : "recipe";

  const productIndex = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product";

  const query = {
    size: 1000,
    query: {
      bool: {
        must: [{ term: { "PK.keyword": PK } }, { term: { "SK.keyword": SK } }],
        filter: [{ match: { storeId: storeId } }],
      },
    },
  };

  try {
    // Fetch recipes
    const response = await axios.post(
      `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${recipeIndex}/_search`,
      query,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAuthToken(),
        },
      },
    );

    const recipeHits = response.data?.hits?.hits || [];
    const productCodes = new Set<string>();

    // Collect all product codes
    recipeHits.forEach(
      (recipeHit: {
        _source: { ingredients: { ingredientsDetail: any[] } };
      }) => {
        const ingredients =
          recipeHit._source.ingredients.ingredientsDetail || [];
        ingredients.forEach(
          (ingredient: { productCode: string; substituteDetails: any[] }) => {
            productCodes.add(ingredient.productCode);
            ingredient.substituteDetails.forEach(
              (substitute: { productCode: string }) => {
                productCodes.add(substitute.productCode);
              },
            );
          },
        );
      },
    );

    const uniqueProductCodes = Array.from(productCodes);
    const maxBatchSize = 20;
    const numBatches = Math.ceil(uniqueProductCodes.length / maxBatchSize);
    const productDataQueries = [];

    // Build queries in ndjson format
    for (let i = 0; i < numBatches; i++) {
      const batchProductCodes = uniqueProductCodes.slice(
        i * maxBatchSize,
        (i + 1) * maxBatchSize,
      );
      const batchQuery =
        batchProductCodes
          .map(
            (code) => `{"index":"${productIndex}"}
{"query":{"term":{"productCode":"${code}"}}}`,
          )
          .join("\n") + "\n";

      productDataQueries.push(batchQuery);
    }

    // Execute all queries in parallel
    const productDataPromises = productDataQueries.map(async (query) =>
      axios.post(
        `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/_msearch`,
        query,
        {
          headers: {
            "Content-Type": "application/x-ndjson",
            Authorization: await getAuthToken(),
          },
        },
      ),
    );
    const productDataResponses = await Promise.all(productDataPromises);

    const productData = productDataResponses.flatMap((response) =>
      response.data.responses.flatMap((res: { hits: { hits: any[] } }) =>
        res.hits.hits.map((hit: { _source: any }) => hit._source),
      ),
    );
    const productDataMap = new Map(
      productData.map((item) => [item.productCode, item]),
    );
    const enrichedRecipes = recipeHits.map((recipeHit: { _source: Recipe }) => {
      const recipe: Recipe = recipeHit._source;
      if (recipe.ingredients && recipe.ingredients.ingredientsDetail) {
        recipe.ingredients.ingredientsDetail =
          recipe.ingredients.ingredientsDetail.map((ingredient) => {
            ingredient.productdata =
              productDataMap.get(ingredient.productCode) || null;
            ingredient.substituteDetails = ingredient.substituteDetails.map(
              (substitute) => {
                substitute.productdata =
                  productDataMap.get(substitute.productCode) || null;

                if (!substitute.productdata) {
                  console.error(
                    `Product data not found for substitute: ${substitute.productCode}`,
                  );
                }

                return substitute;
              },
            );
            return ingredient;
          });
      }
      return recipe;
    });

    // Return the enriched recipes after appending the product data
    return enrichedRecipes;
  } catch (error) {
    console.error("Error performing multi-search", error);
    throw error;
  }
};
