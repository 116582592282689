import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  IMAGE_UPLOAD,
  PRODUCT_PRIORITY_SET,
  PRODUCT_SYNCH,
  SYNCH_STOCK_QUANTITY,
  UPDATE_PRODUCT,
} from "./query";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addProduct = async (ProductInput: any) => {
  try {
    const response = await client.graphql({
      query: CREATE_PRODUCT,
      variables: { input: ProductInput },
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteProducts = async ({
  PK,
  SK,
}: {
  PK: string;
  SK: string;
}) => {
  try {
    const data = await client.graphql({
      query: DELETE_PRODUCT,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    toast.error("Failed to delete product. Please try again later.");
    return error;
  }
};

export const updateProduct = async (CategoryInput: any) => {
  try {
    const response: any = await client.graphql({
      query: UPDATE_PRODUCT,
      variables: { input: CategoryInput },
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getImage = async (content: {
  ContentType?: string;
  Expires?: string;
  Key?: string;
  Bucket?: string;
  imageObject?: {
    ContentType: string;
    Expires: string;
    Key: string;
    Bucket: string;
  };
}) => {
  try {
    const data = await client.graphql({
      query: IMAGE_UPLOAD,
      variables: { input: content },
    });
    //@ts-expect-error
    return JSON.parse(data?.data?.createPreSignedUrl);
  } catch (error) {
    console.error("Failed to fetch image:", error);
    return error;
  }
};

export const ProductSynch = async (
  entityName: any,
  setAlert: Function,
  setMessage: Function,
  storeId: string | null,
  productType: string,
) => {
  try {
    const response: any = await client.graphql({
      query: PRODUCT_SYNCH,
      variables: { entityName: entityName, storeId, productType },
    });
    setAlert(true);
    setMessage(
      JSON.parse(response?.data?.importProducts) ??
        "Product Synch Successfully",
    );
    return response;
  } catch (error) {
    //@ts-expect-error
    toast.error(error?.errors[0].message);
    return error;
  }
};

export const ProductStockSynch = async (
  storeId: string | null,
  setAlert: Function,
  setMessage: Function,
) => {
  try {
    const response: any = await client.graphql({
      query: SYNCH_STOCK_QUANTITY,
      variables: { storeId },
    });

    console.log("response======4", response);
    setAlert(true);
    setMessage(
      JSON.parse(response?.data?.createSyncStockQuantity) ??
        "Product Stock Synch Successfully",
    );
    return response;
  } catch (error) {
    //@ts-expect-error
    toast.error(error?.errors[0].message);
    return error;
  }
};

export const setProductPriority = async (input: any, tableName: string) => {
  try {
    const response: any = await client.graphql({
      query: PRODUCT_PRIORITY_SET,
      variables: { input, tableName }, // Correct variable names for mutation
    });

    return response;
  } catch (error: any) {
    const errorMessage =
      error?.errors?.[0]?.message || "An unexpected error occurred";

    toast.error(errorMessage); // Proper error handling with fallback
    return error;
  }
};
