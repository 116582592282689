import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FormEffect from "../../Effects/FormEffect";
import { getCustomerById } from "../../Api/Search/Customer/Customer";
import {
  processOrderResponse,
  processCustomerData,
  processDriverData,
} from "./utils";
import { getOrderById } from "../../Api/Search/Orders/Orders";
import no_image from "../../Asset/no_image.png";
import moment from "moment";
import {
  capitalizeFirstLetter,
  forceDownloadPdf,
  isValidJSON,
} from "../../Common Functions/Function";
import { Routers } from "../../Routes/Routes";
import React from "react";
import { getDriver } from "../../Api/Search/Driver/Driver";
import Select from "../../Atoms/Select";
import { updateOrder } from "../../Api/Order/mutation";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import Swal from "sweetalert2";
import {
  DirectionsRenderer,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import { downloadInvoice } from "../../Api/Invoice/mutation";
import { getUser } from "../../Api/User/mutations";
// import { generateClient } from "aws-amplify/api";
// import { onUpdateDriverLocationAppSynch } from "../../Api/User/query";
// import throttle from "lodash/throttle";
const OrdersDetails = () => {
  const { IdUser, orderId } = useParams();
  const [customerDetails, setCustomerDetails] = useState<any>();
  const [orderDetails, setOrderDetials] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [drivers, setDrivers] = useState<any>([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const { setAlert, setMessage, setErrorType } = useAlert();
  const { alert, message, errorType } = useAlert();
  const [driverAssignIsLoading, setDriverAssignIsLoading] = useState(false);
  const { storeId, latitude, longitude } = useStoreId();
  const [reasonCloseOrder, setReasonCloseOrder] = useState("");
  const [reasonCloseOrderLoader, setReasonCloseOrderLoader] = useState(false);
  const [driverLocation, setDriverLocation] = useState<any>({
    lat: null,
    lng: null,
  });
  const getDetails = useCallback(async () => {
    setLoader(true);
    if (IdUser)
      await getCustomerById("", "user", IdUser)
        .then(async (res) => {
          const response = await processCustomerData(res);
          setCustomerDetails(response?.modifiedData[0]);
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          setLoader(false);
        });
  }, [IdUser]);

  const getOrderDetails = useCallback(async () => {
    setLoader(true);
    await getOrderById("", "order", orderId)
      .then(async (res) => {
        const response = await processOrderResponse(res);
        setOrderDetials(response?.modifiedData[0]);
        setSelectedDriver(response?.modifiedData[0]?.driverId);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error fetching user details:", error);
      });
  }, [orderId]);

  useEffect(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  function calculateRefundOrderDetails(order: { refundOrderDetails: any[] }) {
    if (!order || !order.refundOrderDetails) {
      return 0;
    }
    const refundTotal = order.refundOrderDetails.reduce((acc, element) => {
      return acc + (element?.salesPrice || 0) * (element?.quantity || 0);
    }, 0);
    return refundTotal;
  }

  function calculateRefundOrderDetailsVat(order: {
    refundOrderDetails: any[];
  }) {
    if (!order || !order.refundOrderDetails) {
      return 0;
    }
    const refundTotal = order.refundOrderDetails.reduce((acc, element) => {
      return acc + (element?.salesPrice || 0) * (element?.quantity || 0);
    }, 0);
    const calculatedValue = refundTotal * 0.15;
    return calculatedValue;
  }

  function calculateRefundOrderDetailsTotal(order: {
    refundOrderDetails: any[];
  }) {
    if (!order || !order.refundOrderDetails) {
      return 0;
    }
    const refundTotal = order.refundOrderDetails.reduce((acc, element) => {
      return acc + (element?.salesPrice || 0) * (element?.quantity || 0);
    }, 0);
    const calculatedValue = refundTotal * 0.15;
    let total = refundTotal + calculatedValue;
    return total;
  }

  const getAllDrivers = () => {
    getDriver("", "user", storeId)
      .then((res) => {
        let result = processDriverData(res);
        setDrivers(result?.modifiedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllDrivers(); // eslint-disable-next-line
  }, [storeId]);

  const DriverAssign = async (data: any) => {
    let val = data;

    const actionLog = [
      ...(orderDetails?.actionLog || []),
      {
        timestamp: new Date().toISOString(),
        action: val === null ? "Driver un-assign" : "Driver assign",
      },
    ];
    let driverDetails = {
      expireTime: new Date().toISOString(),
      comments: JSON.stringify(orderDetails?.driverDetails?.comments),
      driverId: val?.id,
      status: orderDetails?.driverDetails?.status,
    };

    let orderInput = {
      driverId: val === null ? "" : val.id,
      driverDetails: driverDetails,
      PK: orderDetails?.PK,
      SK: orderDetails?.SK,
      entityName: orderDetails?.entityName,
      id: orderDetails?.id,
      addressList: JSON.stringify(orderDetails?.addressList),
      chat: JSON.stringify(orderDetails?.chat),
      orderDetails: JSON.stringify(orderDetails?.orderDetails),
      paymentDetails: JSON.stringify(orderDetails?.paymentDetails),
      specialinStructions: JSON.stringify(orderDetails?.specialinStructions),
      updatedDate: new Date().toISOString(),
      actionLog: JSON.stringify(actionLog),
      refundOrderDetails: JSON.stringify(orderDetails?.refundOrderDetails),
      isDeleted: orderDetails?.isDeleted,
      storeId: orderDetails?.storeId,
      itemAction: "changeDriver",
    };
    setDriverAssignIsLoading(true);
    updateOrder(orderInput, orderDetails?.PK, orderDetails?.SK)
      .then((res: any) => {
        const result = isValidJSON(res?.data?.updateOrder)
          ? JSON.parse(res?.data?.updateOrder)
          : res?.data?.updateOrder;

        if (result?.statusCode === 200) {
          setDriverAssignIsLoading(false);

          setAlert(true);
          setSelectedDriver(val?.SK);
          setMessage(
            val === null
              ? "Driver has been unassigned successfully."
              : "Driver has been assigned successfully.",
          );
          setErrorType(val === null ? "warning" : "success");
        } else {
          setDriverAssignIsLoading(false);

          setAlert(true);
          setMessage(res?.errors?.[0]?.message || "An error occurred");
          setErrorType("error");
        }
      })
      .catch((err) => {
        setDriverAssignIsLoading(false);

        console.error("Error updating order:", err);
        setAlert(true);
        setMessage("Failed to assign driver. Please try again.");
        setErrorType("error");
      });
  };

  const handleCloseOrder = () => {
    Swal.fire({
      title: `Are you sure you want to force close this order ${orderDetails?.id}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, close it!",
      customClass: {
        title: "force-close-custom-title",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Open the modal after confirmation
        const modalElement = document.getElementById("closeOrderModal"); //@ts-expect-error
        const modalInstance = new bootstrap.Modal(modalElement);
        modalInstance.show(); // Opens the modal
      }
    });
  };

  const closeOrder = () => {
    setReasonCloseOrderLoader(true);
    if (reasonCloseOrder === "") {
      setReasonCloseOrderLoader(false);
      setAlert(true);
      setMessage("Reason is required");
      setErrorType("error");
      return;
    }
    const actionLog = [
      ...(orderDetails?.actionLog || []),
      {
        timestamp: new Date().toISOString(),
        action: "Order Forcefully Closed",
      },
    ];

    let orderInput = {
      PK: orderDetails?.PK,
      SK: orderDetails?.SK,
      entityName: orderDetails?.entityName,
      id: orderDetails?.id,
      orderCloseReason: reasonCloseOrder,
      addressList: JSON.stringify(orderDetails?.addressList),
      chat: JSON.stringify(orderDetails?.chat),
      orderDetails: JSON.stringify(orderDetails?.orderDetails),
      paymentDetails: JSON.stringify(orderDetails?.paymentDetails),
      specialinStructions: JSON.stringify(orderDetails?.specialinStructions),
      updatedDate: new Date().toISOString(),
      actionLog: JSON.stringify(actionLog),
      refundOrderDetails: JSON.stringify(
        orderDetails?.refundOrderDetails ?? [],
      ),
      isDeleted: orderDetails?.isDeleted,
      storeId: orderDetails?.storeId,
      total: orderDetails?.total,
      subtotal: orderDetails?.subtotal,
      deliveryCharge: orderDetails?.deliveryCharge,
    };

    updateOrder(orderInput, orderDetails?.PK, orderDetails?.SK)
      .then((res: any) => {
        const result = isValidJSON(res?.data?.updateOrder)
          ? JSON.parse(res?.data?.updateOrder)
          : res?.data?.updateOrder;
        if (result?.statusCode === 200) {
          const modalElement = document.getElementById("closeOrderModal"); //@ts-expect-error
          const modalInstance = bootstrap.Modal.getInstance(modalElement);
          modalInstance?.hide();
          setAlert(true);
          setReasonCloseOrderLoader(false);
          setMessage("Order Closed  successfully");
          setErrorType("success");
          setLoader(true);
          setTimeout(() => {
            getOrderDetails();
          }, 3000);
        } else {
          setReasonCloseOrderLoader(false);
          setAlert(true);
          setMessage(res?.errors?.[0]?.message || "An error occurred");
          setErrorType("error");
        }
      })
      .catch((err) => {
        setReasonCloseOrderLoader(false);
        setAlert(true);
        setMessage("Failed to Order Closed. Please try again.");
        setErrorType("error");
      });
  };

  const shouldRenderCloseOrderButton = () => {
    if (!orderDetails) return false;

    const { status, shopperStatus, driverStatus } = orderDetails;
    return (
      status !== "failed" &&
      shopperStatus !== "pending" &&
      shopperStatus !== "in-progress" &&
      driverStatus !== "Completed"
    );
  };

  const shouldRenderMap = () => {
    if (!orderDetails) return false;

    const { status, shopperStatus, driverStatus } = orderDetails;
    return (
      status !== "failed" &&
      shopperStatus !== "pending" &&
      shopperStatus !== "in-progress" &&
      driverStatus !== "Completed"
    );
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API ?? "", // Replace with your actual API key
  });

  const [directions, setDirections] = useState<any>(null);

  const containerStyle = {
    width: "100%", // Adjust width to 100% to fit the container
    height: "300px", // You can adjust the height as needed, or make it responsive using vh units
  };

  // Customer and store locations

  useEffect(() => {
    if (orderDetails) {
      setDriverLocation({
        lat: latitude,
        lng: longitude,
      });
    } // eslint-disable-next-line
  }, [orderDetails]);

  const directionsService = new window.google.maps.DirectionsService();
  useEffect(() => {
    const customerLocation = {
      lat: orderDetails?.addressList?.[0]?.latitude,
      lng: orderDetails?.addressList?.[0]?.longitude,
    };

    const fetchDirections = async () => {
      try {
        const locationDriver = {
          lat: driverLocation?.lat,
          lng: driverLocation?.lng,
        };

        // Ensure both driverLocation and customerLocation are valid before making the request
        if (
          !locationDriver.lat ||
          !locationDriver.lng ||
          !customerLocation.lat ||
          !customerLocation.lng
        ) {
          console.error("Invalid lat/lng values for origin or destination");
          return;
        }

        const result = await directionsService.route({
          origin: locationDriver,
          destination: customerLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
        });

        setDirections(result);
      } catch (error) {
        console.error("Error fetching directions:", error);
        setDirections(null); // Reset directions in case of error
      }
    };

    if (isLoaded && orderDetails?.addressList?.length > 0 && driverLocation) {
      fetchDirections();
    }
    // eslint-disable-next-line
  }, [isLoaded, orderDetails, driverLocation]);

  const durationToMinutes = (durationText: string) => {
    const [hours = 0, minutes = 0] = durationText
      .split(" ")
      .reduce(
        (
          acc: number[],
          curr: string | string[],
          idx: number,
          arr: string[],
        ) => {
          if (curr.includes("hr")) acc[0] = parseInt(arr[idx - 1], 10);
          if (curr.includes("min")) acc[1] = parseInt(arr[idx - 1], 10);
          return acc;
        },
        [],
      );
    return hours * 60 + minutes;
  };

  // Utility to convert total minutes back to "X hr Y min" format
  const minutesToDuration = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours ? `${hours} hr ` : ""}${minutes} min`;
  };

  // Add 30 minutes to the original duration
  const getUpdatedDuration = (
    originalDuration: string,
    extraMinutes: number,
  ) => {
    if (!originalDuration) return "N/A";
    const totalMinutes = durationToMinutes(originalDuration);
    return minutesToDuration(totalMinutes + extraMinutes);
  };

  const [downloadInvoiceLoader, setDownloadInvoiceLoader] = useState(false);

  const DownloadInvoice = (item: any) => {
    setDownloadInvoiceLoader(true);
    let OrderInvoiceInput = {
      SK: item?.SK,
      PK: item?.PK,
      entityName: item?.entityName,
      id: item?.id,
    };

    downloadInvoice(OrderInvoiceInput)
      .then((res) => {
        const result = isValidJSON(res?.data?.orderInvoice)
          ? JSON.parse(res?.data?.orderInvoice)
          : res?.data?.orderInvoice;

        if (result?.statusCode === 200) {
          let response = JSON.parse(result.body);
          const pdfUrl = response?.url;

          if (pdfUrl) {
            forceDownloadPdf(pdfUrl);
            setDownloadInvoiceLoader(false);
          } else {
            setDownloadInvoiceLoader(false);
            setAlert(true);
            setMessage("URL is not available.");
            setErrorType("error");
          }
        } else {
          setDownloadInvoiceLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const client = generateClient();

  // useEffect(() => {
  //   const subscription = updateOrderSubscription();
  //   return () => {
  //     subscription.unsubscribe();
  //   }; // eslint-disable-next-line
  // }, []);

  // const updateOrderSubscription = () => {
  //   const subscription = client.graphql({
  //     query: onUpdateDriverLocationAppSynch,
  //   });
  //   //@ts-expect-error
  //   return subscription.subscribe({
  //     next: throttle(async ({ data }: any) => {
  //       const parsedBody = isValidJSON(data)
  //         ? isValidJSON(data.trackingDriverCreated)
  //         : data;

  //       const result = isValidJSON(parsedBody?.trackingDriverCreated)
  //         ? isValidJSON(parsedBody?.trackingDriverCreated)
  //         : parsedBody?.trackingDriverCreated;

  //       const resultBody = isValidJSON(result?.body)
  //         ? isValidJSON(result?.body)
  //         : result?.body;

  //       if (
  //         resultBody?.data?.latitude &&
  //         resultBody?.data?.longitude &&
  //         orderDetails?.id === resultBody?.data?.orderId
  //       ) {
  //         let startLoc = `${resultBody?.data?.latitude},${resultBody?.data?.longitude}`;
  //         console.log("startLoc===", startLoc);

  //         setDriverLocation({
  //           lat: resultBody?.data?.latitude,
  //           lng: resultBody?.data?.longitude,
  //         });

  //         // setDriverCurrentLocation({
  //         //   latitude: resultBody?.data?.latitude,
  //         //   longitude: resultBody?.data?.longitude,
  //         // });
  //         // fetchDirections(startLoc, destinationLocation);
  //       }
  //     }, 10000),
  //     error: (error: any) => {
  //       console.log("onUpdateDriverLocationAppSynch errro ======", error);
  //     },
  //   });
  // };

  const [locationLoader, setLocationLoader] = useState(false);
  const getDriverLatLong = () => {
    if (orderDetails?.id) {
      setLocationLoader(true);
      getUser("tracking", `Active#${orderDetails?.id}`)
        .then((res: any) => {
          const result = isValidJSON(res?.data?.createTrackingDriver)
            ? isValidJSON(res?.data?.createTrackingDriver)
            : res?.data?.createTrackingDriver;
          const errorMessage =
            JSON.parse(result.body).error ?? JSON.parse(result.body)?.message;

          console.log("errorMessage===", errorMessage);
          if (result?.statusCode === 200) {
            const track = JSON.parse(result?.body);
            console.log(
              "Api  track?.data?.latitude=====",
              track?.data?.latitude,
            );
            console.log(
              "Api  track?.data?.latitude=====",
              track?.data?.longitude,
            );
            if (track?.data?.latitude && track?.data?.longitude) {
              setLocationLoader(false);

              setDriverLocation({
                lat: track?.data?.latitude,
                lng: track?.data?.longitude,
              });
            }
          } else {
            setLocationLoader(false);
          }
        })
        .catch((err) => {
          setLocationLoader(false);

          console.log(err); // Log any errors
        });
    }
  };

  useEffect(() => {
    if (orderDetails?.id) {
      console.log("orderDetails?.id===", orderDetails?.id);
      if (shouldRenderMap()) {
        getDriverLatLong();
      }
    }
    // eslint-disable-next-line
  }, [orderDetails]);

  console.log("drivers===", drivers);
  console.log("orderDetails?.id===", orderDetails?.driverId);

  return (
    <div className="mt-4">
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}
      {loader ? (
        <FormEffect />
      ) : (
        <>
          {orderDetails?.actionLog?.some(
            (log: any) => log?.action === "Order Forcefully Closed",
          ) && (
            <div className="alert alert-danger" role="alert">
              <i className="bi bi-info-circle"></i>{" "}
              {"Order was forcefully closed by the admin"}
            </div>
          )}

          {orderDetails?.actionLog?.some(
            (log: any) => log?.action === "Order Reject",
          ) && (
            <div className="alert alert-danger" role="alert">
              <i className="bi bi-info-circle"></i>{" "}
              {"The order was rejected by the chef."}
            </div>
          )}
          <div className="d-flex flex-wrap gap-3 align-items-center mb-3">
            <h3 className="theme_color fw-bolder mb-0">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.orders}
              >
                <i className="bi bi-chevron-left"></i>
              </Link>
              {orderDetails?.id ? `${orderDetails?.id}` : ""}
            </h3>
            {/* <button type="button" className="btn btn-outline-success">
              {"Paid"}
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Processing
            </button> */}
            <div className="order-radio-group">
              <input
                type="radio"
                className="btn-check"
                name="options-order"
                id="paid"
                readOnly
                checked={orderDetails?.paymentStatus === "SUCCEEDED"}
              />
              <label className="btn " htmlFor="paid">
                Paid
              </label>

              <input
                type="radio"
                className="btn-check"
                name="options-order"
                id="processing"
                readOnly
                checked={orderDetails?.paymentStatus !== "SUCCEEDED"}
              />
              <label className="btn " htmlFor="processing">
                Processing
              </label>
            </div>
          </div>

          {shouldRenderCloseOrderButton() &&
            !orderDetails?.actionLog?.some(
              (log: any) => log?.action === "Order Forcefully Closed",
            ) && (
              <div className="d-flex justify-content-end mb-4">
                <button className="btn btn-danger" onClick={handleCloseOrder}>
                  Force Close Order
                </button>
              </div>
            )}

          <div className="row ">
            <div className="col-md-7 col-lg-8 mb-4">
              <div className="border border-grey p-4 rounded-top white_bg">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="theme_color order_details_heading">
                    Order Status: {capitalizeFirstLetter(orderDetails?.status)}
                  </span>

                  {orderDetails && (
                    <button
                      onClick={() => DownloadInvoice(orderDetails)}
                      className="btn btn-secondary"
                      disabled={downloadInvoiceLoader}
                    >
                      {downloadInvoiceLoader
                        ? "Download in progress..."
                        : "Download Invoice"}
                    </button>
                  )}
                </div>

                <div className="table-responsive order-table mt-3">
                  <table className="w-100">
                    <tbody>
                      {orderDetails?.orderDetails?.map((item: any) => (
                        <React.Fragment key={item?.sku}>
                          <tr>
                            <td width={90}>
                              <div className="table-order-img-wrapper">
                                <img
                                  src={
                                    item?.imageURL ? item?.imageURL : no_image
                                  }
                                  alt={item?.productName || "Product"}
                                />
                              </div>
                            </td>

                            <td>
                              <div>
                                <span className="theme_color fw-bold">
                                  {item?.productName}
                                </span>
                              </div>
                              <div>
                                <span className="theme_color fw-bold text-nowrapper">
                                  Sku Name: {item?.sku}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="theme_color fw-bold">
                                R {(Number(item?.salesPrice) || 0).toFixed(2)} x{" "}
                                {item?.quantity}
                              </span>
                            </td>
                            <td>
                              <span className="theme_color fw-bold">
                                R{" "}
                                {Number(
                                  item?.salesPrice * item?.quantity,
                                ).toFixed(2)}
                              </span>
                            </td>
                          </tr>

                          {/* Sub-products Heading */}
                          {item?.subProduct?.length > 0 && (
                            <tr>
                              <td colSpan={4}>
                                <span
                                  className="theme_color  fs-6  fw-bolder"
                                  style={{
                                    marginTop: "20px",
                                    display: "block",
                                  }}
                                >
                                  Sub-Products
                                </span>
                              </td>
                            </tr>
                          )}

                          {/* Display Sub-products */}
                          {item?.subProduct?.length > 0 &&
                            item?.subProduct?.map((subItem: any) => (
                              <tr key={subItem?.sku} className="subproduct-row">
                                <td width={90}>
                                  <div className="table-order-img-wrapper">
                                    <img
                                      src={
                                        subItem?.imageURL
                                          ? subItem?.imageURL
                                          : no_image
                                      }
                                      alt={
                                        subItem?.productName ||
                                        "Subproduct Image"
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span className="theme_color fw-bold">
                                      {subItem?.productName}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="theme_color fw-bold text-nowrapper">
                                      Sku Name: {subItem?.sku}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="theme_color fw-bold">
                                    R{" "}
                                    {(Number(subItem?.salesPrice) || 0).toFixed(
                                      2,
                                    )}{" "}
                                    x {subItem?.quantity}
                                  </span>
                                </td>
                                <td>
                                  <span className="theme_color fw-bold">
                                    R{" "}
                                    {(
                                      Number(
                                        subItem?.salesPrice * subItem?.quantity,
                                      ) || 0
                                    ).toFixed(2)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="border-top-0 "></div>
              {/* <div className="border border-grey p-4 rounded-bottom white_bg d-flex justify-content-end">
                <button type="button" className="btn btn-outline-success">
                  Ship Item
                </button>
              </div> */}

              {orderDetails?.orderDetails?.length > 0 && (
                <div className="border border-grey p-4 mt-4 rounded-top white_bg">
                  <span className="theme_color order_details_heading ">
                    {"Credit Card"}
                  </span>
                  <div className="table-responsive mt-3">
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td className="order_details_credit_card">
                            Subtotal
                          </td>
                          <td className="order_details_credit_card"></td>
                          <td className="order_details_credit_card">
                            R {orderDetails?.subtotal?.toFixed(2)}
                          </td>
                        </tr>
                        {orderDetails?.actionLog?.some(
                          (log: any) => log?.action === "Order Reject",
                        ) ? (
                          <tr>
                            <td className="order_details_credit_card">
                              Shipping
                            </td>
                            <td className="order_details_credit_card">
                              Standard Delivery
                            </td>
                            <td className="order_details_credit_card">
                              R {"0.00"}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="order_details_credit_card">
                              Shipping
                            </td>
                            <td className="order_details_credit_card">
                              Standard Delivery
                            </td>
                            <td className="order_details_credit_card">
                              R {orderDetails?.deliveryCharge?.toFixed(2)}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="order_details_credit_card">
                            Discount
                          </td>
                          <td className="order_details_credit_card"></td>
                          <td className="order_details_credit_card">
                            R {"0.00"}
                          </td>
                        </tr>
                        {orderDetails?.actionLog?.some(
                          (log: any) => log?.action === "Order Reject",
                        ) ? (
                          <tr>
                            <td className="order_details_credit_card">Tip</td>
                            <td className="order_details_credit_card"></td>
                            <td className="order_details_credit_card">
                              R {"0.00"}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="order_details_credit_card">Tip</td>
                            <td className="order_details_credit_card"></td>
                            <td className="order_details_credit_card">
                              R {orderDetails?.tip?.toFixed(2)}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="order_details_credit_card">Tax</td>
                          <td className="order_details_credit_card"></td>
                          <td className="order_details_credit_card">
                            R{" "}
                            {(
                              (parseFloat(orderDetails?.subtotal) * 15) /
                              100
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="order_details_credit_card">Total</td>
                          <td className="order_details_credit_card"></td>
                          <td className="order_details_credit_card">
                            R {Number(orderDetails?.total).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot className="pt-2 border-top">
                        <tr>
                          <td className="order_details_credit_card" colSpan={2}>
                            Paid By Customer
                          </td>
                          <td className="order_details_credit_card">
                            R {Number(orderDetails?.total).toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {/* Refund order start */}
              {
                <>
                  {orderDetails?.refundOrderDetails?.length > 0 && (
                    <div className="border border-grey p-4 rounded-top white_bg mt-4">
                      <span className="theme_color order_details_heading ">
                        {"Refund Order Details - Product"}
                      </span>
                      <div className="table-responsive order-table mt-3">
                        <table className="w-100">
                          <tbody>
                            {orderDetails?.refundOrderDetails?.map(
                              (item: any) => (
                                <tr>
                                  <td width={90}>
                                    <div className="table-order-img-wrapper">
                                      {item?.imageURL ? (
                                        <img
                                          src={item?.imageURL}
                                          alt={item?.imageURL}
                                        />
                                      ) : (
                                        <img src={no_image} alt={"no_image"} />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <span className="theme_color fw-bold">
                                        {item?.productName}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="theme_color fw-bold text-nowrapper">
                                        Sku Name : {item?.sku}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="theme_color fw-bold">
                                      R{" "}
                                      {(Number(item?.salesPrice) || 0).toFixed(
                                        2,
                                      )}{" "}
                                      x {""}
                                      {item?.quantity}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="theme_color fw-bold">
                                      R{" "}
                                      {Number(
                                        item?.salesPrice * item?.quantity,
                                      ).toFixed(2)}
                                    </span>
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {orderDetails?.refundOrderDetails?.length > 0 && (
                    <div className="border border-grey p-4 mt-4 rounded-top white_bg">
                      <span className="theme_color order_details_heading ">
                        {"Refund Order Details - Amount"}
                      </span>
                      <div className="table-responsive mt-3">
                        <table width={"100%"}>
                          <tbody>
                            <tr>
                              <td className="order_details_credit_card">
                                Subtotal
                              </td>
                              <td className="order_details_credit_card"></td>
                              <td className="order_details_credit_card">
                                R{" "}
                                {calculateRefundOrderDetails(
                                  orderDetails,
                                ).toFixed(2)}
                              </td>
                            </tr>

                            <tr>
                              <td className="order_details_credit_card">Tax</td>
                              <td className="order_details_credit_card"></td>
                              <td className="order_details_credit_card">
                                R {""}
                                {calculateRefundOrderDetailsVat(
                                  orderDetails,
                                ).toFixed(2)}
                              </td>
                            </tr>
                            {orderDetails?.actionLog?.some(
                              (log: any) => log?.action === "Order Reject",
                            ) && (
                              <>
                                <tr>
                                  <td className="order_details_credit_card">
                                    Tip
                                  </td>
                                  <td className="order_details_credit_card"></td>
                                  <td className="order_details_credit_card">
                                    R {orderDetails?.tip?.toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="order_details_credit_card">
                                    Shipping
                                  </td>
                                  <td className="order_details_credit_card">
                                    Standard Delivery
                                  </td>
                                  <td className="order_details_credit_card">
                                    R {orderDetails?.deliveryCharge?.toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tfoot className="pt-2 border-top">
                            {orderDetails?.actionLog?.some(
                              (log: any) => log?.action === "Order Reject",
                            ) ? (
                              <tr>
                                <td
                                  className="order_details_credit_card"
                                  colSpan={2}
                                >
                                  Refund Amount
                                </td>
                                <td className="order_details_credit_card">
                                  R{" "}
                                  {(
                                    calculateRefundOrderDetailsTotal(
                                      orderDetails,
                                    ) +
                                    (parseFloat(orderDetails?.deliveryCharge) ||
                                      0) +
                                    (parseFloat(orderDetails?.tip) || 0)
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td
                                  className="order_details_credit_card"
                                  colSpan={2}
                                >
                                  Refund Amount
                                </td>
                                <td className="order_details_credit_card">
                                  R{" "}
                                  {calculateRefundOrderDetailsTotal(
                                    orderDetails,
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            )}
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  )}
                </>
              }
              {/* refund order end */}

              {shouldRenderMap() && (
                <>
                  {/* <div>
                    <p> lat: {driverLocation?.lat}</p>
                    <p> lng: {driverLocation?.lng}</p>
                  </div> */}

                  <div className="border border-grey p-4 mt-4 rounded-top white_bg">
                    <span className="theme_color order_details_heading">
                      Order Delivery Estimated Time
                    </span>
                    <div className="mt-2">
                      <span className="theme_color fw-bold">
                        Estimated time to reach:
                        {getUpdatedDuration(
                          directions?.routes[0]?.legs[0]?.duration?.text,
                          30,
                        )}
                      </span>
                      <br />
                      <span className="theme_color fw-bold">
                        Latest time to reach:
                        {getUpdatedDuration(
                          directions?.routes[0]?.legs[0]?.duration?.text,
                          50,
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row justify-content-end mt-4">
                    <div className="col-12 col-md-auto d-flex flex-column flex-md-row gap-2">
                      <button
                        className="btn btn-outline-success  mt-2 mt-md-0"
                        onClick={() => getDriverLatLong()}
                        type="button"
                      >
                        {locationLoader ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Reload Map"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="mb-4 mt-4">
                    {directions ? (
                      <>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          zoom={13} // Adjusted zoom level to show both locations clearly
                        >
                          {directions && (
                            <DirectionsRenderer directions={directions} />
                          )}
                        </GoogleMap>
                      </>
                    ) : null}
                  </div>
                </>
              )}

              {/* sterper */}
              <div className="col-md-7 col-lg-8 mb-4">
                <div className=" p-4 ">
                  <span className="theme_color order_details_heading ">
                    {"Activites"}
                  </span>
                </div>
              </div>
              <div className="p-4 ">
                {orderDetails?.actionLog?.map((item: any, key: number) => (
                  <ul id="progress">
                    <li className="stepper">
                      <div className="node green"></div>
                      <div className="content time-line">
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <div className="time-line-text">
                              <p className="p-0 m-0 theme_color">
                                {moment(item?.timestamp).format("DD MMM, YYYY")}
                              </p>
                            </div>
                            <div className="time-line-text">
                              <p className="p-0 m-0 theme_color">
                                {capitalizeFirstLetter(item?.action)}
                              </p>
                            </div>
                            <div className="time-line-text">
                              <p className="p-0 m-0 theme_color">
                                {/* sdvkjsview0e9vsdmv201wezx */}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex align-items-center">
                            <div className="time-line-text">
                              <p className="p-0 m-0 theme_color">
                                {moment(item?.timestamp).format("hh:mm:ss A")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                ))}
              </div>

              {/* steper */}
            </div>

            <div className="col-md-5 col-lg-4 ">
              <div className="border border-grey p-4 mb-4 rounded white_bg">
                <h5 className="h5 fw-bold mb-3">{"Delivery OTP"}</h5>
                <span className="order_customer">
                  {orderDetails?.customerDetails?.comments?.[0]?.orderOtp}
                </span>
              </div>

              {orderDetails?.status !== "Completed" &&
              orderDetails?.driverStatus !== "Completed" &&
              orderDetails?.status !== "failed" &&
              !orderDetails?.actionLog?.some(
                (log: any) => log?.action === "Order Forcefully Closed",
              ) ? (
                <div className="border border-grey p-4 mb-4 rounded white_bg">
                  <label htmlFor="description1" className={`form-label`}>
                    Assign Driver (Online Only)
                  </label>
                  <Select
                    cacheOptions={true}
                    onChange={DriverAssign}
                    defaultOptions={drivers}
                    data={drivers}
                    value={
                      drivers.find(
                        (item: { id: string }) => item?.id === selectedDriver,
                      ) ||
                      drivers.find(
                        (item: { SK: string }) => item?.SK === selectedDriver,
                      )
                    }
                    isLoading={driverAssignIsLoading}
                  />
                </div>
              ) : (
                <div className="border border-grey p-4 mb-4 rounded white_bg">
                  <label htmlFor="description1" className={`form-label`}>
                    Driver Name
                  </label>
                  <p>
                    {drivers.find(
                      (item: { id: string }) =>
                        item?.id === orderDetails?.driverId,
                    )?.fullName ?? "N/A"}
                  </p>
                </div>
              )}

              <div className="border border-grey p-4 mb-4 rounded white_bg">
                <h5 className="h5 fw-bold mb-3">{"Customer Notes"}</h5>
                <span className="order_customer">
                  {orderDetails?.specialinStructions ?? "No Note From Customer"}
                </span>
              </div>

              <div className="border border-grey p-4 mb-4 rounded white_bg">
                <div className="mb-4">
                  <h5 className="h5 fw-bold mb-3">{"Customer"}</h5>
                  <div className="order_customer">{customerDetails?.email}</div>
                  <div className="order_customer">
                    ({customerDetails?.fullName})
                  </div>
                </div>
                <div className="mb-4">
                  <h5 className="h5 fw-bold mb-3">{"CONTACT INFORMATION"}</h5>
                  <div className="f-small fw-bold opacity-80">
                    {customerDetails?.email}
                  </div>
                  <div className="f-small fw-bold opacity-80">
                    {customerDetails?.phone}
                  </div>
                </div>

                <div className="mb-4">
                  <>
                    <h5 className="h5 fw-bold mb-3">{"SHIPPING ADDRESS"}</h5>
                    <div className="f-small fw-bold opacity-80">
                      {orderDetails?.addressList?.[0]?.addressName &&
                        `${orderDetails?.addressList?.[0]?.addressName}, `}
                      {orderDetails?.addressList?.[0]?.aptNumber &&
                        `${orderDetails?.addressList?.[0]?.aptNumber}, `}
                      {orderDetails?.addressList?.[0]?.address}
                    </div>

                    <div className="f-small fw-bold opacity-80">
                      {orderDetails?.phone}
                    </div>
                  </>
                </div>

                <div className="mb-4">
                  <>
                    <h5 className="h5 fw-bold mb-3">{"BILLING ADDRESS"}</h5>
                    <div className="f-small fw-bold opacity-80">
                      <div className="f-small fw-bold opacity-80">
                        {orderDetails?.addressList?.[0]?.addressName &&
                          `${orderDetails?.addressList?.[0]?.addressName}, `}
                        {orderDetails?.addressList?.[0]?.aptNumber &&
                          `${orderDetails?.addressList?.[0]?.aptNumber}, `}
                        {orderDetails?.addressList?.[0]?.address}
                      </div>
                    </div>

                    <div className="f-small fw-bold opacity-80">
                      {customerDetails?.phone}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="modal fade"
        id="closeOrderModal"
        tabIndex={-1}
        aria-labelledby="closeOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="closeOrderModalLabel">
                Close Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="closeReason">Reason for Closing:</label>
                <textarea
                  className="form-control"
                  id="closeReason"
                  rows={3}
                  placeholder="Enter the reason for closing the order"
                  onChange={(e) => {
                    setReasonCloseOrder(e.target.value);
                  }}
                  required
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                id="confirmCloseOrder"
                onClick={closeOrder}
                disabled={reasonCloseOrderLoader}
              >
                {reasonCloseOrderLoader ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Close Order"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersDetails;
