import { COMMUNICATION } from "./query";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";

const client = generateClient();

export const addCommunication = async (CommunicationInput: any) => {
  try {
    const response = await client.graphql({
      query: COMMUNICATION,
      variables: { input: CommunicationInput },
    });
    return response;
  } catch (error) {
    //@ts-expect-error
    toast.error(error?.errors[0].message);
    return error;
  }
};
