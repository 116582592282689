import { ReactComponent as Search } from "../../../../Asset/search.svg";
import { ReactComponent as Menu } from "../../../../Asset/menu.svg";
import { useState } from "react";

const Overview = () => {
  const [isOpen, setIsOpen] = useState<number | null>(1);

  const toggleAccordion = (item: number) => {
    setIsOpen(isOpen === item ? null : item);
  };
  return (
    <div className="overview_wraper">
      <h4 className="theme_color fw-bolder card-title">{"Menu"}</h4>
      <p className="fw-bolder">Menu Hours</p>
      <p className="fw-bolder">Every day 12:00 AM - 11:59 PM</p>
      <div className="mb-4 menu_manager_menu_wraper">
        <div className="filter_wrap">
          <Search />
          <input
            type="search"
            className="form-control theme_color"
            aria-label="Sizing example input "
            aria-describedby="inputGroup-sizing-sm"
            placeholder="Search 31 items "
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table">
          {[1, 2, 3].map((item) => {
            return (
              <>
                <thead>
                  <tr onClick={() => toggleAccordion(item)}>
                    <td width={50}>
                      <Menu />
                    </td>
                    <td width={100}>
                      <p className="fw-bolder">Pizza</p>
                      <p className="fw-bolder text-nowrap">9 Items</p>
                    </td>
                    <td colSpan={1}></td>
                    <td width={100}> {isOpen === item && "Delivery"}</td>
                    <td width={100}>{isOpen === item && "Pickup"} </td>
                    <td width={20}>
                      {isOpen === item ? (
                        <i className="bi bi-chevron-down "></i>
                      ) : (
                        <i className="bi bi-chevron-right "></i>
                      )}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {isOpen === item && (
                    <tr>
                      <td>
                        <Menu />
                      </td>
                      <td colSpan={1}></td>
                      <td>
                        <p className="fw-bolder">Margherita Pizza</p>
                        <p className="col-min-w-170">
                          Choose your base, Choose your extra topping
                        </p>
                      </td>
                      <td>
                        <div className="text-nowrap">R 190,00</div>
                      </td>
                      <td>
                        <div className="text-nowrap">R 180,00</div>
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default Overview;
